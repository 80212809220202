import {Component, inject, Input, OnInit} from '@angular/core';
import {ChartDatasetExtended, MeasurementDataset} from "../base-measurement-chart";
import {ContractMeasurementResult, DataType} from "@flowmaps/flowmaps-typescriptmodels";
import {lodash} from "../../../common/utils";
import {AppContext} from "../../../app-context";
import {TranslateDirective} from "../../../common/utils/translate.directive";
import {Handler} from "../../../common/handler";
import {View} from "../../../common/view";
import {ChartUtilsService} from "../chart-utils.service";
import {ChartDataProvider} from "../../../utils/chart-data-provider";

@Component({
    selector: 'app-co2-chart',
    templateUrl: './co2-chart.component.html',
    styleUrls: ['./co2-chart.component.css']
})
@Handler()
export class Co2ChartComponent extends View implements OnInit {
    protected chartUtils: ChartUtilsService = inject(ChartUtilsService);

    totalCo2: number = 0;
    @Input() showInReport: boolean;
    dataProvider: ChartDataProvider = new ChartDataProvider();

    measurementTypes: DataType[] = [DataType.co2EmissionFromElectricity, DataType.co2EmissionFromGas,
            DataType.co2EmissionFromHeat, DataType.co2EmissionFromWater];

    ngOnInit() {
        this.subscribeTo("getContractMeasurements").subscribe((d: ContractMeasurementResult) => this.setData(d));
    }

    setData(result: ContractMeasurementResult) {
        const datasets = this.measurementTypes.map((m, i) => {
            const d = result.co2measurements.measurements[m]
            const estimatedData = result.co2measurements.estimatedMeasurements[m]
            const value = this.roundValueIfNeeded(lodash.divide(lodash.sum(d?.map(point => point.value)) || 0, 1000));
            const estimatedValue = this.roundValueIfNeeded(lodash.divide(lodash.sum(estimatedData?.map(point => point.value)) || 0, 1000));
            return <MeasurementDataset>{
                measurementType: m,
                dataset: this.getDataset(m, [value].concat(estimatedValue > 0 ? [estimatedValue] : []))
            }
        });
        this.totalCo2 = this.roundValueIfNeeded(lodash.sum(datasets.flatMap(d => d.dataset.data)));
        this.dataProvider.emit({
            datasets: datasets.flatMap(d => [d, <any>{
                dataset: {
                    weight: 0.7
                }
            }])
        });
    }

    private roundValueIfNeeded = (value: number) => value >= 100 ? lodash.round(value, 0) : lodash.round(value, 2);

    private getDataset = (measurementType: DataType, data: number[], label?: string): ChartDatasetExtended => {
        const measurementName = AppContext.measurementName(measurementType);
        return ({
            label: label,
            data: data,
            backgroundColor: [AppContext.getChartColorForMeasurementAsString(measurementType), "#FFFFFF"],
            borderColor: AppContext.getChartColorForMeasurementAsString(measurementType),
            borderWidth: 1,
            order: AppContext.indexOfDataType(measurementType),
            measurementType: measurementType,
            tooltip: {
                formatter: this.chartUtils.getCustomTooltipFormatter(measurementType),
                labelOverride: data.length > 1
                    ? [measurementName, `${measurementName} (${TranslateDirective.getTranslation("estimated", true)})`]
                    : measurementName
            }
        });
    };
}