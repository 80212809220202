import {AfterViewInit, Component, TemplateRef, ViewChild} from '@angular/core';
import {View} from "src/app/common/view";
import {Handler} from "src/app/common/handler";
import {AppContext} from "../../../../app-context";
import {map, Observable} from "rxjs";
import {ConsumptionTax, FacetFilter, FacetResult, GetMyConsumptionTaxes} from "@flowmaps/flowmaps-typescriptmodels";
import {filterByTerm, uuid} from "../../../../common/utils";
import {sendFacetsQuery} from "../../../../common/app-common-utils";
import {AddTaxComponent} from "../add-tax/add-tax.component";
import {FacetUtils} from "../../../../common/facets/facet-utils";
import {SentenceCasePipe} from "../../../../common/sentence-case.pipe";

@Component({
  selector: 'app-taxes-overview',
  templateUrl: './taxes-overview.component.html',
  styleUrls: ['./taxes-overview.component.scss']
})
@Handler()
export class TaxesOverviewComponent extends View implements AfterViewInit {
  appContext = AppContext;
  facetUtils = FacetUtils;

  term: string;
  query: Observable<ConsumptionTax[]>;
  data: ConsumptionTax[] = [];
  facets: Observable<FacetResult>;
  facetFilters: FacetFilter[];
  hiddenFacets: string[] = ["intermediaryId"];
  defaultFacetFilters: FacetFilter[] = [{
    facetName: "taxInfo/country",
    values: ["NL"]
  }];

  @ViewChild("filtersInModal") filtersTemplate: TemplateRef<any>;

  ngAfterViewInit() {
    this.executeQuery();
  }

  executeQuery = () => {
    const query: GetMyConsumptionTaxes = {facetFilters: this.facetFilters};
    this.query = this.subscribeTo("getConsumptionTaxes", query)
        .pipe(map((o: ConsumptionTax[]) => o.filter(filterByTerm(this.term))));
    this.facets = sendFacetsQuery("com.flowmaps.api.organisation.GetMyConsumptionTaxesNew", query, {caching: false})
        .pipe(map(f => FacetUtils.addZeroFacetResults(f, "taxInfo/country", "NL")));
  }

  trackByForRecord = (index: number, record: ConsumptionTax) => record.taxId;

  addTax = () => this.openModal(AddTaxComponent, {
    taxId: uuid(),
    taxInfo: {},
    taxPeriods: []
  });

  openFiltersInModal = () => this.openModal(this.filtersTemplate, null);

  facetValueFormatter = (name: string): (value: string) => string => {
    switch (name) {
      case "taxInfo/country": return (v: string) => v.toUpperCase();
      default: return (v: string) => SentenceCasePipe.format(v);
    }
  }
}
