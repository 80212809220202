import {AfterViewInit, Component} from '@angular/core';
import {View} from "src/app/common/view";
import {Handler} from "src/app/common/handler";
import {AppContext} from "../../../../app-context";
import {map, Observable} from "rxjs";
import {Authorisation, Organisation} from "@flowmaps/flowmaps-typescriptmodels";
import {filterByTerm, sort} from "../../../../common/utils";
import {ComparatorChain} from "../../../../common/comparator-chain";
import {tap} from "rxjs/operators";
import {AuthorisationDetailsComponent} from "../authorisation-details/authorisation-details.component";

@Component({
  selector: 'app-authorisations-overview',
  templateUrl: './authorisations-overview.component.html',
  styleUrls: ['./authorisations-overview.component.scss']
})
@Handler()
export class AuthorisationsOverviewComponent extends View implements AfterViewInit {
  appContext = AppContext;

  term: string;
  organisation: Organisation;
  query: Observable<Authorisation[]>;
  data: Authorisation[] = [];
  comparator: ComparatorChain = new ComparatorChain('revoked', 'nominee');

  ngAfterViewInit() {
    this.executeQuery();
  }

  executeQuery = () => {
    this.query = this.subscribeTo("getOrganisation")
        .pipe(tap((o: Organisation) => this.organisation = o))
        .pipe(map((o: Organisation) => o.givenAuthorisations))
        .pipe(map(o => o.filter(a => a.role !== 'intermediary' && !a.revoked)))
        .pipe(map(o => o.filter(filterByTerm(this.term))))
        .pipe(map(c => sort(c, this.comparator)));
  }

  trackByForRecord = (index: number, record: Authorisation) => record.authorisationId;

  addAuthorisation = () => this.openModal(AuthorisationDetailsComponent, { nominator: this.organisation?.organisationId });
}
