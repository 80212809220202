import {AfterViewInit, Component} from '@angular/core';
import {View} from "src/app/common/view";
import {Handler} from "src/app/common/handler";
import {AppContext} from "../../../../app-context";
import {RefdataUtils} from "../../refdata-utils";
import {DashboardContext} from "../../../dashboard/dashboard.context";
import {ContractEntity, LinkedContractEntity} from "../../../../handlers/app-entity-handler.service";
import {ComparatorChain} from "../../../../common/comparator-chain";
import {map, Observable} from "rxjs";
import {sort} from "../../../../common/utils";
import {cloneDeep} from "lodash";
import {
    LinkContractDetailsComponent,
    LinkContractInBulkData
} from "../link-contract-details/link-contract-details.component";

@Component({
    selector: 'app-linked-contracts-overview',
    templateUrl: './linked-contracts-overview.component.html',
    styleUrls: ['./linked-contracts-overview.component.scss']
})
@Handler()
export class LinkedContractsOverviewComponent extends View implements AfterViewInit {
    appContext = AppContext;
    refDataUtils = RefdataUtils;
    dashboardContext = DashboardContext;

    contract: ContractEntity;
    comparator: ComparatorChain = new ComparatorChain('entity.organisation.name');

    term: string;
    query: Observable<LinkedContractEntity[]>;
    data: LinkedContractEntity[] = [];

    ngAfterViewInit() {
        this.subscribeTo("getContract").subscribe(c => {
            this.contract = c;
            this.executeQuery();
        });
    }

    executeQuery = () => {
        this.query = this.subscribeTo("getLinkedContractEntities", this.contract.contract.contractId)
            .pipe(map((c: LinkedContractEntity[]) => sort(c, this.comparator)));
    }

    trackByForRecord = (index: number, record: LinkedContractEntity) => index;

    linkContract = () => this.openModal(LinkContractDetailsComponent, <LinkContractInBulkData> {
        contract: cloneDeep(this.contract),
        dateRange: {
            start: "",
            end: ""
        }
    });
}
