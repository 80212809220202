import {AfterViewInit, Component} from '@angular/core';
import {View} from "src/app/common/view";
import {Handler} from "src/app/common/handler";
import {AppContext} from "../../../../app-context";
import {MergeLocations, Organisation} from "@flowmaps/flowmaps-typescriptmodels";
import {map, Observable} from "rxjs";
import {filterByTerm, sort} from "../../../../common/utils";
import {RefdataUtils} from "../../refdata-utils";
import {Entity} from "../../../../handlers/entity";
import {downloadLocationsExcel, uploadLocationsExcel} from "./locations-list-excel";
import {AppCommonUtils} from "../../../../common/app-common-utils";
import {openConfirmationModalWithCallback} from "../../../../app-utils";
import {
    ModalConfirmAutofocus,
    ModalConfirmAutofocusData
} from "../../../../common/modal-confirm/modal-confirm.component";
import {DndDropEvent} from "ngx-drag-drop";
import {LocationComponentData, LocationDetailsComponent} from "../location-details/location-details.component";
import {HandleEvent} from "../../../../common/handle-event";
import {ModalComponent} from "../../../../common/modal/modal.component";

@Component({
    selector: 'app-locations-overview',
    templateUrl: './locations-overview.component.html',
    styleUrls: ['./locations-overview.component.scss']
})
@Handler()
export class LocationsOverviewComponent extends View implements AfterViewInit {
    appContext = AppContext;
    downloadLocationsAsExcel = () => this.queryFilters(this.sendQuery("getLocationsAsEntities")).subscribe(l => downloadLocationsExcel(l));
    organisation: Organisation;

    term: string;
    query: Observable<Entity[]>;
    data: Entity[] = [];
    anyModalOpened: boolean;

    ngAfterViewInit() {
        this.createQuery();
        this.subscribeTo("getOrganisation").subscribe(o => this.organisation = o);
    }

    createQuery = () => {
        this.query = this.queryFilters(this.subscribeTo("getLocationsAsEntities"));
    }

    executeQuery = () => this.query.subscribe().unsubscribe();

    queryFilters = (o: Observable<Entity[]>): Observable<Entity[]> => {
        return o.pipe(map((entities: Entity[]) => entities.filter(filterByTerm(this.term, ["locations", "connections", "meters", "contracts", "timeZone"]))))
            .pipe(map(c => sort(c, RefdataUtils.locationsComparator)));
    }

    trackByForRecord = (index: number, record: Entity) => record.location.locationId;

    onDrop = (dropEvent: DndDropEvent, toLocation: Entity) => {
        const fromLocation = dropEvent.data as Entity;
        openConfirmationModalWithCallback((confirmed) => {
            if (confirmed) {
                this.sendCommand("com.flowmaps.api.organisation.MergeLocations", <MergeLocations>{
                    organisationId: this.organisation.organisationId,
                    locationId: toLocation.location.locationId,
                    oldLocationId: fromLocation.location.locationId
                });
            }
        }, ModalConfirmAutofocus, <ModalConfirmAutofocusData>{
            type: "warning",
            title: "Merge locations",
            innerHtmlMessage: `<p><span>You are about to merge location</span><span class="notranslate">:<br/><b>${RefdataUtils.locationsFormatter(fromLocation.location)}</b><br/></span> <span>into</span><span class="notranslate">:<br/><b>${RefdataUtils.locationsFormatter(toLocation.location)}</b></span></p>`,
            innerHtmlQuestion: `<p><span>Are you sure you want to move all connections to</span> <span class="notranslate"><b>${RefdataUtils.locationsFormatter(toLocation.location)}</b></span>?</p><p><span>This cannot be undone!</span></p>`,
            confirmText: "Yes",
            cancelText: "No"
        }, 'static');
    }

    authorisedForLocationMerge = () => !this.anyModalOpened && !!this.organisation && AppContext.isAdminOrIntermediary();

    openLocationDetails = (entity: Entity) => this.openModal(LocationDetailsComponent, <LocationComponentData>{
        entity: entity
    });

    newLocation = (): Entity => new Entity(this.organisation, {})

    onUploadLocationsList = (event: any) => {
        uploadLocationsExcel(event.files[0]).subscribe(command => {
            if (command.updates.length === 0) {
                AppCommonUtils.registerError("No changes have been found between uploaded file and current data",
                    'warning', 3000);
                return;
            }
            const locations = command.updates.map(c => RefdataUtils.locationInfoFormatter(c.info)).map(name => `<li>${name}</li>`).join("");
            openConfirmationModalWithCallback((confirmed) => {
                if (confirmed) {
                    this.sendCommand("com.flowmaps.api.organisation.UpsertLocations", command);
                }
            }, ModalConfirmAutofocus, <ModalConfirmAutofocusData>{
                type: "warning",
                title: "Bulk update locations",
                innerHtmlMessage: `<p><span>You are about to bulk update the following locations</span></p><ul class="notranslate fw-bold">${locations}</ul><p class="fw-bold">Are you sure you want to execute this action?</p>`,
                confirmText: "Update locations",
                cancelText: "Cancel"
            }, 'static');
        });
        event.value = '';
    }

    @HandleEvent("modalOpened")
    modalOpened(modal: ModalComponent) {
        this.anyModalOpened = true;
    }

    @HandleEvent("modalClosed")
    modalClosed(modal: ModalComponent) {
        this.anyModalOpened = false;
    }
}
