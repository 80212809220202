import {Component, Input} from '@angular/core';
import {AppContext} from "../../../../../app-context";
import {OrganisationProvider} from "../../../../../utils/source-providers/organisation-provider";
import {EntityType} from "../../../../../handlers/entity";

@Component({
  selector: 'app-report-selection',
  templateUrl: './report-selection.component.html',
  styleUrls: ['./report-selection.component.scss']
})
export class ReportSelectionComponent {
  appContext = AppContext;
  @Input() sourcesProvider: OrganisationProvider;

  private _sourceType: EntityType | string;

  @Input()
  set sourceType(sourceType: EntityType | string) {
    this._sourceType = sourceType;
  }

  getSourceType(): EntityType {
    return this._sourceType as EntityType;
  }

  get length(): number {
    return this.sourcesProvider.getAllSourcesByType(this.getSourceType()).length;
  }

  get label(): string {
    return this.length > 1 ? `${this.getSourceType()}s` : this.getSourceType();
  }
}
