import {Component, Input, OnInit} from '@angular/core';
import {View} from "src/app/common/view";
import {Handler} from "src/app/common/handler";
import {AppContext} from "../../../../../app-context";
import {ContractEntity, LinkedContractEntity} from "../../../../../handlers/app-entity-handler.service";
import {LinkedContract, UnlinkContract} from "@flowmaps/flowmaps-typescriptmodels";
import {
    LinkContractConnectionComponent,
    LinkContractToConnectionComponentData
} from "../../../connections/link-contract-connection/link-contract-connection.component";
import {cloneDeep} from "lodash";

@Component({
    selector: 'app-linked-contract-connection-overview-item',
    templateUrl: './linked-contract-connection-overview-item.component.html',
    styleUrls: ['./linked-contract-connection-overview-item.component.scss']
})
@Handler()
export class LinkedContractConnectionOverviewItemComponent extends View implements OnInit {
    appContext = AppContext;

    @Input() linkedContract: LinkedContractEntity;
    contract: ContractEntity;

    ngOnInit() {
        this.subscribeTo("getContract").subscribe(c => this.contract = c);
    }

    openLinkContractToConnectionModal = (linkedContract: LinkedContract) => this.openModal(LinkContractConnectionComponent, <LinkContractToConnectionComponentData>{
        linkedContract: cloneDeep(linkedContract),
        entity: this.linkedContract.entity
    });

    unlinkContractFromConnection = (linkedContract: LinkedContract) => this.sendCommand('com.flowmaps.api.organisation.UnlinkContract', <UnlinkContract>{
        linkId: linkedContract.linkId,
        organisationId: this.linkedContract.entity.organisation.organisationId
    });

    linkContractToConnection = () => {
        this.openLinkContractToConnectionModal({
            contractId: this.contract.contract.contractId,
            linkId: '',
            dateRange: {
                start: this.linkedContract.entity.connection.linkedContractsLastEndDate || '',
                end: ''
            }
        })
    }
}
