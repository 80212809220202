import {Component, forwardRef} from '@angular/core';
import {ConnectionType, DataType, ElectricityConnectionInfo, MeterType} from "@flowmaps/flowmaps-typescriptmodels";
import {BaseMeasurementChartComponent, MeasurementDataset} from "../base-measurement-chart";
import {EntityType} from "../../../handlers/entity";

@Component({
    selector: 'app-electricity-chart',
    templateUrl: './electricity-chart.component.html',
    styleUrls: ['./electricity-chart.component.scss'],
    providers: [{provide: BaseMeasurementChartComponent, useExisting: forwardRef(() => ElectricityChartComponent)}]
})
export class ElectricityChartComponent extends BaseMeasurementChartComponent {
    contractedCapacityRequiredForPower = true;

    showDataNormally = (): boolean => this.options.splitOffPeak;

    measurementTypes = (): DataType[] => {
        let baseMeasurements = this.showConsumption() ? [DataType.electricityConsumption, DataType.electricityConsumptionOffPeak] : [];
        if (this.options.showReactive) {
            baseMeasurements = baseMeasurements.concat(DataType.electricityConsumptionReactive, DataType.electricityFeedInReactive,
                DataType.electricityConsumptionReactiveOffPeak, DataType.electricityFeedInReactiveOffPeak);
        }
        if (this.options.showGrossProduction) {
            baseMeasurements = baseMeasurements.concat([DataType.electricityGrossProduction]);
            if (this.options.showReactive) {
                baseMeasurements = baseMeasurements.concat([DataType.electricityGrossProductionReactive]);
            }
        }
        if (this.showConsumption() && this.groupByEntityIdEnabled()) {
            baseMeasurements = baseMeasurements.concat([DataType.electricityIntermediateConsumption]);
            if (this.options.showReactive) {
                baseMeasurements = baseMeasurements.concat(DataType.electricityIntermediateConsumptionReactive);
            }
        }
        return baseMeasurements;
    };

    productionDataTypes = (): DataType[] => this.showFeedIn() ? [DataType.electricityFeedIn, DataType.electricityFeedInOffPeak] : [];

    powerDataType = (): DataType => DataType.electricityPower;

    consumptionProductionLink = (): Map<DataType, DataType> => new Map<DataType, DataType>()
        .set(DataType.electricityConsumption, DataType.electricityFeedIn)
        .set(DataType.electricityConsumptionOffPeak, DataType.electricityFeedInOffPeak);

    measurementIntermediateLink = (): Map<DataType, DataType[]> => {
        const intermediateValues = [DataType.electricityIntermediateConsumption]
            .concat(this.options.showGrossProduction ? [DataType.electricityGrossProduction] : []);
        return new Map<DataType, DataType[]>()
            .set(DataType.electricityConsumption, intermediateValues)
            .set(DataType.electricityConsumptionOffPeak, intermediateValues);
    };

    connectionType = (): ConnectionType => ConnectionType.Electricity;

    openModal = () => this.openModalWithType(ElectricityChartComponent);

    measurementUnit = () => this.context.getMeasurementUnit(DataType.electricityConsumption);

    measurementTypesMapped = (): {[key: string]: DataType[]} => {
        const measurementTypes = {
            [DataType.electricityConsumption]: [DataType.electricityConsumption, DataType.electricityConsumptionOffPeak],
            [DataType.electricityIntermediateConsumption]: [DataType.electricityIntermediateConsumption],
            [DataType.electricityGrossProduction]: [DataType.electricityGrossProduction]
        };
        if (this.options.showReactive) {
            measurementTypes[DataType.electricityConsumptionReactive] = [DataType.electricityConsumptionReactive, DataType.electricityConsumptionReactiveOffPeak];
            measurementTypes[DataType.electricityFeedInReactive] = [DataType.electricityFeedInReactive, DataType.electricityFeedInReactiveOffPeak];
        }
        if (this.options.showGrossProduction) {
            measurementTypes[DataType.electricityGrossProduction] = [DataType.electricityGrossProduction];
            if (this.options.showReactive) {
                measurementTypes[DataType.electricityGrossProductionReactive] = [DataType.electricityGrossProductionReactive];
            }
        }
        return measurementTypes;
    }

    productionDataTypesMapped = (): {[key: string]: DataType[]} => ({
        [DataType.electricityFeedIn]: [DataType.electricityFeedIn, DataType.electricityFeedInOffPeak],
    })

    get y2AxisTitle(): string {
        const hasPowerSelected = this.getSelectedConnections().length === 1 && this.options.showPower;
        return !hasPowerSelected ? super.y2AxisTitle : null;
    }

    addConnectionTypeSpecificData(data: MeasurementDataset[]) {
        if (this.showConsumption()) {
            super.addConnectionTypeSpecificData(data);
        }
        if (this.showFeedIn()) {
            const contractedCapacity = this.feedInContractedCapacity();
            if (contractedCapacity) {
                this.addPowerToChart(data, DataType.electricityFeedInPower, undefined, contractedCapacity);
            }
        }
    }

    feedInContractedCapacity() {
        const connections = this.getSelectedConnections(this.connectionType());
        if (this.powerAllowed(this.connectionType())) {
            if (this._dataOptions.dataProvider.isPortfolioProvider()) {
                return connections.length === 1 ? (connections[0]?.info as ElectricityConnectionInfo)?.contractedFeedInCapacity : null;
            } else {
                return (connections.find(c => c.info.code === this.showPowerForEan(this.connectionType())) as ElectricityConnectionInfo)?.contractedFeedInCapacity;
            }
        }
        return null;
    }

    hasProductionMeter = (): boolean =>
        this._dataOptions.dataProvider.sourceProvider.getAllSourcesByType(EntityType.meter)
            .some(s => s.meterType === MeterType.GROSS_PRODUCTION);
}
