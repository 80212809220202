import {Component, Input, OnInit} from '@angular/core';
import {View} from "src/app/common/view";
import {Handler} from "src/app/common/handler";
import {AppContext} from "../../../../../app-context";
import {ContractEntity, LinkedContractEntity} from "../../../../../handlers/app-entity-handler.service";
import {LinkedContract, UnlinkContract} from "@flowmaps/flowmaps-typescriptmodels";
import {cloneDeep} from "lodash";
import {
    LinkContractOrganisationComponent,
    LinkContractToOrganisationData
} from "../../link-contract-organisation/link-contract-organisation.component";

@Component({
    selector: 'app-linked-contract-overview-item',
    templateUrl: './linked-contract-overview-item.component.html',
    styleUrls: ['./linked-contract-overview-item.component.scss']
})
@Handler()
export class LinkedContractOverviewItemComponent extends View implements OnInit {
    appContext = AppContext;
    @Input() linkedContract: LinkedContractEntity;
    contract: ContractEntity;

    ngOnInit() {
        this.subscribeTo("getContract").subscribe(c => this.contract = c);
    }

    openLinkContractModal = (linkedContract: LinkedContract) =>
        this.openModal(LinkContractOrganisationComponent, <LinkContractToOrganisationData>{
            organisationId: this.contract.organisation.organisationId,
            linkedContract: cloneDeep(linkedContract),
            contract: this.contract
        });

    linkContractToOrganisation = () => {
        this.openLinkContractModal({
            contractId: this.contract.contract.contractId,
            linkId: '',
            dateRange: {
                start: this.linkedContract.entity.organisation.linkedContractsLastEndDate || '',
                end: ''
            },
        })
    }

    unlinkContractFromOrganisation = (linkedContract: LinkedContract) =>
        this.sendCommand("com.flowmaps.api.organisation.UnlinkContract", <UnlinkContract>{
            linkId: linkedContract.linkId,
            organisationId: this.contract.organisation.organisationId
        });
}
