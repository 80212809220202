import {Component, EventEmitter, Input, Output} from '@angular/core';
import {FacetStats} from "../facet-filter.component";
import {FacetValueResult} from "@flowmaps/flowmaps-typescriptmodels";

@Component({
  selector: 'app-facet-filter-value',
  templateUrl: './facet-filter-value.component.html',
  styleUrls: ['./facet-filter-value.component.scss']
})
export class FacetFilterValueComponent {
  @Input() term: string;
  @Input() facet: FacetStats;
  @Input() facetValue: FacetValueResultFormatted;

  @Output() valueSelected: EventEmitter<boolean> = new EventEmitter<boolean>();

  private _selectedValues: string[];
  private _selected: boolean;

  get selected() {
    return this._selected;
  }

  set selected(selected: boolean) {
    this._selected = selected;
  }

  get selectedValues(): string[] {
    return this._selectedValues;
  }

  @Input()
  set selectedValues(selectedValues: string[]) {
    this._selectedValues = selectedValues;
    this.selected = this.selectedValues.includes(this.facetValue.value);
  }

  translateValue = (): boolean => this.facet.translateValues ? this.facet.translateValues(this.facet.name) : false;
}

export interface FacetValueResultFormatted extends FacetValueResult {
  formattedValue?: string;
}
