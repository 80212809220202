import {Component, Input, TemplateRef, ViewChild} from '@angular/core';
import {Error, ErrorStatus, MuteErrors, ResolveErrors, UnmuteErrors} from "@flowmaps/flowmaps-typescriptmodels";
import moment from "moment";
import {Handler} from "src/app/common/handler";
import {View} from "src/app/common/view";
import {AppContext} from "../../../app-context";
import {AppCommonUtils} from "../../../common/app-common-utils";
import {checkValidity} from "../../../common/utils";
import {openConfirmationModalWithCallback} from "../../../app-utils";
import {ModalConfirmAutofocus, ModalConfirmAutofocusData} from "../../../common/modal-confirm/modal-confirm.component";

@Component({
    selector: 'app-issue-selection',
    templateUrl: './issue-selection.component.html',
    styleUrls: ['./issue-selection.component.scss']
})
@Handler()
export class IssueSelectionComponent extends View {
    appContext = AppContext;

    @Input() selectedItems: Error[] = [];
    @Input() cancelMethod: () => void;
    @ViewChild("muteErrorTemplate") muteErrorTemplate: TemplateRef<any>;

    muteDate: string;
    tomorrow = (): string => moment().startOf("day").add(1, "day").toISOString();

    get hasAnyMutedErrors() {
        return this.selectedItems.some(s => s.status === ErrorStatus.MUTED);
    }

    get hasAnyOpenErrors() {
        return this.selectedItems.some(s => s.status === ErrorStatus.OPEN);
    }

    unmuteErrors = () => {
        if (this.selectedItems.length === 0) {
            AppCommonUtils.registerError("Please select at least 1 record");
            return;
        }
        if (checkValidity(this.elementRef)) {
            openConfirmationModalWithCallback((confirmed) => {
                if (confirmed) {
                    this.sendCommand("com.flowmaps.api.monitoring.errors.UnmuteErrors", <UnmuteErrors>{
                        errorIds: this.selectedItems.map(s => s.errorId)
                    }, () => {
                        this.selectedItems.forEach(s => s.status = ErrorStatus.OPEN);
                        this.cancelMethod();
                    });
                }
            }, ModalConfirmAutofocus, <ModalConfirmAutofocusData>{
                type: "info",
                title: "Unmute errors",
                innerHtmlMessage: `<p><span>You are about to unmute</span><span class="notranslate fw-bold"> ${this.selectedItems.length} </span><span>bulk errors</span></p><p class="fw-bold">Are you sure you want to execute this action?</p>`,
                confirmText: "Unmute errors",
                cancelText: "Cancel"
            }, 'static');
        }
    }

    muteErrors = () => {
        if (this.selectedItems.length === 0) {
            AppCommonUtils.registerError("Please select at least 1 record");
            return;
        }
        if (checkValidity(this.elementRef)) {
            openConfirmationModalWithCallback((confirmed) => {
                if (confirmed) {
                    this.sendCommand("com.flowmaps.api.monitoring.errors.MuteErrors", <MuteErrors> {
                        errorIds: this.selectedItems.map(s => s.errorId),
                        deadline: this.muteDate
                    }, () => {
                        this.selectedItems.forEach(s => {
                            s.muteDeadline = this.muteDate;
                            s.status = ErrorStatus.MUTED;
                        });
                        this.muteDate = null;
                        this.cancelMethod();
                    });
                }
            }, ModalConfirmAutofocus, <ModalConfirmAutofocusData>{
                type: "info",
                title: "Mute errors",
                body: this.muteErrorTemplate,
                confirmText: "Mute errors",
                cancelText: "Cancel"
            }, 'static');
        }
    }

    resolveErrors = () => {
        if (this.selectedItems.length === 0) {
            AppCommonUtils.registerError("Please select at least 1 record");
            return;
        }
        if (checkValidity(this.elementRef)) {
            openConfirmationModalWithCallback((confirmed) => {
                if (confirmed) {
                    this.sendCommand("com.flowmaps.api.monitoring.errors.ResolveErrors", <ResolveErrors> {
                        errorIds: this.selectedItems.map(s => s.errorId)
                    }, () => {
                        this.selectedItems.forEach(s => s.status = ErrorStatus.RESOLVED);
                        this.cancelMethod();
                    });
                }
            }, ModalConfirmAutofocus, <ModalConfirmAutofocusData>{
                type: "info",
                title: "Resolve error",
                innerHtmlMessage: `<p><span>You are about to resolve</span><span class="notranslate fw-bold"> ${this.selectedItems.length} </span><span>bulk errors</span></p><p class="fw-bold">Are you sure you want to execute this action?</p>`,
                confirmText: "Resolve error",
                cancelText: "Cancel"
            }, 'static');
        }
    }
}
