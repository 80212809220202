import {forkJoin, mergeMap, Observable} from "rxjs";
import {BuildingType, Location, UpsertLocation, UpsertLocations} from "@flowmaps/flowmaps-typescriptmodels";
import {
    ArrayField,
    ArrayTemplate,
    ColumnTemplate,
    downloadWorkbook,
    exportExcelFromWorkBook,
    MappedField,
    parseExcel,
    RequiredField,
    ValidatedField,
    WorkBookTemplate
} from "../../../../common/upload/excel.utils";
import {map} from "rxjs/operators";
import lodash from "lodash";
import {ComparatorChain} from '../../../../common/comparator-chain';
import {hasChanged, uuid} from '../../../../common/utils';
import {Entity} from "../../../../handlers/entity";
import {sendQuery} from "../../../../common/app-common-utils";
import {EnergyLabels} from "../../../common/energy-label/energy-label.component";

export function downloadLocationsExcel(locations: Entity[]) {
    forkJoin([findBuildingTypes()]).subscribe(([buildingTypes]) => {
        const data = prepareData(locations, buildingTypes);
        return exportExcelFromWorkBook(downloadWorkbook("/assets/templates/locations-template.xlsx"),
            locationsTemplate, data, "locations-export.xlsx");
    });
}

export function uploadLocationsExcel(file: File): Observable<UpsertLocations> {
    return parseExcel(file, locationsTemplate)
        .pipe(mergeMap(data => sendQuery("getLocations").pipe(map(
            (locations: Location[]) => {
                const locationsMap = lodash.keyBy(locations, l => l.locationId);
                const diffs = data.locations.filter(u => hasChanged(u.info, locationsMap[u.locationId]?.info));
                console.info("diffs: ", diffs.map(d => ({old: locationsMap[d.locationId]?.info, new: d.info})));
                return <UpsertLocations>{
                    updates: diffs.map(d => (<UpsertLocation>{
                        organisationId: d.organisationId,
                        locationId: d.locationId || uuid(),
                        info: d.info
                    }))
                };
            }))))
}

const organisationComparator: ComparatorChain = new ComparatorChain('organisationInfo.name');
const locationComparator: ComparatorChain = new ComparatorChain('organisationInfo.name', 'info.name', 'info.department');

function prepareData(locations: Entity[], buildingTypes: BuildingType[]) {
    return {
        locations: locations.sort(locationComparator.compare).map(l => ({
            organisationId: l.organisation.organisationId,
            organisation: l.organisation.info.name,
            locationId: l.location.locationId,
            info: {
                name: l.location.info.name,
                department: l.location.info.department,
                address: {
                    street: l.location.info.address.street,
                    number: l.location.info.address.number,
                    addition: l.location.info.address.addition,
                    zipCode: l.location.info.address.zipCode,
                    city: l.location.info.address.city,
                    country: l.location.info.address.country,
                    geoLocation: {
                        latitude: l.location.info.address.geoLocation.latitude,
                        longitude: l.location.info.address.geoLocation.longitude,
                    }
                },
                energyLabel: l.location.info.energyLabel,
                buildingType: buildingTypes.find(buildingType => buildingType.buildingTypeId === l.location.info.buildingType),
                area: l.location.info.area,
                buildingCode: l.location.info.buildingCode,
                objectNumber: l.location.info.objectNumber
            },
        })),
        organisations: lodash.uniqBy(locations, 'organisationId').sort(organisationComparator.compare)
            .map(l => ({
                organisationId: l.organisation.organisationId,
                name: l.organisation.info.name,
                chamberOfCommerceNumber: l.organisation.info.chamberOfCommerceNumber,
            })),
        referenceData: {
            buildingTypes: buildingTypes
        }
    };
}

const locationsTemplate: WorkBookTemplate = {
    sheets: [
        {
            name: 'Locations',
            template: {
                locations: new ArrayTemplate({
                    organisationId: new RequiredField('A$'),
                    organisation: new RequiredField('B$'),
                    locationId: 'C$',
                    info: {
                        name: new RequiredField('D$'),
                        department: 'E$',
                        address: {
                            street: new RequiredField('F$'),
                            number: new RequiredField('G$'),
                            addition: 'H$',
                            zipCode: new MappedField(new RequiredField('I$'), r => r.toUpperCase()),
                            city: new RequiredField('J$'),
                            country: new MappedField(new RequiredField('K$'), r => r.toUpperCase()),
                            geoLocation: {
                                latitude: new RequiredField('L$'),
                                longitude: new RequiredField('M$'),
                            }
                        },
                        energyLabel: new ValidatedField('N$', v => {
                            if (v && !Object.values(EnergyLabels).includes(v)) {
                                throw 'Unknown energy label: ' + v;
                            }
                        }),
                        buildingType: new MappedField('O$', r => requireBuildingType(r)
                                .pipe(map(v => v && v.buildingTypeId)),
                            (v: BuildingType) => v && v.info.code),
                        area: 'P$',
                        buildingCode: 'Q$',
                        objectNumber: 'R$'
                    }
                }, [2, 20000])
            }
        },
        {
            name: 'Organisations',
            template: {
                organisations: new ArrayTemplate({
                    organisationId: new RequiredField('A$'),
                    name: new RequiredField('B$'),
                    chamberOfCommerceNumber: 'C$',
                }, [2, 20000])
            }
        },
        {
            name: 'Reference data',
            template: new ColumnTemplate({
                buildingTypes: new ArrayField("A$", 2, (data) => data.buildingTypes.map(s => s.info.code)),
                energyLabels: new ArrayField("B$", 2, () => Object.values(EnergyLabels))
            }, "referenceData")
        }
    ]
};

function findBuildingTypes(): Observable<BuildingType[]> {
    return sendQuery("findBuildingTypes");
}

function requireBuildingType(code: string): Observable<BuildingType> {
    return findBuildingTypes().pipe(map(r => {
        if (!code) {
            return null;
        }
        const result = r.find(r => r.info.code === code);
        if (!result) {
            throw "Unknown building type: " + code;
        }
        return result;
    }));
}