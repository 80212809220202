import {Component, forwardRef, Input} from '@angular/core';
import {ServiceProvider, ServiceProviderType} from "@flowmaps/flowmaps-typescriptmodels";
import {AbstractValueAccessorComponent} from "../../../common/component/value-accessor.component";
import {NG_VALUE_ACCESSOR} from "@angular/forms";
import {map, Observable} from "rxjs";
import {subscribeTo} from "../../../common/app-common-utils";

@Component({
  selector: 'app-service-provider-select',
  templateUrl: './service-provider-select.component.html',
  styleUrls: ['./service-provider-select.component.scss'],
  providers: [
    {provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => ServiceProviderSelectComponent), multi: true}
  ]
})
export class ServiceProviderSelectComponent extends AbstractValueAccessorComponent<ServiceProvider> {
  @Input() type: ServiceProviderType | string;
  @Input() required: boolean;
  @Input() disabled: boolean;

  private _value: ServiceProvider;

  get value(): ServiceProvider {
    return this._value;
  }

  set value(value: ServiceProvider) {
    this.writeValue(value);
  }

  writeValue(value: ServiceProvider): void {
    this._value = value;
    this.onModelChange();
  }

  getProviders = (): Observable<ServiceProvider[]> => {
    return subscribeTo("getServiceProviders", this.type)
        .pipe(map(providers => this.required ? providers : [{name: '—', code: null} as ServiceProvider, ...providers]));
  }

  providerFormatter = (value: ServiceProvider) => value ? `${value.name}` : null;

  onProviderChange(event: ServiceProvider): void {
    this.writeValue(event.code ? event : null);
  }
}
