import {Component, inject, Input} from '@angular/core';
import {View} from "src/app/common/view";
import {Handler} from "src/app/common/handler";
import {AppContext} from "../../../../../app-context";
import {ContractEntity} from "../../../../../handlers/app-entity-handler.service";
import {ContractPeriod, DeleteContractPeriod} from "@flowmaps/flowmaps-typescriptmodels";
import {Router} from "@angular/router";
import {cloneDeep} from "lodash";
import {
    ContractPeriodComponentData,
    ContractPeriodDetailsComponent
} from "../../contract-period-details/contract-period-details.component";

@Component({
    selector: 'app-contract-overview-item',
    templateUrl: './contract-overview-item.component.html',
    styleUrls: ['./contract-overview-item.component.scss']
})
@Handler()
export class ContractOverviewItemComponent extends View {
    appContext = AppContext;
    @Input() contract: ContractEntity;
    private router: Router = inject(Router);

    trackByForRecord = (index: number, record: ContractPeriod) => record.periodId;

    navigateToContract = () => this.router.navigate(['/contract', this.contract.contract.contractId]);

    addNewContractPeriod = (): void => this.openContractPeriodModal({
        periodId: '',
        data: {
            dateRange: {
                start: '',
                end: ''
            },
            offPeakRate: null,
            rate: null,
            feedInRate: null,
            feedInOffPeakRate: null,
            standingCharge: null,
            supplier: null,
            source: null
        },
    })

    openContractPeriodModal(contractPeriod?: ContractPeriod): void {
        this.openModal(ContractPeriodDetailsComponent, cloneDeep(<ContractPeriodComponentData>{
            period: contractPeriod,
            contract: this.contract,
            supplier: contractPeriod.data.supplier,
            source: contractPeriod.data.source
        }));
    }


    deleteContractPeriod = (record: ContractPeriod) =>
        this.sendCommand("com.flowmaps.api.organisation.DeleteContractPeriod", <DeleteContractPeriod>{
            periodId: record.periodId,
            contractId: this.contract.contract.contractId,
            organisationId: this.contract.organisation.organisationId
        })
}
