import {Component, forwardRef} from '@angular/core';
import {BaseMeasurementChartComponent, ChartDatasetExtended, MeasurementDataset} from "../base-measurement-chart";
import {ConnectionType, DataType} from "@flowmaps/flowmaps-typescriptmodels";
import {lodash} from "../../../common/utils";
import {DashboardContext} from "../../dashboard/dashboard.context";
import {ChartDataPerMeasurement} from "../../../utils/measurements-data-provider";

@Component({
    selector: 'app-renewable-fuel-units',
    templateUrl: './renewable-fuel-units.component.html',
    styleUrls: ['./renewable-fuel-units.component.scss'],
    providers: [{provide: BaseMeasurementChartComponent, useExisting: forwardRef(() => RenewableFuelUnitsComponent)}]
})
export class RenewableFuelUnitsComponent extends BaseMeasurementChartComponent {
    renewableUnits: number = 0;
    totalHbeRenewable: number = 0;

    connectionType(): ConnectionType {
        return null;
    }

    consumptionProductionLink = (): Map<DataType, DataType> => new Map<DataType, DataType>();

    measurementIntermediateLink = (): Map<DataType, DataType[]> => new Map<DataType, DataType[]>();

    measurementTypes = (): DataType[] => [DataType.electricityConsumption];

    measurementUnit(): string {
        return null;
    }

    productionDataTypes(): DataType[] {
        return [DataType.electricityGrossProduction];
    }

    powerDataType = (): DataType => null;

    setData(result: ChartDataPerMeasurement, measurements: MeasurementDataset[], estimatedMeasurements: MeasurementDataset[]) {
        const totalProduction = lodash.sum(measurements.filter(d => this.productionDataTypes()
            .includes(d.measurementType)).flatMap(d => d.dataset.data));
        const totalHbeProd = totalProduction * 0.0036 * 4;
        const totalHbeFromNet = totalHbeProd * 0.304;
        this.renewableUnits = lodash.round(DashboardContext.generateRandom(
            JSON.stringify(this._dataOptions.dataProvider.sourceProvider.selectedSources), 20, 40), 1) / 100;
        this.totalHbeRenewable = lodash.round((totalHbeProd + totalHbeFromNet) * this.renewableUnits, 1);
        if (this.totalHbeRenewable === 0) {
            this.renewableUnits = 0;
        }
        this.chartDataProvider.emit({
            datasets: [{
                measurementType: null,
                dataset: this.getRenewableUnitsDataset(this.renewableUnits)
            }]
        });
        this.changeDetectorRef.detectChanges();
    }

    private getRenewableUnitsDataset = (renewableUnits: number): ChartDatasetExtended => ({
        label: "Renewable fuel units",
        data: [renewableUnits, 1 - renewableUnits],
        cutout: '80%',
        backgroundColor: [
            "rgb(94, 195, 99, 0.8)",
            "rgba(223, 232, 241, 0.8)"
        ],
    }) as any;
}