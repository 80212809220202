import {lodash} from "../../common/utils";
import {ConnectionType, MeterType, SourceIds} from "@flowmaps/flowmaps-typescriptmodels";
import {EventEmitter} from "@angular/core";
import {cloneDeep} from "lodash";
import {Entity, EntityType} from "../../handlers/entity";

export abstract class SourcesProvider<T> {
    private _selectedSources: SourceIds;
    filteredData: TreeViewItem[];
    hierarchyData: TreeViewItem[] = [];
    flattenedData: TreeViewItem[] = [];
    selectionUpdated: EventEmitter<SourceInfo[]> = new EventEmitter<SourceInfo[]>();
    filterTerm: string;
    data: TreeViewItem[];

    dataCollected: EventEmitter<T[]> = new EventEmitter<T[]>();

    abstract treeViewItemMapper(o: T[]): TreeViewItem[];
    abstract collectData();
    abstract setData(data: T[]);

    get selectedSources() {
        if (!this._selectedSources) {
            this._selectedSources = {
                organisationIds: [],
                locationIds: [],
                connectionIds: [],
                meterIds: []
            };
        }
        return this._selectedSources;
    }

    set selectedSources(sources: SourceIds) {
        this._selectedSources = sources;
    }

    findById(id: string) {
        return this.flattenedData?.find(v => [v.info.id, ...v.info.aliases].includes(id));
    }

    filterTermChanged = (term: string) => {
        this.filterTerm = term;
        this.data = this.getData();
    }

    getData = () => this.filterTerm ? this.flattenedData : this.hierarchyData;

    getAllItems = () : Entity[] => {
        return this.getData().map(i => i.info.source);
    }

    getAllSelectedSourceIds = (selectedSources : SourceIds = this.selectedSources): string[] => selectedSources.organisationIds
        .concat(selectedSources.locationIds || [])
        .concat(selectedSources.connectionIds || [])
        .concat(selectedSources.meterIds || [])|| [];

    isDirectlySelectedBy = (sourceInfo: SourceInfo): boolean => {
        const sourceIds = [sourceInfo.id, ...sourceInfo.aliases];
        return this.getAllSelectedSourceIds().some(id => sourceIds.includes(id));
    };

    isSelectedById = (id: string): boolean => this.getFlatSourceSelection().flatMap(s => [s.id, ...s.aliases]).includes(id);

    getSelectedSourcesByType(source: EntityType): string[] {
        switch (source) {
            case EntityType.organisation:
                return this.selectedSources.organisationIds;
            case EntityType.location:
                return this.selectedSources.locationIds;
            case EntityType.connection:
                return this.selectedSources.connectionIds;
            case EntityType.meter:
                return this.selectedSources.meterIds;
        }
    }

    deleteSource(source: EntityType, sourceId: SourceInfo) {
        const sourceIds = [sourceId.id, ...sourceId.aliases];
        switch (source) {
            case EntityType.organisation:
                this.selectedSources.organisationIds = this.selectedSources.organisationIds.filter(s => !sourceIds.includes(s));
                break;
            case EntityType.location:
                this.selectedSources.locationIds = this.selectedSources.locationIds.filter(s => !sourceIds.includes(s));
                break;
            case EntityType.connection:
                this.selectedSources.connectionIds = this.selectedSources.connectionIds.filter(s => !sourceIds.includes(s));
                break;
            case EntityType.meter:
                this.selectedSources.meterIds = this.selectedSources.meterIds.filter(s => !sourceIds.includes(s));
                break;
        }
    }

    updateFilteredData(data: TreeViewItem[]) {
        this.filteredData = data;
    }

    protected flatMapData(data: TreeViewItem[]) {
        return lodash.flatMap(data, r => {
            const arr = SourcesProvider.flatMapSubItems(r);
            if (!r.parent) {
                return arr.concat(r);
            }
            return arr;
        });
    }

    getTreeViewSelection(selectedSources : SourceIds = this.selectedSources) {
        return this.getAllSelectedSourceIds(selectedSources)
            .map(s => this.findById(s))
            .filter(s => s);
    }

    getFlatSourceSelection() {
        const treeViewSelection = this.getTreeViewSelection();
        return treeViewSelection.length > 0
            ? lodash.unionBy(this.flatMapData(treeViewSelection).concat(treeViewSelection).map(s => s.info), info => [info.id, ...info.aliases])
            : this.flattenedData.map(s => s.info);
    }

    getAllSourcesByType = (type: EntityType): SourceInfo[] => this.getFlatSourceSelection().filter(s => s.type === type);

    hasSourceForConnectionType = (connectionType: ConnectionType): boolean => this.getAllSourcesByType(EntityType.connection)
        .some(s => s.connectionType === connectionType);

    hasSubMetersForConnectionType = (connectionType: ConnectionType): boolean => this.getAllSourcesByType(EntityType.meter)
            .some(c => (connectionType === ConnectionType.Electricity ?
                [MeterType.INTERMEDIATE, MeterType.GROSS_PRODUCTION] : [MeterType.PRIMARY]).includes(c.meterType));

    getSelection() {
        return this.flatMapData(this.getTreeViewSelection()).map(s => s.info);
    }

    sourceSelectionDeleted(item: TreeViewItem | TreeViewItem[]) {
        const items = Array.isArray(item) ? item : [item];
        items.forEach(i => {
            this.deleteSource(i.info.type, i.info);
        });
        this.selectionUpdated.emit(this.getSelection());
    }

    getSelectedParent(source: TreeViewItem): TreeViewItem {
        if (source && source.parent) {
            if (this.isDirectlySelectedBy(source.parent.info)) {
                return source.parent;
            }
            return this.getSelectedParent(source.parent);
        }
        return null;
    }

    isSelected(source: TreeViewItem): boolean {
        if (this.getSelectedParent(source)) {
            return true;
        }
        if (!source.subItems || source.subItems.length == 0) {
            return this.isDirectlySelectedBy(source.info) || (!!source.parent && this.isDirectlySelectedBy(source.parent.info));
        }
        return this.isDirectlySelectedBy(source.info) || source.subItems.every(s => this.isSelected(s));
    }

    selectSource(source: TreeViewItem, value: boolean) {
        if (value) {
            if (!this.isDirectlySelectedBy(source.info)) {
                const sourcesByType = this.getSelectedSourcesByType(source.info.type);
                sourcesByType.push(source.info.id, ...source.info.aliases);
            }
        } else {
            this.deleteSource(source.info.type, source.info);
        }
    }

    sourceSelectionAfterCleanup(): SourceIds {
        const backup = cloneDeep(this.selectedSources);
        this.cleanupSelection();
        const sources: SourceIds = {
            organisationIds: this.getSelectedSourcesByType(EntityType.organisation),
            locationIds: this.getSelectedSourcesByType(EntityType.location),
            connectionIds: this.getSelectedSourcesByType(EntityType.connection),
            meterIds: this.getSelectedSourcesByType(EntityType.meter)
        };
        this._selectedSources = backup;
        return sources;
    }

    private cleanupSelection() {
        let recheck = false;
        const before = this.getAllSelectedSourceIds();
        this.getAllSelectedSourceIds().forEach(i => {
            const v: TreeViewItem = this.findById(i);
            if (v && v.parent && this.isSelected(v.parent)) {
                this.selectSource(v.parent, true);
                this.selectSource(v, false);
                recheck = true;
            }
        });
        if (recheck && !lodash.isEqual(before, this.getAllSelectedSourceIds())) {
            this.cleanupSelection();
        }
    }

    static flatMapSubItems(source: TreeViewItem): TreeViewItem[] {
        return source.subItems?.length
            ? source.subItems.flatMap(s => s.subItems?.length > 0 ? this.flatMapSubItems(s).concat(s) : [s])
            : [source];
    }

    protected treeViewComparator = (a: TreeViewItem, b: TreeViewItem): number => {
        return a.info.name < b.info.name ? -1 : 1;
    }
    selectSources = (entityIds: string[]) => {
        entityIds.map(id => this.findById(id))
            .filter(id => !!id)
            .forEach(i => this.selectSource(i, true));
    }
}

export interface TreeViewItem {
    icon: string;
    checkbox: boolean;
    expanded: boolean;
    visible: boolean;
    info: SourceInfo;
    parent?: TreeViewItem;
    subItems?: TreeViewItem[];
}

export interface SourceInfo {
    id: string;
    aliases: string[];
    name: string;
    filteredNameOverride: string;
    source: Entity;
    type: EntityType;
    connectionType: ConnectionType;
    meterType: MeterType;
}