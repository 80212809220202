import {CustomAxesPlugin} from "../scatter-chart/plugins/custom-axes.plugin";
import {Chart} from "chart.js";
import {EnergyCompassParisProofLinePlugin} from "./energy-compass-paris-proof-line.plugin";
import {TranslateDirective} from "../../../common/utils/translate.directive";

export class EnergyCompassLabelPlugin extends CustomAxesPlugin {
    id = "energyCompassLabels";
    fullScreen: boolean;

    private cornerRadius: number = 3;
    private lineHeight: number = 12;
    private boxMargin = 5;
    private leftPadding = 125;

    private weiiBoxColors = [
        "rgb(17, 48, 121)",
        "rgb(73, 34, 172)",
        "rgb(141, 44, 175)",
        "rgb(187, 51, 177)",
        "rgb(187, 51, 81)",
        "rgb(214, 46, 31)",
        "rgb(233, 51, 37)"
    ];


    afterDraw = (chart: Chart) => {
        const {ctx} = chart;
        ctx.save();
        this.drawWeiiLabels(chart);
        this.drawEnergyLabels(chart);
        ctx.restore();
    }

    private drawEnergyLabels(chart: Chart) {
        const {ctx, chartArea: {top, width, height}, scales: {x}} = chart;
        const ticks = x.getTicks();
        const bottom = top + height;
        ticks.forEach((t, i) => {
            const label = t.label === EnergyCompassParisProofLinePlugin.unknownLabel ? "?" : t.label as string;
            const cssString = label === "?" ? "q" : label.substring(0, 1);
            const color = t.label === EnergyCompassParisProofLinePlugin.unknownLabel ? "rgb(175,175,175)"
                : window.getComputedStyle(document.body).getPropertyValue("--energy-label-" + cssString);
            const widthOfAxis = ((width * 0.92) / (ticks.length - 1));
            const position = this.getPositionOfTickX(chart, t, x);

            ctx.fillStyle = "white";
            const height = 80;
            ctx.fillRect(position.x, position.y + 2, widthOfAxis, height);

            ctx.strokeStyle = "white";
            ctx.fillStyle = color;
            const padding = 5;
            ctx.beginPath();
            ctx.roundRect(position.x + padding, bottom + padding + 5, widthOfAxis - (padding * 2), height - (padding * 2) - 15, this.cornerRadius);
            ctx.stroke();
            ctx.fill();

            ctx.fillStyle = "white";
            ctx.textAlign = 'center';
            ctx.font = `bold ${this.fullScreen ? '14px' : '12px'} "Cerebri Sans"`;
            ctx.fillText(label, position.x + (widthOfAxis / 2), bottom + (height/2));
        });
    }

    private drawWeiiLabels(chart: Chart) {
        const {ctx, chartArea: {height}, scales: {x, y}} = chart;
        const ticks = y.getTicks();
        ticks.forEach((t, i) => {
            if (i > 0 && !this.weiiBoxColors[i - 1]) {
                return;
            }
            const label = TranslateDirective.getTranslation(t.label as string, true);
            const heightOfAxis = (height / (ticks.length - 1));
            const isLastRecord = i === ticks.length - 1;
            const position = this.getPositionOfTickY(chart, t, y);
            if (!isLastRecord) {
                ctx.strokeStyle = "white";
                ctx.fillStyle = "white";
                ctx.fillRect(position.x, position.y, this.leftPadding, heightOfAxis);

                ctx.fillStyle = this.weiiBoxColors[i];
                ctx.beginPath();
                ctx.roundRect(position.x + this.boxMargin, position.y + this.boxMargin,
                    this.leftPadding - (this.boxMargin * 2), heightOfAxis - (this.boxMargin * 2), this.cornerRadius);
                ctx.stroke();
                ctx.fill();
            }
            ctx.fillStyle = "white";
            ctx.textAlign = 'center';
            ctx.font = 'bold 14px "Cerebri Sans"';
            const lines = label.split(' ');

            position.y = (y.bottom - (i * heightOfAxis) + (heightOfAxis / 2) + 5) -
                ((lines.length - 1) * (this.lineHeight / 2));
            lines.forEach((l, i) =>
                ctx.fillText(l, position.x + 60, position.y + (i * this.lineHeight)));
        });
    }
}