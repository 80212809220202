import {Component, inject} from '@angular/core';
import {GetMyReports, Report, ReportScheduleResolution, TimeResolution} from "@flowmaps/flowmaps-typescriptmodels";
import {ActivatedRoute, Router} from "@angular/router";
import {cloneDeep} from "lodash";
import {Handler} from "../../../common/handler";
import {View} from "../../../common/view";

@Component({
    selector: 'app-report',
    templateUrl: './report.component.html',
    styleUrls: ['./report.component.scss']
})
@Handler()
export class ReportComponent extends View {
    reportId: string;
    report: Report;

    private router: Router = inject(Router);
    private route: ActivatedRoute = inject(ActivatedRoute);

    constructor() {
        super();
        this.route.params.subscribe(params => {
            this.reportId = params['reportId'];

            if (!this.reportId) {
                this.router.navigateByUrl("/report/default");
                return;
            }

            if (this.reportId === "default") {
                this.report = cloneDeep(ReportComponent.defaultReport);
            } else {
                this.getReports();
            }
        });
    }

    'reportChange' = () => {
        this.getReports();
    }

    private getReports() {
        this.sendQuery("com.flowmaps.api.reporting.GetMyReports", <GetMyReports>{}, {caching: false})
            .subscribe((reports: Report[]) => {
                this.report = reports.find(r => r.reportId === this.reportId);
            });
    }

    static defaultReport: Report = (<Report>{
        info: {
            name: "Default",
            query: {
                range: {
                    resolution: ReportScheduleResolution.year,
                    value: 0
                },
                resolution: TimeResolution.month
            }
        }
    });
}
