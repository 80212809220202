import {Component, Input} from '@angular/core';
import {AppContext} from "../../../../app-context";
import moment from "moment";
import {TimeRange, UserProfile} from "@flowmaps/flowmaps-typescriptmodels";
import {ChartMeasurementData} from "../../../charts/base-measurement-chart";
import {OrganisationProvider} from "../../../../utils/source-providers/organisation-provider";

@Component({
    selector: 'app-report-html-front-page',
    templateUrl: './report-html-front-page.component.html',
    styleUrls: ['./report-html-front-page.component.scss']
})
export class ReportHtmlFrontPageComponent {
    appContext = AppContext;

    @Input() timeRange: TimeRange;
    @Input() chartDataProvider: ChartMeasurementData;
    @Input() sourcesProvider: OrganisationProvider;
    @Input() owner: UserProfile;

    formatDate = (date: string) => moment(date).format("DD MMM YYYY");

    now = () => moment().format("DD MMM YYYY");
}
