import {Component, Input} from '@angular/core';
import {EntityPerformanceChartComponent, PerformanceRecord} from "./entity-performance-chart.component";
import {DataType} from "@flowmaps/flowmaps-typescriptmodels";
import {ChartDataPerMeasurement} from "../../../utils/measurements-data-provider";
import {MeasurementDataset} from "../base-measurement-chart";
import {lodash} from "../../../common/utils";
import {map, Observable} from "rxjs";
import {RefdataUtils} from "../../refdata/refdata-utils";
import {Entity, EntityType} from "../../../handlers/entity";
import {sendQuery} from "../../../common/app-common-utils";

@Component({
    selector: 'app-meter-performance-chart',
    templateUrl: './entity-performance-chart.component.html',
    styleUrls: ['./entity-performance-chart.component.scss']
})
export class MeterPerformanceChartComponent extends EntityPerformanceChartComponent<Entity> {
    @Input() selectedDataType: DataType = DataType.electricityIntermediateConsumption;

    entityType(): EntityType {
        return EntityType.meter;
    }

    possibleDataTypes = (): DataType[] =>
        [DataType.electricityIntermediateConsumption, DataType.electricityGrossProduction, DataType.electricityGrossProductionReactive]
            .concat([DataType.gasConsumption, DataType.heatConsumption, DataType.waterConsumption])
            .concat([DataType.electricityConsumptionCosts, DataType.gasConsumptionCosts, DataType.waterConsumptionCosts,
                DataType.heatConsumptionCosts])
            .concat([DataType.co2EmissionFromElectricity, DataType.co2EmissionFromGas, DataType.co2EmissionFromWater,
                DataType.co2EmissionFromHeat])

    createData(result: ChartDataPerMeasurement, measurements: MeasurementDataset[], estimatedMeasurements: MeasurementDataset[]): Observable<PerformanceRecord[]> {
        return this.getEntities().pipe(map(meters => {
            return Object.entries(lodash.groupBy((measurements || [])
                .filter(d => d.measurementType === this.selectedDataType), d => d.entityId))
                .map(e => ({
                    meter: meters.find(l => l.meter.meterId === e[0]),
                    dataset: e[1]
                }))
                .filter(e => e.meter)
                .map(e => ({
                    entityId: e.meter.meter.meterId,
                    value: lodash.sum(e.dataset.flatMap(d => d.dataset.data.map(r => r as number))),
                    estimatedValue: null,
                    label: RefdataUtils.meterFormatter(e.meter)
                }));
        }));
    }

    getAllEntities(): Observable<Entity[]> {
        return sendQuery("getMetersAsEntities");
    }
}
