import {Component, Input} from '@angular/core';
import {DateRange} from "@flowmaps/flowmaps-typescriptmodels";

@Component({
  selector: 'app-contract-range',
  templateUrl: './contract-range.component.html',
  styleUrls: ['./contract-range.component.scss']
})
export class ContractRangeComponent {
  @Input() dateRange: DateRange;
  @Input() isReadOnly: boolean = false;
  @Input() isRequired: boolean = false;
}
