import {Component, inject} from '@angular/core';
import {RequestUserReset} from '@flowmaps/flowmaps-typescriptmodels';
import {Router} from "@angular/router";
import {View} from "src/app/common/view";
import {Handler} from "src/app/common/handler";

@Component({
    selector: 'app-password-reset',
    templateUrl: './forgot-password.component.html',
    styleUrls: ['./forgot-password.component.css']
})
@Handler()
export class ForgotPasswordComponent extends View {
    private router: Router = inject(Router);

    command: RequestUserReset = {};

    sendLoginLink = () => {
        this.sendCommand('com.flowmaps.api.authentication.RequestUserReset', this.command,
            () => this.router.navigate(["/reset-password"], {queryParams: {email: this.command.email}}));
    };
}
