import {Component} from '@angular/core';
import {View} from "src/app/common/view";
import {Handler} from "src/app/common/handler";
import {AddAlert, AlertType} from "@flowmaps/flowmaps-typescriptmodels";
import {uuid} from "../../../common/utils";

@Component({
    selector: 'app-add-alert',
    templateUrl: './add-alert.component.html',
    styleUrls: ['./add-alert.component.scss']
})
@Handler()
export class AddAlertComponent extends View {
    alertTypeOptions: AlertType[] = [AlertType.MissingData, AlertType.Disconnected, AlertType.Peak, AlertType.ContractedPowerExceeded];

    command: AddAlert = {
        alertId: uuid(),
        details: {}
    }

    updateInfo = () => this.sendCommand('com.flowmaps.api.monitoring.alerting.AddAlert', this.command);
}
