import {Component, Input, OnInit} from '@angular/core';
import {View} from "src/app/common/view";
import {Handler} from "src/app/common/handler";
import {ContractEntity} from "../../../../handlers/app-entity-handler.service";
import {UpsertContract} from "@flowmaps/flowmaps-typescriptmodels";
import {uuid} from "../../../../common/utils";

@Component({
    selector: 'app-contract-details-modal',
    templateUrl: './contract-details-modal.component.html',
    styleUrls: ['./contract-details-modal.component.scss']
})
@Handler()
export class ContractDetailsModalComponent extends View implements OnInit {
    @Input() data: ContractDetailsModalComponentData;
    isNewRecord: boolean;

    ngOnInit() {
        this.isNewRecord = !this.data.contract.contract.contractId
    }

    updateInfo = () => this.sendCommand("com.flowmaps.api.organisation.UpsertContract", <UpsertContract>{
        contractId: uuid(),
        contractData: this.data.contract.contract.contractData,
        defaultContract: this.data.contract.contract.defaultContract,
        organisationId: this.data.contract.organisation.organisationId,
        organisationInfo: this.data.contract.organisation.info
    })
}

export interface ContractDetailsModalComponentData {
    contract: ContractEntity;
}