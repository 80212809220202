import {AppContext} from "../app-context";
import {
    AuthenticationResponse,
    AuthenticationResult,
    ConfirmUser,
    ImpersonateUser,
    SetUserPassword,
    SignInUser,
    SignUpUser
} from "@flowmaps/flowmaps-typescriptmodels";
import {inject, Injectable} from "@angular/core";
import {Router} from "@angular/router";
import moment from "moment";
import {StandaloneHandler} from "../common/standalone-handler";
import {AppCommonUtils, sendCommand} from "src/app/common/app-common-utils";
import {environment} from "../../environments/environment";
import {HandleEvent} from "../common/handle-event";

@Injectable()
export class AuthService extends StandaloneHandler {
    private redirectUrl: string;
    private router: Router = inject(Router);

    constructor() {
        super();
        this.startSessionTimer();
    }

    navigateToLogin() {
        if (this.router.url !== "/login") {
            this.redirectUrl = this.router.url;
            AppCommonUtils.navigateToUrl("/login");
        }
    }

    @HandleEvent("checkSession")
    checkSession() {
        this.checkSessionDeadline();
    }

    signUp(command: SignUpUser) {
        sendCommand("com.flowmaps.api.authentication.SignUpUser", command, this.responseHandler(command.info.email));
    }

    signIn(command: SignInUser) {
        sendCommand("com.flowmaps.api.authentication.SignInUser", command, this.responseHandler(command.userId));
    }

    impersonate(command: ImpersonateUser) {
        sendCommand("com.flowmaps.api.authentication.ImpersonateUser", command,
            this.responseHandler(command.userId, true));
    }

    stopImpersonating() {
        sendCommand("com.flowmaps.api.authentication.StopImpersonatingUser", {},
            this.responseHandler(null, true));
    }

    signOut() {
        AppCommonUtils.httpClient().get(environment.apiProtocol + environment.apiDomain + "/api/logout", {withCredentials : true}).subscribe();
        AppContext.setUserProfile(undefined);
        AppCommonUtils.navigateToUrl("/login");
    }


    private responseHandler = (email? : string, reloadPage = false) => (response: AuthenticationResponse) => {
        AppCommonUtils.clearCache();
        if (response.impersonator) {
            localStorage.setItem("impersonator", response.impersonator);
        } else {
            localStorage.removeItem("impersonator");
        }
        if (response.authorizationHeader) {
            localStorage.setItem("Authorization", response.authorizationHeader);
        }
        if (response.sessionDeadline) {
            localStorage.setItem("SessionDeadline", response.sessionDeadline);
            this.startSessionTimer();
        }
        if (response.result == AuthenticationResult.SUCCESS) {
            AppContext.setUserProfile(response.userProfile);
            AppCommonUtils.navigateToUrl(this.redirectUrl || "", reloadPage);
            this.redirectUrl = null;
        }
        if (response.result == AuthenticationResult.NOT_CONFIRMED) {
            AppContext.setUserProfile(response.userProfile);
            AppCommonUtils.navigateToUrl((this.redirectUrl || "").startsWith("/confirm")
                ? this.redirectUrl : "/confirm?userId=" + encodeURIComponent(email), reloadPage);
        }
    };

    confirm(command: ConfirmUser) {
        sendCommand('com.flowmaps.api.authentication.ConfirmUser', command, () => {
            AppCommonUtils.navigateToUrl("/");
            AppCommonUtils.registerSuccess("You've successfully confirmed your e-mail address");
        })
    }

    resetPassword(command: SetUserPassword) {
        sendCommand('com.flowmaps.api.authentication.SetUserPassword', command, this.responseHandler(command.userId))
    }

    startSessionTimer = () => {
        const sessionDeadline = this.checkSessionDeadline();
        if (sessionDeadline) {
            setTimeout(() => this.checkSessionDeadline, moment(sessionDeadline).diff(moment()));
        }
    }

    private checkSessionDeadline = () : string => {
        const sessionDeadline = localStorage.getItem("SessionDeadline");
        if (sessionDeadline && moment(sessionDeadline).isBefore(moment())) {
            localStorage.removeItem("SessionDeadline");
            this.navigateToLogin();
            return null;
        }
        return sessionDeadline;
    }
}
