import {FacetStats} from "./facet-filter/facet-filter.component";
import {FacetFilter, FacetResult} from "@flowmaps/flowmaps-typescriptmodels";

export class FacetUtils {

  static isBooleanFacet(facet: FacetStats): boolean {
    return facet.values.every(v => ["false", "true"].includes(v.value))
  }

  static hasActiveFilters = (filters: FacetFilter[]): boolean => !!filters?.length;

  static addZeroFacetResults = (result: FacetResult, facetName: string, ...values: string[]): FacetResult => {
    let statusFacets = result.facets[facetName];
    if (!statusFacets) {
      statusFacets = result.facets[facetName] = [];
    }
    values.forEach(value => {
      if (!statusFacets.some(f => f.value === value)) {
        statusFacets.push({value: value, count: 0});
      }
    });
    return result;
  }
}
