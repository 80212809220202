import {Component, Input} from '@angular/core';
import {Connection, UpsertSolarEdgeMeter} from "@flowmaps/flowmaps-typescriptmodels";
import {Handler} from "../../../../common/handler";
import {View} from "../../../../common/view";

@Component({
    selector: 'app-upsert-solar-edge-meter',
    templateUrl: './upsert-solar-edge-meter.component.html',
    styleUrls: ['./upsert-solar-edge-meter.component.scss']
})
@Handler()
export class UpsertSolarEdgeMeterComponent extends View {
    @Input() data: UpsertSolarEdgeMeterComponentData;
    kioskUrl: string;
    siteId: string;
    apiKey: string;

    updateInfo = () => this.sendCommand("com.flowmaps.api.organisation.UpsertSolarEdgeMeter", <UpsertSolarEdgeMeter>{
        ean: this.data.connection.info.code,
        kioskUrl: this.kioskUrl,
        siteId: this.siteId,
        apiKey: this.apiKey
    });
}

export interface UpsertSolarEdgeMeterComponentData {
    connection: Connection;
}