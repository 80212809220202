import {ChangeDetectorRef, Component, EventEmitter, Input, Output} from '@angular/core';
import {SourcesProvider, TreeViewItem} from "../../../utils/source-providers/sources-provider";
import {OrganisationProvider} from "../../../utils/source-providers/organisation-provider";
import {DashboardContext} from "../../dashboard/dashboard.context";

@Component({
    selector: 'app-dashboard-sources-list',
    templateUrl: './dashboard-sources-list.component.html',
    styleUrls: ['./dashboard-sources-list.component.scss'],
})
export class DashboardSourcesListComponent {
    context = DashboardContext;

    @Input() data: TreeViewItem[];

    @Input() depth: number = 0;
    @Input() recordCls: string = "";
    @Input() filterTerm: string;

    @Input() dataProvider: OrganisationProvider;

    @Output() selectionChange: EventEmitter<TreeViewItem> = new EventEmitter<TreeViewItem>();

    constructor(private changeDetector: ChangeDetectorRef) {
    }

    toggleExpand(source: TreeViewItem) {
        source.expanded = !source.expanded;
        if (!source.expanded) {
            collapseItems(source);
        }

        function collapseItems(parent: TreeViewItem) {
            if (parent.subItems) {
                parent.subItems.forEach(s => {
                    s.expanded = false;
                    collapseItems(s);
                })
            }
        }
        this.changeDetector.detectChanges();
    }

    toggleSource(source: TreeViewItem) {
        const selectedParent = this.dataProvider.getSelectedParent(source);
        if (selectedParent) {
            this.toggleSourceParent(source);
            this.dataProvider.selectSource(source, false);
            this.dataProvider.selectionUpdated.emit(this.dataProvider.getSelection());
            this.selectionChange.emit(source);
            return;
        }
        if (this.dataProvider.isSelected(source) && !selectedParent) {
            SourcesProvider.flatMapSubItems(source)
                .filter(s => s !== source)
                .forEach(s => this.dataProvider.selectSource(s, false));
        }
        this.dataProvider.selectSource(source, !this.dataProvider.isSelected(source));
        this.dataProvider.selectionUpdated.emit(this.dataProvider.getSelection());
        this.selectionChange.emit(source);
    }

    toggleSourceParent(source: TreeViewItem) {
        const selectedParent = this.dataProvider.getSelectedParent(source);
        if (selectedParent) {
            this.dataProvider.selectSource(selectedParent, false);
            selectedParent.subItems.filter(s => s !== source)
                .forEach(s => this.dataProvider.selectSource(s, true));
            this.toggleSourceParent(source);
        }
        return false;
    }

    sourceSelectionType(source: TreeViewItem): 'all' | 'partially' | 'none' {
        if (this.dataProvider.isSelected(source)) {
            return 'all';
        }
        if (this.isUnselected(source)) {
            return 'none';
        }
        return 'partially';
    }

    isUnselected(source: TreeViewItem): boolean {
        return SourcesProvider.flatMapSubItems(source).every(s => !this.dataProvider.isDirectlySelectedBy(s.info));
    }

    trackByTreeViewItemId = (index: number, treeViewItem: TreeViewItem) => treeViewItem.info.id;

    visibleSubItems = (parent: TreeViewItem) => parent.subItems?.filter(i => i.visible) || [];
}