import {AfterViewInit, Component} from '@angular/core';
import {Handler} from "src/app/common/handler";
import {AppContext} from "../../../../app-context";
import {UserProfile} from "@flowmaps/flowmaps-typescriptmodels";
import {Observable} from "rxjs";
import {View} from "../../../../common/view";
import {UserDetailsComponent} from "../user-details/user-details.component";

@Component({
  selector: 'app-users-overview',
  templateUrl: './users-overview.component.html',
  styleUrls: ['./users-overview.component.scss']
})
@Handler()
export class UsersOverviewComponent extends View implements AfterViewInit {
    appContext = AppContext;

    term: string;
    query: Observable<UserProfile[]>;
    data: UserProfile[] = [];

    ngAfterViewInit() {
        this.executeQuery();
    }

    executeQuery = () => {
        this.query = this.subscribeTo("searchUsers", this.term);
    }

    trackByForRecord = (index: number, record: UserProfile) => record.userId;

    createUser = () => this.openModal(UserDetailsComponent, {
        intermediaryId: AppContext.userProfile.intermediaryId,
        info: {
            language: AppContext.userProfile.info.language,
        },
        receivedAuthorisations: []
    });
}
