import {
    ArrayTemplate,
    downloadWorkbook,
    DynamicField,
    exportExcelFromWorkBook,
    getRecordRowCountForSheet,
    QuantityField,
    RequiredField,
    SheetTemplate,
    WorkBookTemplate,
    writeExcelFromWorkBook
} from "../../../../common/upload/excel.utils";
import {SourcesProvider} from "../../../../utils/source-providers/sources-provider";
import {
    DataType,
    GetMeasurementsByConnection,
    MeasurementsResult,
    TimeRange,
    TimeResolution
} from "@flowmaps/flowmaps-typescriptmodels";
import {RefdataUtils} from "../../refdata-utils";
import lodash, {cloneDeep} from "lodash";
import {MeasurementsDataProvider, Slot} from "../../../../utils/measurements-data-provider";
import {DashboardContext} from "../../../dashboard/dashboard.context";
import {AppContext} from "../../../../app-context";
import {mergeMap, tap} from "rxjs/operators";
import * as XLSX from "xlsx-js-style";
import moment from "moment";
import {removeItem} from "../../../../common/utils";
import {combineLatest, Observable} from 'rxjs';
import {sendQuery} from "../../../../common/app-common-utils";
import {Entity, EntityType} from "../../../../handlers/entity";
import {MeterViewEntity} from "../../../../handlers/meter-views-standalone-handler";

export function downloadBulkMonthData(sourceProvider: SourcesProvider<any>, timeRange: TimeRange) {
    const info = prepareDownload(timeRange);
    combineLatest([sendQuery("getConnectionsAsEntities"), sendQuery("getPrimaryMeterViews"), getMeasurements(timeRange, sourceProvider)])
        .subscribe(([entities, meterViews, data]) => {
            const records = {
                data: getConnectionData(timeRange, data, entities, meterViews, sourceProvider, info.slots, info.yearSlots, info.dynamicColumns)
            };
            const rowCount = getRecordRowCountForSheet(info.template.sheets[0].template, records);
            exportExcelFromWorkBook(downloadWorkbook("/assets/templates/bulk-data-download.xlsx")
                    .pipe(tap(wb => addDynamicColumns(wb.Sheets["Data"], info.template.sheets.find(
                        s => s.name === "Data"), info.dynamicColumns, rowCount))),
                info.template, records, "bulk-data-export.xlsx");
        });
}

export function writeBulkMonthDataAsExcel(sourceProvider: SourcesProvider<any>, timeRange: TimeRange): Observable<any> {
    const info = prepareDownload(timeRange);
    return combineLatest([sendQuery("getConnectionsAsEntities"), sendQuery("getPrimaryMeterViews"), getMeasurements(timeRange, sourceProvider)])
        .pipe(mergeMap(([connections, meterViews, data]) => {
            const records = {
                data: getConnectionData(timeRange, data, connections, meterViews, sourceProvider, info.slots, info.yearSlots, info.dynamicColumns)
            };
            const rowCount = getRecordRowCountForSheet(info.template.sheets[0].template, records);
            return writeExcelFromWorkBook(downloadWorkbook("/assets/templates/bulk-data-download.xlsx")
                    .pipe(tap(wb => addDynamicColumns(wb.Sheets["Data"], info.template.sheets.find(
                        s => s.name === "Data"), info.dynamicColumns, rowCount))),
                info.template, records, {
                    bookType: "xlsx",
                    type: "base64"
                });
        }));
}

function prepareDownload(timeRange: TimeRange): BulkDownloadInfo {
    const slots = MeasurementsDataProvider.getSlots(timeRange, TimeResolution.month);
    const endDate = moment(timeRange.end);
    const yearSlots = MeasurementsDataProvider.getSlots({
        start: moment(timeRange.start).startOf("year").toISOString(),
        end: endDate.clone().startOf('year').isSame(endDate) ? endDate.clone().toISOString()
            : endDate.clone().add(1, 'year').startOf("year").toISOString()
    }, TimeResolution.year);
    const template = cloneDeep(bulkDataDownloadTemplate);
    return {
        slots: slots,
        yearSlots: yearSlots,
        dynamicColumns: getDynamicColumns(template, slots, yearSlots),
        template: template
    };
}

function getMeasurements(timeRange: TimeRange, sourceProvider: SourcesProvider<any>): Observable<MeasurementsResult[]> {
    return sendQuery("com.flowmaps.api.measurements.GetMeasurementsByConnection", <GetMeasurementsByConnection>{
        resolution: TimeResolution.month,
        timeRange: AppContext.timeRangeToQuery(timeRange),
        sources: sourceProvider.sourceSelectionAfterCleanup(),
        unrounded: true
    }, {caching: false, showSpinner: true});
}

function getConnectionData(timeRange: TimeRange, data: MeasurementsResult[], entities: Entity[], meterViews: MeterViewEntity[],
                           sourceProvider: SourcesProvider<any>, slots: Slot[], yearSlots: Slot[], dynamicColumns: DynamicColumn[]) {
    const allConnections = sourceProvider.getAllSourcesByType(EntityType.connection).map(s => s.source.connection);
    const connectionIdsWithoutData = allConnections.map(c => c.connectionId);
    const connectionsWithData = data.flatMap(m => {
        const entity = entities.find(c => c.connection.connectionId === m.entityId);
        if (entity) {
            removeItem(connectionIdsWithoutData, entity.connection.connectionId);
            return Object.entries(m.measurements)
                .filter(e => allowedMeasurementTypes.includes(e[0] as DataType))
                .map(e => {
                    const data = {
                        organisation: RefdataUtils.organisationInfoFormatter(entity.organisation.info),
                        location: RefdataUtils.locationInfoFormatter(entity.location.info),
                        buildingCode: entity.location.info.buildingCode,
                        ean: entity.connection.info.code,
                        marketSegment: entity.connection.info.marketSegment,
                        connectionType: entity.connection.info.connectionType,
                        measurementType: AppContext.entityPerformanceMeasurementName(e[0] as DataType),
                        measurementUnit: DashboardContext.getMeasurementUnit(e[0] as DataType, false),
                        completeness: AppContext.computeCompleteness(meterViews.filter(
                                v => v.meterView.connectionId === entity.connection.connectionId), timeRange, [])
                    };
                    const slotsWithData = MeasurementsDataProvider.appendAggregatedDataToSlots(e[1], slots)
                        .concat(MeasurementsDataProvider.appendAggregatedDataToSlots(e[1], yearSlots));
                    dynamicColumns.forEach(d => {
                        const slot = slotsWithData.find(s => s.label === d.fieldName);
                        if (slot) {
                            data[d.fieldName] = slot.values.length > 0 ? lodash.sum(slot.values) : null;
                        }
                    });
                    return data;
                });
        }
        return [];
    });
    const connectionsWithoutData = connectionIdsWithoutData.map(c => {
        const entity = entities.find(con => con.connection.connectionId === c);
        return entity ? <any>{
            organisation: RefdataUtils.organisationInfoFormatter(entity.organisation.info),
            location: RefdataUtils.locationInfoFormatter(entity.location.info),
            buildingCode: entity.location.info.buildingCode,
            ean: entity.connection.info.code,
            marketSegment: entity.connection.info.marketSegment,
            connectionType: entity.connection.info.connectionType
        } : null;
    });
    return lodash.sortBy(connectionsWithData.concat(connectionsWithoutData).filter(e => e),
        ["organisation", "location", "ean", "measurementType"]);
}

function addDynamicColumns(sheet: XLSX.WorkSheet, template: SheetTemplate, dynamicColumns: DynamicColumn[], rowCount: number) {
    dynamicColumns.forEach(d => template.template.data[d.fieldName] = new DynamicField(d.columnIndex, d.header));
    template.template.data = new ArrayTemplate(template.template.data, [2, rowCount]);
    Object.values(template.template.data.pattern).filter(e => e["headerName"])
        .forEach((e: DynamicField) => sheet[e.field.replace("$", "1")] = <any>{v: e.headerName, t: "s"});
}

const allowedMeasurementTypes: DataType[] = [DataType.electricityConsumption, DataType.electricityConsumptionOffPeak,
    DataType.electricityFeedIn, DataType.electricityFeedInOffPeak, DataType.gasConsumption, DataType.waterConsumption,
    DataType.heatConsumption];

function getDynamicColumns(template: WorkBookTemplate, monthSlots: Slot[], yearSlots: Slot[]) {
    const highestColumnField = (Object.values(template.sheets[0].template.data) as RequiredField[]).reduce(
        (a, b) =>
            alphabetPosition(a.field.replace(/[^A-Z]/g, '')) > alphabetPosition(b.field.replace(/[^A-Z]/g, ''))
                ? a : b);
    const columns: DynamicColumn[] = [];
    let currentHighestColumnName = highestColumnField.field.replace(/[^A-Z]/g, '');
    monthSlots.forEach(s => {
        currentHighestColumnName = getNextExcelColumnName(currentHighestColumnName);
        columns.push({
            fieldName: s.label,
            columnIndex: currentHighestColumnName + "$",
            header: s.label,
            slot: s
        });
    });
    yearSlots.forEach(s => {
        currentHighestColumnName = getNextExcelColumnName(currentHighestColumnName);
        columns.push({
            fieldName: s.label,
            columnIndex: currentHighestColumnName + "$",
            header: "Total " + s.label,
            slot: s
        });
    })
    return columns;
}

function alphabetPosition(text: string) {
    let result = "";
    for (let i = 0; i < text.length; i++) {
        const code = text.toUpperCase().charCodeAt(0)
        if (code > 64 && code < 91) result += (code - 64) + " ";
    }
    return result.slice(0, result.length - 1);
}

function getNextExcelColumnName(columnName: string) {
    let result = columnName.split('');
    for (let i = result.length - 1; i >= 0; i--) {
        if (result[i] === 'Z') {
            result[i] = 'A';
            if (i === 0) {
                result.unshift('A');
                break;
            }
        } else {
            result[i] = String.fromCharCode(result[i].charCodeAt(0) + 1);
            break;
        }
    }
    return result.join('');
}

const bulkDataDownloadTemplate: WorkBookTemplate = {
    sheets: [
        {
            name: 'Data',
            template: {
                data: {
                    organisation: new RequiredField('A$'),
                    location: new RequiredField('B$'),
                    buildingCode: new RequiredField('C$'),
                    ean: new RequiredField('D$'),
                    marketSegment: new RequiredField('E$'),
                    connectionType: new RequiredField('F$'),
                    measurementType: new RequiredField('G$'),
                    measurementUnit: new RequiredField("H$"),
                    completeness: new QuantityField("I$", 0, "0%"),
                    // Data columns are added when creating data
                }
            }
        }
    ]
};

interface DynamicColumn {
    fieldName: string;
    columnIndex: string;
    header: string;
    slot: Slot;
}

interface BulkDownloadInfo {
    slots: Slot[];
    yearSlots: Slot[];
    dynamicColumns: DynamicColumn[];
    template: WorkBookTemplate;
}