import {Component, Input, OnInit} from '@angular/core';
import {View} from "src/app/common/view";
import {Handler} from "src/app/common/handler";
import {RefdataUtils} from "../../refdata-utils";
import {AppContext} from "../../../../app-context";
import {
  Authorisation,
  AuthorisationType,
  ManagerSignUpUser,
  Organisation,
  TimeRange,
  UpdateUserInfo,
  UserInfo,
  UserProfile
} from "@flowmaps/flowmaps-typescriptmodels";
import {SourceInfo, SourcesProvider} from "../../../../utils/source-providers/sources-provider";
import {OrganisationProvider} from "../../../../utils/source-providers/organisation-provider";
import moment from "moment";

@Component({
  selector: 'app-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.scss']
})
@Handler()
export class UserDetailsComponent extends View implements OnInit {
  appContext = AppContext;
  refDataUtils = RefdataUtils;

  @Input() data: UserProfile;
  isNewRecord: boolean;
  createDefaultReport: boolean = true;
  authorisation: Authorisation = {
    type: AuthorisationType.user,
    timeRange: {}
  }

  organisation: Organisation;
  sourceProvider: SourcesProvider<any>;

  getMinStartDate = (): string => this.authorisation.timeRange?.start ? this.authorisation.timeRange?.start : AppContext.today();

  get info(): UserInfo {
    return this.data.info;
  }

  ngOnInit(): void {
    this.isNewRecord = !this.data.userId;
  }

  searchOrganisations = (term: string) => this.sendQuery("searchOrganisations", term);

  setOrganisation = (organisation: Organisation) => {
    this.organisation = organisation;
    if (organisation) {
      this.sourceProvider = new OrganisationProvider(organisation.organisationId, []);
    }
  }

  updateInfo = () => {
    if (this.isNewRecord) {
      if (this.organisation) {
        this.authorisation.nominee = this.data.info.email;
        this.authorisation.nominator = this.organisation.organisationId;
      }
      if (this.authorisation.timeRange) {
        this.authorisation.timeRange = asTimeRange(this.authorisation.timeRange);
      }
      this.sendCommand("com.flowmaps.api.authentication.ManagerSignUpUser", <ManagerSignUpUser>{
        info: this.data.info,
        createDefaultReport: this.createDefaultReport,
        authorisation: this.authorisation.nominator ? this.authorisation : null
      });
    } else {
      this.sendCommand("com.flowmaps.api.user.UpdateUserInfo", <UpdateUserInfo> {
        userId: this.data.userId,
        info: this.data.info
      });
    }

    function asTimeRange(dateRange: TimeRange): TimeRange {
      if (dateRange?.start || dateRange?.end) {
        return {
          start: dateRange.start && moment(dateRange.start).startOf('day').toISOString(),
          end: dateRange.end && moment(dateRange.end).endOf('day').toISOString(),
        }
      }
      return null;
    }
  };

  updateEntityIds(sources: SourceInfo[]) {
    const sourceIds = this.sourceProvider.sourceSelectionAfterCleanup();
    this.authorisation.entityIds = sourceIds.locationIds.concat(sourceIds.connectionIds).concat(sourceIds.meterIds);
  }
}
