import {Component, Input} from '@angular/core';
import moment from "moment";
import {Handler} from "../../../common/handler";
import {View} from "../../../common/view";
import {
  Error,
  ErrorStatus,
  MuteError,
  ReopenError,
  ResolveError,
  UnmuteError
} from "@flowmaps/flowmaps-typescriptmodels";

@Component({
  selector: 'app-issue-actions-dropdown',
  templateUrl: './issue-actions-dropdown.component.html',
  styleUrls: ['./issue-actions-dropdown.component.scss']
})
@Handler()
export class IssueActionsDropdownComponent extends View {
  @Input() issue: Error;
  muteDate: string;

  resolveIssue = () => {
    this.sendCommand("com.flowmaps.api.monitoring.errors.ResolveError", <ResolveError>{
      errorId: this.issue.errorId
    }, () => {
      this.issue.status = ErrorStatus.RESOLVED;
      this.sendCommandAndForget("reloadIssues");
    });
  }

  unmuteIssue = () => {
    this.sendCommand("com.flowmaps.api.monitoring.errors.UnmuteError", <UnmuteError>{
      errorId: this.issue.errorId
    }, () => {
      this.issue.status = ErrorStatus.OPEN;
      this.issue.muteDeadline = null;
      this.sendCommandAndForget("reloadIssues");
    });
  }

  muteIssue() {
    this.sendCommand("com.flowmaps.api.monitoring.errors.MuteError", <MuteError>{
      errorId: this.issue.errorId,
      deadline: this.muteDate
    }, () => {
      this.issue.status = ErrorStatus.MUTED;
      this.issue.muteDeadline = this.muteDate;
      this.muteDate = null;
      this.sendCommandAndForget("reloadIssues");
    });
  }

  reopenIssue = () => {
    this.sendCommand("com.flowmaps.api.monitoring.errors.ReopenError", <ReopenError>{
      errorId: this.issue.errorId
    }, () => {
      this.issue.status = ErrorStatus.OPEN;
      this.sendCommandAndForget("reloadIssues");
    });
  }

  setMuteDeadline(duration: string) {
    switch (duration) {
      case '1d':
        this.muteDate = moment().add(1, 'day').toISOString();
        break;
      case '1w':
        this.muteDate = moment().add(1, 'week').toISOString();
        break;
      case '1m':
        this.muteDate = moment().add(1, 'month').toISOString();
        break;
      case 'indefinite':
        this.muteDate = null;
        break;
    }
    this.muteIssue();
  }
}
