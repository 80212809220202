import {Injectable} from "@angular/core";
import {map, mergeMap, Observable, of} from "rxjs";
import {
    Alert,
    AlertChannel,
    AlertStatus,
    Error,
    FindAlertChannels,
    GetErrors,
    GetMyAlertsNew
} from "@flowmaps/flowmaps-typescriptmodels";
import {StandaloneHandler} from "../common/standalone-handler";
import {HandleQuery} from "../common/handle-query";
import {sendQuery, subscribeTo} from "../common/app-common-utils";
import {AppContext} from "../app-context";
import {Entity} from "./entity";
import lodash from "lodash";

@Injectable({
    providedIn: "root"
})
export class IssuesStandaloneHandler extends StandaloneHandler {

    @HandleQuery("getErrors")
    getErrors(query?: GetErrors): Observable<Error[]> {
        return AppContext.isAdmin() ? subscribeTo('com.flowmaps.api.monitoring.errors.GetErrorsNew', query, {
            caching: false
        }) : of(null);
    }

    @HandleQuery("getOpenErrors")
    getOpenErrors(): Observable<Error[]> {
        return this.getErrors({
            facetFilters: [{
                "facetName": "status",
                "values": ["OPEN"]
            }]
        });
    }

    @HandleQuery("getAlerts")
    getAlerts(query?: GetMyAlertsNew): Observable<AlertEntity[]> {
        return subscribeTo('com.flowmaps.api.monitoring.alerting.GetMyAlertsNew', query)
            .pipe(mergeMap((a: Alert[]) => sendQuery("getEntitiesById", a.map(a => a.details.entityIds[0]))
                .pipe(map((e: Entity[]) => this.mapAlertsAndEntities(a, e)))));
    }

    @HandleQuery("getOpenAlerts")
    getOpenAlerts(): Observable<Alert[]> {
        return subscribeTo('com.flowmaps.api.monitoring.alerting.GetMyAlertsNew', <GetMyAlertsNew>{
            facetFilters: [{
                facetName: "alertStatus",
                values: ["OPEN"]
            }]
        });
    }

    @HandleQuery("findAlertChannels")
    findAlertChannels(): Observable<AlertChannel[]> {
        return subscribeTo("com.flowmaps.api.monitoring.errors.FindAlertChannels", <FindAlertChannels>{});
    }

    private mapAlertsAndEntities(alerts: Alert[], entities: Entity[]): AlertEntity[] {
        const byEntityId = lodash.keyBy(entities, e => e.getEntityId());
        return alerts.map(a => ({
            alert: a,
            alertStatusPriority: this.alertStatusPriority(a.alertStatus),
            entity: byEntityId[a.details.entityIds[0]]
        }));
    }

    private alertStatusPriority = (alertStatus: AlertStatus) => {
        switch (alertStatus) {
            case AlertStatus.OPEN: return 1;
            case AlertStatus.MUTED: return 2;
            case AlertStatus.RESOLVED: return 3;
        }
    }
}

export interface AlertEntity {
    alert: Alert;
    alertStatusPriority: number;
    entity: Entity;
}