import {Component, inject, OnInit} from '@angular/core';
import {View} from "src/app/common/view";
import {Handler} from "src/app/common/handler";
import {Organisation, UpdateOrganisationInfo} from "@flowmaps/flowmaps-typescriptmodels";
import {AppContext} from "../../../../app-context";
import {RouteData} from 'src/app/app-routing.module';
import {ActivatedRoute} from "@angular/router";

@Component({
    selector: 'app-organisation-details',
    templateUrl: './organisation-details.component.html',
    styleUrls: ['./organisation-details.component.scss']
})
@Handler()
export class OrganisationDetailsComponent extends View implements OnInit {
    private route: ActivatedRoute = inject(ActivatedRoute);
    appContext = AppContext;
    organisation: Organisation;
    routeData: RouteData;

    constructor() {
        super();
        this.routeData = this.route.snapshot.data as RouteData;
    }

    ngOnInit() {
        this.subscribeTo("getOrganisation").subscribe(o => this.organisation = o);
    }

    updateInfo = () => this.sendCommand("com.flowmaps.api.organisation.UpdateOrganisationInfo", <UpdateOrganisationInfo>{
        organisationId: this.organisation.organisationId,
        info: this.organisation.info
    });
}
