import {ChartDataPerMeasurement, MeasurementsDataProvider} from "./measurements-data-provider";
import {
    DayOfWeekMeasurementsResult,
    GetDayOfWeekMeasurements,
    Organisation,
    TimeRange
} from "@flowmaps/flowmaps-typescriptmodels";
import {Observable, of} from "rxjs";
import {DashboardContext} from "../views/dashboard/dashboard.context";
import {CompletenessInfo} from "../app-context";
import moment from "moment";
import {lodash} from "../common/utils";
import {sendQuery} from "../common/app-common-utils";

export class DayOfWeekMeasurementsDataProvider extends MeasurementsDataProvider<DayOfWeekMeasurementsResult> {

    hasData: () => boolean = () => !lodash.isEmpty(this.dashboardData?.currentData.byDayOfWeek);

    getDataForRange(timeRange: TimeRange): Observable<DayOfWeekMeasurementsResult> {
        return moment(timeRange.end).diff(moment(timeRange.start), "day") < 367 ? sendQuery("com.flowmaps.api.measurements.GetDayOfWeekMeasurements", <GetDayOfWeekMeasurements>{
            timeRange: timeRange,
            sources: this.sourceProvider.sourceSelectionAfterCleanup()
        }) : of({measurements: {}});
    }

    createChartData(data: DayOfWeekMeasurementsResult, completeness: CompletenessInfo[], organisations: Organisation[], dateRange: TimeRange, stack: string = DashboardContext.stacks.currentPeriod, comparedYear?: number): ChartDataPerMeasurement {
        return {
            byDayOfWeek: data
        }
    }

    copy(): DayOfWeekMeasurementsDataProvider {
        return new DayOfWeekMeasurementsDataProvider(this.chartUtils, this.info, this.selectedSources)
    }
}