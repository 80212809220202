import {Component, inject} from '@angular/core';
import {View} from "src/app/common/view";
import {Handler} from "src/app/common/handler";
import {ActivatedRoute} from '@angular/router';
import {HandleQuery} from "../../../../common/handle-query";
import {map, mergeMap, Observable} from "rxjs";
import {Connection, Location, Organisation} from "@flowmaps/flowmaps-typescriptmodels";
import {Entity} from "../../../../handlers/entity";

@Component({
  selector: 'app-organisation-routing',
  templateUrl: './organisation-routing.component.html',
  styleUrls: ['./organisation-routing.component.scss']
})
@Handler()
export class OrganisationRoutingComponent extends View {
  private route = inject(ActivatedRoute);
  organisationId: string;

  constructor() {
    super();
    this.route.params.subscribe(params => this.organisationId = params["organisationId"]);
  }

  @HandleQuery("getOrganisation")
  getOrganisation(): Observable<Organisation> {
    return this.subscribeTo("getOrganisations").pipe(map((organisations: Organisation[]) =>
        organisations.find(o => o.organisationId === this.organisationId)));
  }

  @HandleQuery("getLocations")
  getLocations(): Observable<Location[]> {
    return this.subscribeTo("getOrganisation").pipe(map((o: Organisation) => o.locations));
  }

  @HandleQuery("getLocationsAsEntities")
  getLocationsAsEntities(): Observable<Entity[]> {
    return this.subscribeTo("getLocations")
        .pipe(map((l: Location[]) => l.map(l => l.locationId)))
        .pipe(mergeMap(ids => this.sendQuery("getEntitiesById", ids)));
  }

  @HandleQuery("getConnections")
  getConnections(): Observable<Connection[]> {
    return this.subscribeTo("getLocations")
        .pipe(map((l: Location[]) => l.flatMap(l => l.connections)));
  }

  @HandleQuery("getConnectionsAsEntities")
  getConnectionsAsEntities(): Observable<Entity[]> {
    return this.subscribeTo("getConnections")
        .pipe(map((l: Connection[]) => l.map(l => l.connectionId)))
        .pipe(mergeMap(ids => this.sendQuery("getEntitiesById", ids)));
  }

}
