import {forkJoin, mergeMap, Observable} from "rxjs";
import {
    ConnectionInfo,
    ConnectionType,
    MeterType,
    OffPeakPeriod,
    ServiceProviderType,
    UpsertConnection,
    UpsertConnections
} from "@flowmaps/flowmaps-typescriptmodels";
import {
    ArrayField,
    ArrayTemplate,
    Cell,
    ColumnTemplate,
    DateField,
    downloadWorkbook,
    exportExcelFromWorkBook,
    getWorkbook,
    MappedField,
    parseExcel,
    RequiredField,
    WorkBookTemplate,
    writeExcelFromWorkBook
} from "../../../../common/upload/excel.utils";
import {map, tap} from "rxjs/operators";
import * as XLSX from "xlsx-js-style";
import lodash from "lodash";
import moment from "moment";
import {AppContext} from "../../../../app-context";
import {uuid} from '../../../../common/utils';
import {ComparatorChain} from '../../../../common/comparator-chain';
import {AppCommonUtils, sendQuery} from "../../../../common/app-common-utils";
import {Entity} from "../../../../handlers/entity";

const locationComparator: ComparatorChain = new ComparatorChain(
    'organisationInfo.name', 'locationInfo.name', 'locationInfo.department');

const connectionComparator: ComparatorChain = new ComparatorChain(
    'organisationInfo.name', 'locationInfo.name', 'connection.info.code');

export function downloadConnectionsAsExcel(connections: Entity[]) {
    forkJoin(sendQuery("getServiceProviders", ServiceProviderType.operator), sendQuery("getServiceProviders", ServiceProviderType.surveyor),
        sendQuery("getServiceProviders", ServiceProviderType.supplier), sendQuery("getConnectionValues")).subscribe(results => {
        const data = prepareData(connections, results);
        const authorisedHeader = connectionInfoTemplate.authorised.substring(0, connectionInfoTemplate.authorised.length - 1) + "1";
        return exportExcelFromWorkBook(downloadWorkbook("/assets/templates/connections-meters-template.xlsx")
                .pipe(tap(wb =>
                    XLSX.utils.sheet_add_aoa(wb.Sheets['Connections'], [['Authorised']], {origin: authorisedHeader}))),
            connectionsTemplate, data, "connections-export.xlsx");
    });
}

export function writeConnectionsAsExcel(connections: Entity[]): Observable<any> {
    return forkJoin(sendQuery("getServiceProviders", ServiceProviderType.operator), sendQuery("getServiceProviders", ServiceProviderType.surveyor),
        sendQuery("getServiceProviders", ServiceProviderType.supplier), sendQuery("getConnectionValues"))
        .pipe(mergeMap(results => {
            const data = prepareData(connections, results);
            const authorisedHeader = connectionInfoTemplate.authorised.substring(0, connectionInfoTemplate.authorised.length - 1) + "1";
            const connectionsWorkbook = downloadWorkbook("/assets/templates/connections-meters-template.xlsx")
                .pipe(tap(wb =>
                    XLSX.utils.sheet_add_aoa(wb.Sheets['Connections'], [['Authorised']], {origin: authorisedHeader})));
            return writeExcelFromWorkBook(connectionsWorkbook,
                connectionsTemplate, data, {
                    bookType: "xlsx",
                    type: "base64"
                });
        }));
}

export function uploadConnectionsExcel(file: File): Observable<UpsertConnections> {
    return forkJoin([sendQuery("getServiceProviders", ServiceProviderType.operator), sendQuery("getServiceProviders", ServiceProviderType.surveyor),
        sendQuery("getServiceProviders", ServiceProviderType.supplier), sendQuery("getConnectionValues"), sendQuery("getLocations")])
        .pipe(mergeMap(refData => {
            const operators = refData[0];
            const surveyors = refData[1];
            const suppliers = refData[2];
            const connectionValues = refData[3];
            const locationsMap = lodash.keyBy(refData[4], l => l.locationId);
            return getWorkbook(file)
                .pipe(mergeMap(workbook => {
                    return parseExcel(file, {
                        sheets: [{
                            name: "Connections",
                            template: {
                                connections: new ArrayTemplate(connectionInfoTemplate, [2, 20000])
                            }
                        }]
                    })
                }))
                .pipe(mergeMap(data => {
                    return sendQuery("getConnectionsAsEntities").pipe(map(connections => {
                        const connectionsMap = lodash.keyBy(connections, c => c.connection.connectionId);
                        const uploadData = data.connections.map(u => {
                            const connection: any = {
                                connectionId: u.connectionId,
                                locationId: u.locationId,
                                info: {
                                    code: lodash.toString(u.ean)?.trim(),
                                    connectionType: u.connectionType
                                }
                            };
                            if (u.label) {
                                connection.info.label = u.label.trim();
                            }
                            if (u.desiredStartDate) {
                                connection.info.desiredStartDate = moment(u.desiredStartDate).utc(false).format(AppContext.defaultIsoFormat);
                            }
                            if (u.desiredEndDate) {
                                connection.info.desiredEndDate = moment(u.desiredEndDate).add(1, 'day')
                                    .utc(false).format(AppContext.defaultIsoFormat);
                            }
                            if (u.marketSegment) {
                                connection.info.marketSegment = u.marketSegment;
                            }
                            if (u.profileCode) {
                                connection.info.profileCode = u.profileCode;
                            }
                            if (u.supplierContract) {
                                connection.info.supplierContract = u.supplierContract;
                            }
                            if (connection.info.marketSegment === "GV" && u.contractedCapacity) {
                                connection.info.contractedCapacity = u.contractedCapacity;
                            }
                            if (connection.info.marketSegment === "KV" && u.connectionValue) {
                                const connectionValue = connectionValues.find(cv => (cv.name === u.connectionValue
                                    || cv.connectionValueId === u.connectionValue) && cv.connectionType === connection.info.connectionType);
                                if (connectionValue) {
                                    connection.info.connectionValue = connectionValue;
                                } else {
                                    AppCommonUtils.registerError(`Invalid connection value: ${u.connectionValue} for connection ${u.connectionId}`);
                                }
                            }
                            if (u.gosCode) {
                                connection.info["gosCode"] = u.gosCode;
                            }
                            if (u.operator) {
                                const operator = operators.find(o => o.name === u.operator || o.code === u.operator);
                                if (operator) {
                                    connection.info.operator = operator;
                                }
                            }
                            if (u.supplier) {
                                const supplier = suppliers.find(o => o.name === u.supplier || o.code === u.supplier);
                                if (supplier) {
                                    connection.info.supplier = supplier;
                                }
                            }
                            if (u.surveyor) {
                                const surveyor = surveyors.find(o => o.name === u.surveyor || o.code === u.surveyor);
                                if (surveyor) {
                                    connection.info.surveyor = surveyor;
                                }
                            }
                            return connection;
                        });
                        const differs = uploadData.filter(u => {
                            const existing = connectionsMap[u.connectionId];
                            if (!existing) {
                                return true;
                            }
                            u.info.code = existing.connection.info.code;
                            u.info.connectionType = existing.connection.info.connectionType;
                            if (existing.connection.info.label) {
                                existing.connection.info.label = existing.connection.info.label.trim();
                            }
                            if (existing.connection.info.code) {
                                existing.connection.info.code = existing.connection.info.code.trim();
                            }
                            return hasDifferentFields(u.info, existing.connection.info);
                        });
                        console.info("difference: ", differs.map(d => ({
                            old: connectionsMap[d.connectionId]?.connection.info,
                            new: d.info
                        })));
                        return <UpsertConnections>{
                            updates: differs.map(d => (<UpsertConnection>{
                                connectionId: d.connectionId || uuid(),
                                locationId: d.locationId,
                                organisationId: locationsMap[d.locationId].organisationId,
                                info: d.info
                            }))
                        };
                    }));
                }));
        }));
}

function hasDifferentFields(objectA: ConnectionInfo, objectB: ConnectionInfo) {
    const keys = ["code", "connectionType", "label", "desiredStartDate", "desiredEndDate", "marketSegment", "profileCode",
        "supplierContract", "contractedCapacity", "gosCode", "operator", "supplier", "surveyor",
        "connectionValue"];
    const a = lodash.pick(objectA, keys);
    const b = lodash.pick(objectB, keys);
    const isEqual = lodash.isEqual(a, b);
    return !isEqual;
}


function prepareData(connections: Entity[], refdata: any[]) {
    const refDataObject = {
        operators: refdata[0],
        surveyors: refdata[1],
        suppliers: refdata[2],
        connectionValues: refdata[3]
    };
    return {
        referenceData: refDataObject,
        connections: connections.map(c => ({
            organisation: c.organisation.info.name,
            locationId: c.location.locationId,
            location: c.location.info.name,
            connectionId: c.connection.connectionId,
            street: c.location.info.address.street,
            streetNumber: c.location.info.address.number,
            streetNumberAddition: c.location.info.address.addition,
            zipCode: c.location.info.address.zipCode,
            city: c.location.info.address.city,
            country: c.location.info.address.country,
            ean: c.connection.info.code,
            connectionType: c.connection.info.connectionType,
            label: c.connection.info.label,
            desiredStartDate: c.connection.info.desiredStartDate,
            desiredEndDate: c.connection.info.desiredEndDate
                && moment(c.connection.info.desiredEndDate).subtract(1, 'day'),
            marketSegment: c.connection.info.marketSegment,
            offPeakPeriod: c.location.info.offPeakPeriod,
            operator: refDataObject.operators.find(s => s.code === c.connection.info.operator?.code)?.name,
            profileCode: c.connection.info.profileCode,
            supplier: refDataObject.suppliers.find(s => s.code === c.connection.info.supplier?.code)?.name,
            supplierContract: c.connection.info.supplierContract,
            surveyor: refDataObject.surveyors.find(s => s.code === c.connection.info.surveyor?.code)?.name,
            contractedCapacity: c.connection.info.contractedCapacity,
            connectionValue: refDataObject.connectionValues.find(s => s.connectionValueId === c.connection.info.connectionValue?.connectionValueId)?.name,
            gosCode: c.connection.info["gosCode"],
            authorised: c.connection.meters.some(m => !m.timeRange?.end && m.info.type == 'PRIMARY')
        })),
        meters: connections.sort(connectionComparator.compare)
            .flatMap(c => c.connection.meters.map(m => {
                return {
                    ean: c.connection.info.code,
                    connectionType: c.connection.info.connectionType,
                    meterType: m.info.type,
                    label: m.details?.label,
                    surveyor: m.info?.surveyor?.name,
                    accessPointId: m.info?.accessPointId,
                    meterNumbers: (m.details?.meterNumbers || m.info?.meterNumbers || []).join(', '),
                    startDate: m.timeRange?.start,
                    endDate: m.timeRange?.end,
                };
            })),
        locations: lodash.uniqBy(connections, 'locationId').sort(locationComparator.compare)
            .map(c => ({
                organisation: c.organisation.info.name,
                locationId: c.location.locationId,
                location: c.location.info.name,
                street: c.location.info.address.street,
                streetNumber: c.location.info.address.number,
                streetNumberAddition: c.location.info.address.addition,
                zipCode: c.location.info.address.zipCode,
                city: c.location.info.address.city,
                country: c.location.info.address.country
            })),
    };
}

const connectionInfoTemplate = {
    organisation: new RequiredField('A$'),
    locationId: new RequiredField('B$'),
    location: new RequiredField('C$'),

    street: new RequiredField('D$'),
    streetNumber: new RequiredField('E$'),
    streetNumberAddition: 'F$',
    zipCode: new RequiredField('G$'),
    city: new RequiredField('H$'),
    country: new RequiredField('I$'),

    connectionId: 'J$',
    ean: new RequiredField('K$'),
    connectionType: new RequiredField('L$'),
    label: 'M$',
    desiredStartDate: new DateField('N$'),
    desiredEndDate: new DateField('O$'),
    marketSegment: 'P$',
    offPeakPeriod: 'Q$',
    operator: 'R$',
    profileCode: 'S$',
    supplier: 'T$',
    supplierContract: 'U$',
    surveyor: 'V$',
    contractedCapacity: "W$",
    connectionValue: "X$",
    gosCode: "Y$",
    authorised: "Z$"
};

const connectionsTemplate: WorkBookTemplate = {
    sheets: [
        {
            name: 'Connections',
            template: {
                connections: new ArrayTemplate(connectionInfoTemplate, [2, 20000])
            }
        },
        {
            name: 'Meters',
            template: {
                meters: new ArrayTemplate({
                    ean: new RequiredField('A$'),
                    connectionType: new RequiredField('B$'),
                    meterType: new MappedField(new RequiredField('C$'), meterTypeMapper, meterTypeExporter),
                    label: 'D$',
                    surveyor: 'E$',
                    accessPointId: 'F$',
                    meterNumbers: 'G$',
                    startDate: new DateField('H$'),
                    endDate: new DateField('I$'),
                }, [2, 20000])
            }
        },
        {
            name: 'Locations',
            template: {
                locations: new ArrayTemplate({
                    organisation: new RequiredField('A$'),
                    locationId: new RequiredField('B$'),
                    location: new RequiredField('C$'),
                    street: new RequiredField('D$'),
                    streetNumber: new RequiredField('E$'),
                    streetNumberAddition: 'F$',
                    zipCode: new RequiredField('G$'),
                    city: new RequiredField('H$'),
                    country: new RequiredField('I$')
                }, [2, 20000])
            }
        },
        {
            name: 'Reference data',
            template: new ColumnTemplate({
                connectionTypes: new ArrayField("A$", 2, () => [ConnectionType.Electricity, ConnectionType.Gas, ConnectionType.Heat, ConnectionType.Water]),
                marketSegments: new ArrayField("B$", 2, () => ["KV", "GV"]),
                offPeakPeriod: new ArrayField("C$", 2, () => [OffPeakPeriod.NONE, OffPeakPeriod.EVENING, OffPeakPeriod.NIGHT]),
                operators: new ArrayField("D$", 2, (data) => data.operators.map(s => s.name)),
                suppliers: new ArrayField("E$", 2, (data) => data.suppliers.map(s => s.name)),
                surveyors: new ArrayField("F$", 2, (data) => data.surveyors.map(s => s.name)),
                connectionValues: new ArrayField("G$", 2, (data) => data.connectionValues.map(s => s.name))
            }, "referenceData")
        }
    ]
};

function meterTypeMapper(value: string, cell: Cell): MeterType {
    if (!value) {
        return null;
    }
    switch (value) {
        case 'Primary':
            return MeterType.PRIMARY;
        case 'Gross production':
            return MeterType.GROSS_PRODUCTION;
        case 'Sub-meter':
            return MeterType.INTERMEDIATE;
    }
    throw 'Cell ' + cell.cell + ' in sheet "' + cell.sheetName + '\" contains an unknown enum value: ' + value;
}

function meterTypeExporter(value: MeterType): string {
    if (value) {
        switch (value) {
            case MeterType.PRIMARY:
                return "Primary";
            case MeterType.GROSS_PRODUCTION:
                return "Gross production";
            case MeterType.INTERMEDIATE:
                return "Sub-meter";
        }
    }
}
