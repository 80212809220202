import {Component, inject, Input} from '@angular/core';
import {View} from "src/app/common/view";
import {Handler} from "src/app/common/handler";
import {
    Authorisation,
    AuthorisationType,
    DeleteUser,
    Organisation,
    UserProfile
} from "@flowmaps/flowmaps-typescriptmodels";
import {AppContext} from "../../../../../app-context";
import {AuthService} from "../../../../../utils/auth.service";
import {RefdataUtils} from "../../../refdata-utils";
import {ComparatorChain} from "../../../../../common/comparator-chain";
import {
    AuthorisationDetailsComponent
} from "../../../authorisations/authorisation-details/authorisation-details.component";
import {map, Observable} from 'rxjs';
import {cloneDeep} from "lodash";
import {UserDetailsComponent} from "../../user-details/user-details.component";

@Component({
    selector: 'app-users-overview-item',
    templateUrl: './users-overview-item.component.html',
    styleUrls: ['./users-overview-item.component.scss']
})
@Handler()
export class UsersOverviewItemComponent extends View {
    appContext = AppContext;
    refDataUtils = RefdataUtils;
    authService: AuthService = inject(AuthService);

    @Input() user: UserProfile;
    authorisationComparator: ComparatorChain = new ComparatorChain('nominatorOrganisation.info.name', 'nominatorOrganisation.organisationId');


    deleteUser = () => this.sendCommand("com.flowmaps.api.user.DeleteUser", <DeleteUser>{
        userId: this.user.userId
    });

    impersonate = () => this.authService.impersonate({userId: this.user.userId});

    openUserDetails = () => this.openModal(UserDetailsComponent, this.user);

    openAuthoriseUserDetails = () => this.openModal(AuthorisationDetailsComponent, <Authorisation>{
        nominee: this.user.userId,
        type: AuthorisationType.user
    });

    organisationAuthorisations = (): Observable<AuthorisationEntity[]> =>
        this.subscribeTo("getOrganisations")
            .pipe(map((o: Organisation[]) => this.user.receivedAuthorisations
                .filter(a => !a.revoked)
                .filter(a => o.map(o => o.organisationId).includes(a.nominator))
                .map(a => (<AuthorisationEntity>{
                    authorisation: a,
                    nominatorOrganisation: o.find(o => o.organisationId === a.nominator),
                    nomineeUser: this.user
                }))
                .sort(this.authorisationComparator.compare)));

    trackByForAuthorisation = (index: number, record: AuthorisationEntity) => record.authorisation.authorisationId;


    editAuthorisation = (authorisation: AuthorisationEntity) => {
        const data = cloneDeep(authorisation.authorisation);
        data["showNominator"] = true;
        this.openModal(AuthorisationDetailsComponent, data);
    };
}

interface AuthorisationEntity {
    authorisation?: Authorisation;
    nominatorOrganisation?: Organisation;
    nomineeUser?: UserProfile;
}