import {Component, Input, OnInit} from '@angular/core';
import {Connection, LinkContractToConnection, LinkedContract, Organisation,} from "@flowmaps/flowmaps-typescriptmodels";
import {uuid} from "../../../../common/utils";
import {RefdataUtils} from "../../refdata-utils";
import {Observable} from "rxjs";
import {View} from "../../../../common/view";
import {ContractEntity} from "../../../../handlers/app-entity-handler.service";
import {Handler} from "../../../../common/handler";
import {Entity} from "../../../../handlers/entity";

@Component({
    selector: 'app-link-contract-connection',
    templateUrl: './link-contract-connection.component.html',
    styleUrls: ['./link-contract-connection.component.scss']
})
@Handler()
export class LinkContractConnectionComponent extends View implements OnInit {
    refDataUtils = RefdataUtils;

    @Input() data: LinkContractToConnectionComponentData;
    isNewRecord: boolean;
    contract: ContractEntity;
    connection: Connection
    organisation: Organisation;

    ngOnInit(): void {
        if (this.data.linkedContract.contractId) {
            this.organisation = this.data.entity.organisation;
            this.subscribeTo("getContract", this.data.linkedContract.contractId)
                .subscribe((c: ContractEntity) => this.contract = c);
        }
        this.isNewRecord = !this.data.linkedContract.linkId;
        if (this.isNewRecord) {
            this.data = {
                linkedContract: {
                    linkId: uuid(),
                    contractId: this.data.linkedContract.contractId,
                    dateRange: {
                        start: this.data.linkedContract.dateRange.start,
                        end: null
                    },
                },
                entity: this.data.entity
            };
        };
        this.connection= this.data.entity.connection;
    }

    updateConnectionContractPeriod = () => {
        if (this.contract && this.contract.contract.contractData.connectionType === this.connection.info.connectionType) {
            this.sendCommand("com.flowmaps.api.organisation.LinkContractToConnection", <LinkContractToConnection> {
                linkedContract: this.data.linkedContract,
                connectionId: this.data.entity.connection.connectionId,
                locationId: this.data.entity.location.locationId,
                organisationId: this.data.entity.organisation.organisationId,
                linkId: this.data.linkedContract.linkId
            });
        } else {
            console.error('Contract type does not match connection type');
        }
    }

    searchContract = (term: string): Observable<ContractEntity[]> => this.sendQuery("searchContracts", term);

    onContractSelected = (contract: ContractEntity) => {
        this.contract = contract;
        this.data.linkedContract.contractId = contract.contract.contractId;
    }
}

export interface LinkContractToConnectionComponentData {
    linkedContract: LinkedContract;
    entity: Entity;
}