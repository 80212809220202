import {Component, Input, OnInit} from '@angular/core';
import {checkValidity, uuid} from "../../../common/utils";
import {openConfirmationModalWithCallback} from "../../../app-utils";
import {ModalConfirmAutofocus, ModalConfirmAutofocusData} from "../../../common/modal-confirm/modal-confirm.component";
import {Handler} from "../../../common/handler";
import {View} from "../../../common/view";
import {DeleteAlertChannel, SlackAlertChannelInfo, UpsertSlackAlertChannel} from "@flowmaps/flowmaps-typescriptmodels";

@Component({
  selector: 'app-slack-alert-channel-details',
  templateUrl: './slack-alert-channel-details.component.html',
  styleUrls: ['./slack-alert-channel-details.component.scss']
})
@Handler()
export class SlackAlertChannelDetailsComponent extends View implements OnInit {
  @Input() data: SlackAlertChannelDetailsComponentData;
  isNewRecord: boolean;

  ngOnInit(): void {
    this.isNewRecord = !this.data.alertChannelId;
    if (this.isNewRecord) {
      this.data.alertChannelId = uuid();
    }
  }

  updateInfo = () => this.sendCommand("com.flowmaps.api.monitoring.errors.slack.UpsertSlackAlertChannel", <UpsertSlackAlertChannel>{
    alertChannelId: this.data.alertChannelId,
    info: this.data.info
  });

  deleteChannel = () => {
    if (checkValidity(this.elementRef)) {
      openConfirmationModalWithCallback((confirmed) => {
        if (confirmed) {
          this.sendCommand("com.flowmaps.api.monitoring.errors.DeleteAlertChannel", <DeleteAlertChannel>{
            alertChannelId: this.data.alertChannelId
          });
        }
      }, ModalConfirmAutofocus, <ModalConfirmAutofocusData>{
        type: "info",
        title: "Delete channel",
        innerHtmlMessage: `<p><span>You are about to delete</span><span class="notranslate fw-bold"> </span><span> this channel.</span></p><p class="fw-bold">Are you sure you want to execute this action?</p>`,
        confirmText: "Delete channel",
        cancelText: "Cancel"
      }, 'static');
    }
  }
}

export interface SlackAlertChannelDetailsComponentData {
  alertChannelId?: string;
  info?: SlackAlertChannelInfo;
}
