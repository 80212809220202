import {Component} from '@angular/core';
import {ChangeUserPassword} from '@flowmaps/flowmaps-typescriptmodels';
import {AppContext} from '../../../app-context';
import {AppCommonUtils} from "../../../common/app-common-utils";
import {Handler} from "src/app/common/handler";
import {View} from "src/app/common/view";

@Component({
    selector: 'app-edit-profile-security',
    templateUrl: './edit-profile-security.component.html',
    styleUrls: ['./edit-profile-security.component.css']
})
@Handler()
export class EditProfileSecurityComponent extends View {
    changePasswordCommand: ChangeUserPassword = {
        userId: AppContext.userProfile.userId
    };

    changePassword = () => this.sendCommand("com.flowmaps.api.authentication.ChangeUserPassword", this.changePasswordCommand,
        () => {
            AppCommonUtils.registerSuccess("You have successfully changed your password");
            this.changePasswordCommand = {
                userId: AppContext.userProfile.userId
            };
        });

}
