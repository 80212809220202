import {BaseRouteReuseStrategy} from "@angular/router";
import lodash from "lodash";
import {ExtendedActivatedRouteSnapshot} from "../../app-routing.module";

export class RouteStrategyService extends BaseRouteReuseStrategy {

    shouldReuseRoute(future: ExtendedActivatedRouteSnapshot, current: ExtendedActivatedRouteSnapshot): boolean {
        if (!lodash.isUndefined(future.routeConfig?.data?.reUseRoute)) {
            return future.routeConfig.data.reUseRoute;
        }
        return super.shouldReuseRoute(future, current);
    }
}
