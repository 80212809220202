import {Component, Input, OnInit} from '@angular/core';
import {View} from "src/app/common/view";
import {Handler} from "src/app/common/handler";
import {ContractEntity} from "../../../../../handlers/app-entity-handler.service";
import {AppContext} from "../../../../../app-context";
import {DeleteConnection, DeleteMeter, LinkedContract, Meter} from "@flowmaps/flowmaps-typescriptmodels";
import {
    ConnectionComponentData,
    ConnectionDetailsComponent
} from "../../connection-details/connection-details.component";
import {AppCommonUtils} from "../../../../../common/app-common-utils";
import {DashboardContext} from "../../../../dashboard/dashboard.context";
import {map, Observable} from "rxjs";
import {
    UpsertSolarEdgeMeterComponent,
    UpsertSolarEdgeMeterComponentData
} from "../../../meters/upsert-solar-edge-meter/upsert-solar-edge-meter.component";
import {cloneDeep} from "lodash";
import {
    LinkContractConnectionComponent,
    LinkContractToConnectionComponentData
} from "../../link-contract-connection/link-contract-connection.component";
import {Entity} from "../../../../../handlers/entity";

@Component({
    selector: 'app-connections-overview-item',
    templateUrl: './connections-overview-item.component.html',
    styleUrls: ['./connections-overview-item.component.scss']
})
@Handler()
export class ConnectionsOverviewItemComponent extends View implements OnInit {
    appContext = AppContext;

    @Input() entity: Entity;
    @Input() selecting: boolean;

    meters: Observable<Entity[]>;

    get connection() {
        return this.entity.connection;
    }

    ngOnInit() {
        this.meters = this.subscribeTo("getEntitiesById", this.connection.meters.map(m => m.meterId));
    }

    toggleSelectedItem = (value: boolean) => this.sendCommandAndForget("selectionChange", {
        entity: this.entity,
        selected: value
    });

    openConnectionDetails = () => this.openModal(ConnectionDetailsComponent, <ConnectionComponentData>{
        organisation: this.entity.organisation,
        location: this.entity.location,
        connection: this.connection
    });

    openUpsertSolarEdgeMeter = () => this.openModal(UpsertSolarEdgeMeterComponent, <UpsertSolarEdgeMeterComponentData>{
        connection: this.connection
    });

    openConnectionGraph = () => AppCommonUtils.navigateToUrl('/dashboard/' + DashboardContext.dashboardToBase64(DashboardContext.connectionDashboard(this.connection.connectionId)));

    deleteConnection = () => this.sendCommand("com.flowmaps.api.organisation.DeleteConnection", <DeleteConnection>{
        organisationId: this.entity.organisation.organisationId,
        locationId: this.entity.location.locationId,
        connectionId: this.connection.connectionId
    });

    linkedContracts = (): LinkedContract[] => {
        if (this.connection.linkedContracts) {
            return this.connection.linkedContracts.map(lc => ({
                contractId: lc.contractId,
                dateRange: lc.dateRange,
                linkId: lc.linkId,
            }));
        } else {
            return [];
        }
    }

    trackByLinkedContractId = (index: number, record: LinkedContract) => record.linkId;

    allowContractDelete = (linkedContract: LinkedContract): boolean => AppContext.isAdminOrIntermediary();

    isSolarEdgeMeter = (meter: Meter): boolean => meter.info.surveyor?.code === "f31a8249-3d2f-4154-bef1-49eb8d9f17fe";

    allowMeterDelete = (meter: Entity): boolean => AppContext.isAdminOrIntermediary() && this.isSolarEdgeMeter(meter.meter);

    allowAddMeter = (): boolean => this.connection.info.connectionType === "Electricity";

    deleteMeter = (meter: Entity) => this.sendCommand("com.flowmaps.api.organisation.DeleteMeter", <DeleteMeter>{
        organisationId: meter.organisation.organisationId,
        locationId: meter.location.locationId,
        connectionId: meter.connection.connectionId,
        meterId: meter.meter.meterId
    });

    trackByMeterId = (index: number, record: Entity) => record.meter.meterId;

    getContractDescription = (contractId: string): Observable<string> =>
        this.subscribeTo("getContract", contractId).pipe(map((c: ContractEntity) => c?.contract.contractData.name));

    linkContract = () => {
        this.updateLinkedContract({
            contractId: null,
            linkId: null,
            dateRange: {
                start: this.connection.linkedContractsLastEndDate || null,
                end: null
            }
        });
    }

    updateLinkedContract = (contract: LinkedContract) => {
        this.openModal(LinkContractConnectionComponent, <LinkContractToConnectionComponentData>{
            linkedContract: cloneDeep(contract),
            entity: this.entity
        });
    }

    unlinkContract = (linkedContract: LinkedContract) => null;
}
