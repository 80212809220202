import {Component, Input} from '@angular/core';
import {View} from "src/app/common/view";
import {Handler} from "src/app/common/handler";
import {AppContext} from "../../../../../app-context";
import {ConsumptionTax, DeleteConsumptionTax, DeleteTaxPeriod, TaxPeriod} from "@flowmaps/flowmaps-typescriptmodels";
import {cloneDeep} from "lodash";
import {
  TaxPeriodDetailsComponent,
  TaxPeriodDetailsComponentData
} from "../../tax-period-details/tax-period-details.component";

@Component({
  selector: 'app-taxes-overview-item',
  templateUrl: './taxes-overview-item.component.html',
  styleUrls: ['./taxes-overview-item.component.scss']
})
@Handler()
export class TaxesOverviewItemComponent extends View {
  appContext = AppContext;
  @Input() tax: ConsumptionTax;

  deleteTax = () => {
    this.sendCommand("com.flowmaps.api.organisation.DeleteConsumptionTax", <DeleteConsumptionTax> {
      taxId: this.tax.taxId,
    });
  }

  deleteTaxPeriod = (record: TaxPeriod) => {
    this.sendCommand("com.flowmaps.api.organisation.DeleteTaxPeriod", <DeleteTaxPeriod>{
      periodId: record.periodId,
      taxId: this.tax.taxId
    });
  }

  addNewTaxPeriod = () => {
    this.openTaxPeriodModal({
      periodId: '',
      data: {
        period: {
          start: "",
          end: ""
        },
        residentialRate: null,
        taxBrackets: []
      }
    });
  }

  openTaxPeriodModal(taxPeriod?: TaxPeriod): void {
    this.openModal(TaxPeriodDetailsComponent, cloneDeep(<TaxPeriodDetailsComponentData>{
      period: taxPeriod,
      tax: this.tax,
    }));
  }
}
