import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output, TemplateRef} from '@angular/core';
import {FacetStats} from "../facet-filter/facet-filter.component";
import {Observable} from "rxjs";
import {SentenceCasePipe} from "../../sentence-case.pipe";
import {FacetUtils} from "../facet-utils";
import {FacetFilter, FacetResult, FacetValueResult} from "@flowmaps/flowmaps-typescriptmodels";
import lodash from "lodash";

@Component({
  selector: 'app-facets-overview',
  templateUrl: './facets-overview.component.html',
  styleUrls: ['./facets-overview.component.scss']
})
export class FacetsOverviewComponent implements OnInit, AfterViewInit {
  facetUtils = FacetUtils;
  @Input() otherFacets: FacetStats[];
  @Input() facetNameFormatter: (name: string) => string = this.defaultNameFormatter;
  @Input() specificFacetFiltersTemplate: TemplateRef<any>;
  @Input() localStorageKey: string;
  @Input() hiddenFacets: string[] = [];
  @Input() getValueFormatter: (name: string) => (value: string) => string = this.defaultValueFormatter;
  @Input() filterFacetValues: (name: string, values: FacetValueResult[]) => FacetValueResult[];
  @Input() getValuesSelectedCallback: (name: string) => (facet: FacetStats, selectedValues: string[]) => string[];
  @Input() getTranslateValues: (name: string) => boolean;
  @Input() position: 'top' | 'left';
  @Input() defaultFacetFilters: FacetFilter[];

  @Output() facetFiltersChange: EventEmitter<FacetFilter[]> = new EventEmitter<FacetFilter[]>();
  @Output() onUpdate: EventEmitter<FacetFilter[]> = new EventEmitter<FacetFilter[]>();

  query: Observable<any>;
  facetFilters: FacetFilter[];
  facetFiltersMap: Map<string, string[]> = new Map();
  facets: FacetStats[] = [];
  rendered: boolean = false;

  ngOnInit() {
    const value = this.localStorageKey ? localStorage.getItem(this.localStorageKey) : null;
    if (this.localStorageKey) {
      this.facetFiltersMap = JSON.parse(value) || {};
      if (lodash.isEmpty(this.facetFiltersMap) && this.defaultFacetFilters?.length) {
        this.defaultFacetFilters.forEach(f => this.facetFiltersMap[f.facetName] = f.values);
      }
      this.emitValue(this.getFacetFilters(this.facetFiltersMap));
    }
  }

  ngAfterViewInit() {
    this.rendered = true;
    if (this.query) {
      this.subscribeToQuery();
    }
  }

  subscribeToQuery = () => this.query.subscribe((s: FacetResult) => this.facets = this.facetResultToStats(s.facets));

  @Input("query")
  set setQuery(o: Observable<any>) {
    this.query = o;
    if (this.query && this.rendered) {
      this.subscribeToQuery();
    }
  }

  trackByFacetName = (index: number, record: FacetStats) => record.name;

  onFacetValuesChange = (facet: FacetStats, selectedValues: string[]) => {
    if (selectedValues?.length > 0) {
      this.facetFiltersMap[facet.name] = selectedValues;
    } else {
      delete this.facetFiltersMap[facet.name];
    }
    this.updateValue();
  }

  private updateValue() {
    if (this.localStorageKey) {
      localStorage.setItem(this.localStorageKey, JSON.stringify(this.facetFiltersMap));
    }
    this.facetFilters = this.getFacetFilters(this.facetFiltersMap);
    this.emitValue(this.facetFilters);
    this.onUpdate.emit(this.facetFilters);
  }

  get selectedOtherFacetValues(): FacetStats[] {
    return this.otherFacets?.filter(f => this.facetFiltersMap[f.name]?.length > 0) || [];
  }

  get hasOtherFacetValueSelected(): boolean {
    return this.selectedOtherFacetValues.length > 0;
  }

  get otherValuesSelectedFormatted(): string {
    return this.selectedOtherFacetValues.map(f => this.facetNameFormatter(f.name)).join(", ");
  }

  private emitValue(value: FacetFilter[]) {
    this.facetFilters = value;
    this.facetFiltersChange.emit(value);
  }

  clearFilters = () => {
    this.facetFiltersMap = new Map();
    this.updateValue();
  }

  private getFacetFilters = (filters: Map<string, string[]>) => Object.entries(filters).map(
    entry => ({
      facetName: entry[0],
      values: entry[1]
    }));

  private facetResultToStats = (facets: FacetResult) => Object.entries(facets)
    .map(e => (<FacetStats>{
      name: e[0],
      values: this.filterFacetValues ? this.filterFacetValues(e[0], e[1]) : e[1],
      nameFormatter: this.facetNameFormatter,
      valueFormatter: this.getValueFormatter ? this.getValueFormatter(e[0]) : null,
      valuesSelectedCallback: this.getValuesSelectedCallback ? this.getValuesSelectedCallback(e[0]) : null,
      translateValues: this.getTranslateValues
    })).filter(e => !(this.hiddenFacets || []).includes(e.name));

  private defaultNameFormatter(name: string): string {
    const nameSplit = name.split("/");
    return SentenceCasePipe.formatWithSpaces(nameSplit[nameSplit.length-1]);
  }

  private defaultValueFormatter(name: string): (value: string) => string {
    return (value: string) => SentenceCasePipe.format(value);
  }
}
