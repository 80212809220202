import {Component, Input, OnInit} from '@angular/core';
import {View} from "src/app/common/view";
import {Handler} from "src/app/common/handler";
import {
    Connection,
    ConnectionInfo,
    ElectricityConnectionInfo,
    GasConnectionInfo,
    HeatConnectionInfo,
    Location,
    Organisation,
    UpsertConnection,
    UpsertEnecoMeter
} from "@flowmaps/flowmaps-typescriptmodels";
import {AppContext} from "../../../../app-context";
import {RefdataUtils} from "../../refdata-utils";
import {uuid} from "../../../../common/utils";
import {Entity} from "../../../../handlers/entity";
import {Observable} from "rxjs";

@Component({
  selector: 'app-connection-details',
  templateUrl: './connection-details.component.html',
  styleUrls: ['./connection-details.component.scss']
})
@Handler()
export class ConnectionDetailsComponent extends View implements OnInit {
  appContext = AppContext;
  refDataUtils = RefdataUtils;
  @Input() data: ConnectionComponentData;

  newConnectionId: string;
  connectionTypes: string[] = ["Electricity", "Gas", "Heat", "Water"];
  marketSegments: string[] = ["KV", "GV"];
  location: Entity;
  info: ConnectionInfo;
  enecoMeterNumber: string;

  get infoAsElectricity(): ElectricityConnectionInfo {
    return this.info as ElectricityConnectionInfo;
  }

  get infoAsGas(): GasConnectionInfo {
    return this.info as GasConnectionInfo;
  }

  get infoAsHeat(): HeatConnectionInfo {
    return this.info as HeatConnectionInfo;
  }

  get isEnecoConnection(): boolean {
    return this.info.surveyor?.code === "089349d8-db89-447e-a838-79a0794997cf";
  }

  get contractedCapacity() {
    return this.info.connectionValue?.contractedCapacity || this.info.contractedCapacity;
  }

  ngOnInit(): void {
    if (!this.data.connection) {
      this.newConnectionId = uuid();
    }
    this.info = this.data.connection?.info || <ConnectionInfo>{};
    if (this.isEnecoConnection) {
      this.enecoMeterNumber = this.data.connection?.meters?.find(m => m.meterId.startsWith("eneco-"))?.info?.meterNumbers[0];
    }
    if (this.data.location) {
      this.location = new Entity(this.data.organisation, this.data.location)
    }
  }

  onLocationSelected = (entity: Entity) => {
    this.data.organisation = entity.organisation;
  }

  searchLocation = (term: string): Observable<Entity[]> => this.sendQuery("getLocationsPerOrganisation", {
        organisationId: this.data.organisation.organisationId,
        term: term
  });

  updateInfo = () => {
    if (this.isEnecoConnection) {
      return this.sendCommand("com.flowmaps.api.organisation.UpsertEnecoMeter", <UpsertEnecoMeter> {
        organisationId: this.data.organisation.organisationId,
        locationId: this.location.location.locationId,
        connectionId: this.newConnectionId || this.data.connection?.connectionId,
        info: this.infoAsHeat,
        meterNumber: this.enecoMeterNumber
      });
    } else {
      return this.sendCommand("com.flowmaps.api.organisation.UpsertConnection", <UpsertConnection> {
        organisationId: this.data.organisation.organisationId,
        locationId: this.location.location.locationId,
        connectionId: this.newConnectionId || this.data.connection?.connectionId,
        info: this.info
      });
    }
  };
}

export interface ConnectionComponentData {
  organisation: Organisation;
  location?: Location;
  connection?: Connection;
}