import {Component, forwardRef} from '@angular/core';
import {ConnectionType, DataType} from "@flowmaps/flowmaps-typescriptmodels";
import {BaseMeasurementChartComponent, MeasurementDataset} from "../base-measurement-chart";
import {DashboardContext} from "../../dashboard/dashboard.context";
import {cloneDeep} from "lodash";

@Component({
    selector: 'app-gas-chart',
    templateUrl: './gas-chart.component.html',
    styleUrls: ['./gas-chart.component.scss'],
    providers: [{provide: BaseMeasurementChartComponent, useExisting: forwardRef(() => GasChartComponent)}]
})
export class GasChartComponent extends BaseMeasurementChartComponent {
    contractedCapacityRequiredForPower = true;

    showDataNormally = (): boolean => true;

    measurementTypes = (): DataType[] => {
        const consumptions = [DataType.gasConsumption, DataType.heatConsumption];
        if (this.options.showUncorrectedConsumption) {
            consumptions.push(DataType.gasConsumptionUncorrected);
        }
        return consumptions;
    }

    productionDataTypes = (): DataType[] => [];

    powerDataType = (): DataType => DataType.gasPower;

    consumptionProductionLink = (): Map<DataType, DataType> => new Map<DataType, DataType>();

    measurementIntermediateLink = (): Map<DataType, DataType[]> => new Map<DataType, DataType[]>();

    measurementTypesMapped = (): { [key: string]: DataType[] } => ({
        [DataType.gasConsumption]: [DataType.gasConsumption],
        [DataType.gasConsumptionUncorrected]: [DataType.gasConsumptionUncorrected],
        [DataType.heatConsumption]: [DataType.heatConsumption],
    });

    connectionType = (): ConnectionType => ConnectionType.Gas;

    openModal = () => this.openModalWithType(GasChartComponent);

    measurementUnit = () => this.context.getMeasurementUnit(DataType.gasConsumption);

    completenessTypes = (): ConnectionType[] => [ConnectionType.Gas, ConnectionType.Heat];

    getDataOfMeasurements = (rawData: MeasurementDataset[], measurementTypes: DataType[]): MeasurementDataset[] =>
        rawData.filter(d => measurementTypes.includes(d.measurementType))
            .map(d => {
                const measurementSet = cloneDeep(d);
                if (measurementSet.measurementType === DataType.heatConsumption) {
                    measurementSet.dataset.data = measurementSet.dataset.data.map(v => (v as number) * DashboardContext.gjToM3Rate);
                }
                return measurementSet;
            });

    addConnectionTypeSpecificData(data: MeasurementDataset[]) {
        super.addConnectionTypeSpecificData(data);
        const contractedCapacity = this.contractedCapacity(ConnectionType.Heat);
        if (contractedCapacity) {
            this.addPowerToChart(data, DataType.heatPower, ConnectionType.Heat, contractedCapacity);
        }
    }
}