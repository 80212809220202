import {Component, Input} from '@angular/core';
import {View} from "src/app/common/view";
import {Handler} from "src/app/common/handler";
import {DeleteOrganisation, Organisation} from "@flowmaps/flowmaps-typescriptmodels";
import lodash from "lodash";
import {AppContext} from "../../../../../app-context";
import {AppCommonUtils} from "../../../../../common/app-common-utils";
import {DashboardContext} from "../../../../dashboard/dashboard.context";
import {
  LinkContractOrganisationComponent,
  LinkContractToOrganisationData
} from "../../../contracts/link-contract-organisation/link-contract-organisation.component";

@Component({
  selector: 'app-organisation-overview-item',
  templateUrl: './organisation-overview-item.component.html',
  styleUrls: ['./organisation-overview-item.component.scss']
})
@Handler()
export class OrganisationOverviewItemComponent extends View {
  appContext = AppContext;
  @Input() organisation: Organisation;

  openOrganisationGraph = (organisation: Organisation) => AppCommonUtils.navigateToUrl('/dashboard/' + DashboardContext.dashboardToBase64(DashboardContext.organisationDashboard(organisation.organisationId)));

  deleteOrganisation = (organisation: Organisation) => this.sendCommand("com.flowmaps.api.organisation.DeleteOrganisation", <DeleteOrganisation>{
    organisationId: organisation.organisationId
  });

  getConnectionCount = (organisation: Organisation) => lodash.sum(organisation.locations?.map(l => l.connections.length));

  linkNewContract(organisation: Organisation) {
    this.openModal(LinkContractOrganisationComponent, <LinkContractToOrganisationData>{
      organisationId: organisation.organisationId,
      linkedContract: {
        contractId: '',
        linkId: '',
        dateRange: {
          start: organisation.linkedContractsLastEndDate || '',
          end: ''
        },
      },
    }, {
      cssClass: 'modal-xl custom-modal-wide'
    });
  }
}
