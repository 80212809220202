import {Chart, Plugin, Scale} from "chart.js";
import {AnyObject} from "chart.js/dist/types/basic";
import {ChartUtilsService} from "../chart-utils.service";

export class BarAxesPercentagePlugin implements Plugin<'bar'> {
    id = "axesPercentage";

    constructor(private chartUtilsService: ChartUtilsService, private scales: string[]) {
    }

    afterBuildTicks = (chart: Chart, args: { scale: Scale }, options: AnyObject) => {
        if (this.scales.includes(args.scale.id)) {
            args.scale.afterTickToLabelConversion = () => this.applyScaleToTickLabels(args.scale);
            if (args.scale.ticks && args.scale.ticks.length > 0) {
                args.scale.ticks = args.scale.ticks.map((t, i) => {
                    t['percentage'] = t['value'];
                    return t;
                });
            }
        }
    }

    private applyScaleToTickLabels(scale: Scale) {
        const ticks = scale.ticks;
        if (ticks) {
            ticks.forEach(t => {
                if (t['percentage'] > 0) {
                    t.label = (t['percentage'] * 100).toString();
                }
            });
        }
    }


    axisTitle = (baseTitle: string): string => {
        return `${baseTitle} %`;
    }
}