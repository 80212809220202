import {Component, Input, TemplateRef, ViewChild} from '@angular/core';
import moment from "moment";
import {Handler} from "src/app/common/handler";
import {View} from "src/app/common/view";
import {AppContext} from "../../../app-context";
import {checkValidity} from "../../../common/utils";
import {openConfirmationModalWithCallback} from "../../../app-utils";
import {ModalConfirmAutofocus, ModalConfirmAutofocusData} from "../../../common/modal-confirm/modal-confirm.component";
import {AlertEntity} from "../../../handlers/issues-standalone-handler";
import {AlertStatus, MuteAlerts, ResolveAlerts, UnmuteAlerts} from "@flowmaps/flowmaps-typescriptmodels";
import {AppCommonUtils} from "../../../common/app-common-utils";

@Component({
    selector: 'app-alert-selection',
    templateUrl: './alert-selection.component.html',
    styleUrls: ['./alert-selection.component.scss']
})
@Handler()
export class AlertSelectionComponent extends View {
    appContext = AppContext;

    @Input() selectedItems: AlertEntity[] = [];
    @Input() cancelMethod: () => void;
    @ViewChild("muteAlertTemplate") muteAlertTemplate: TemplateRef<any>;
    muteDate: string;

    tomorrow = (): string => moment().startOf("day").add(1, "day").toISOString();

    get hasAnyMutedAlert() {
        return this.selectedItems.some(s => s.alert.alertStatus === AlertStatus.MUTED);
    }

    get hasAnyOpenAlert() {
        return this.selectedItems.some(s => s.alert.alertStatus === AlertStatus.OPEN);
    }

    unmuteAlerts = () => {
        if (this.selectedItems.length === 0) {
            AppCommonUtils.registerError("Please select at least 1 record");
            return;
        }

        if (checkValidity(this.elementRef)) {
            openConfirmationModalWithCallback((confirmed) => {
                if (confirmed) {
                    this.sendCommand("com.flowmaps.api.monitoring.alerting.UnmuteAlerts", <UnmuteAlerts>{
                        alertIds: this.selectedItems.filter(s => s.alert.alertStatus == AlertStatus.MUTED)
                            .map(s => s.alert.alertId)
                    }, () => {
                        this.muteDate = null;
                        this.selectedItems.forEach(s => s.alert.alertStatus = AlertStatus.OPEN);
                        this.cancelMethod();
                    });
                }
            }, ModalConfirmAutofocus, <ModalConfirmAutofocusData>{
                type: "info",
                title: "Unmute alerts",
                innerHtmlMessage: `<p><span>You are about to unmute</span><span class="notranslate fw-bold"> ${this.selectedItems.length} </span><span>bulk unmute alerts</span></p><p class="fw-bold">Are you sure you want to execute this action?</p>`,
                confirmText: "Unmute alert",
                cancelText: "Cancel"
            }, 'static');
        }
    }

    muteAlerts = () => {
        if (this.selectedItems.length === 0) {
            AppCommonUtils.registerError("Please select at least 1 record");
            return;
        }
        if (checkValidity(this.elementRef)) {
            openConfirmationModalWithCallback((confirmed) => {
                if (confirmed) {
                    this.sendCommand("com.flowmaps.api.monitoring.alerting.MuteAlerts", <MuteAlerts>{
                        alertIds: this.selectedItems.filter(s => s.alert.alertStatus != AlertStatus.RESOLVED)
                            .map(s => s.alert.alertId),
                        deadline: this.muteDate
                    }, () => {
                        this.selectedItems.forEach(s => {
                            s.alert.alertStatus = AlertStatus.MUTED;
                            s.alert.muteDeadline = this.muteDate;
                        });
                        this.muteDate = null;
                        this.cancelMethod();
                    });
                }
            }, ModalConfirmAutofocus, <ModalConfirmAutofocusData>{
                type: "info",
                title: "Mute alerts",
                body: this.muteAlertTemplate,
                confirmText: "Mute alert",
                cancelText: "Cancel"
            }, 'static');
        }
    }

    resolveAlerts = () => {
        if (this.selectedItems.length === 0) {
            AppCommonUtils.registerError("Please select at least 1 record");
            return;
        }
        if (checkValidity(this.elementRef)) {
            openConfirmationModalWithCallback((confirmed) => {
                if (confirmed) {
                    this.sendCommand("com.flowmaps.api.monitoring.alerting.ResolveAlerts", <ResolveAlerts>{
                        alertIds: this.selectedItems.filter(s => s.alert.alertStatus != AlertStatus.RESOLVED)
                            .map(s => s.alert.alertId)
                    }, () => {
                        this.selectedItems.forEach(s => s.alert.alertStatus = AlertStatus.RESOLVED);
                        this.muteDate = null;
                        this.cancelMethod();
                    });
                }
            }, ModalConfirmAutofocus, <ModalConfirmAutofocusData>{
                type: "info",
                title: "Resolve alert",
                innerHtmlMessage: `<p><span>You are about to resolve</span><span class="notranslate fw-bold"> ${this.selectedItems.length} </span><span>bulk resolve alerts</span></p><p class="fw-bold">Are you sure you want to execute this action?</p>`,
                confirmText: "Resolve alert",
                cancelText: "Cancel"
            }, 'static');
        }
    }
}
