import {Component, Input, OnInit} from '@angular/core';
import {View} from "src/app/common/view";
import {Handler} from "src/app/common/handler";
import {AppContext} from "../../../../app-context";
import {RefdataUtils} from "../../refdata-utils";
import {ConsumptionTax, CreateConsumptionTax} from "@flowmaps/flowmaps-typescriptmodels";

@Component({
  selector: 'app-add-tax',
  templateUrl: './add-tax.component.html',
  styleUrls: ['./add-tax.component.scss']
})
@Handler()
export class AddTaxComponent extends View implements OnInit {
  protected readonly appContext = AppContext;
  protected readonly refDataUtils = RefdataUtils;

  @Input() data: ConsumptionTax;
  isNewRecord: boolean;

  ngOnInit(): void {
    this.isNewRecord = !this.data || !this.data.taxId;
  }

  connectionTypes: string[] = ["Electricity", "Gas", "Heat", "Water", "Cooling"];

  updateInfo = () => {
    this.sendCommand("com.flowmaps.api.organisation.CreateConsumptionTax", <CreateConsumptionTax>{
      taxId: this.data.taxId,
      info: this.data.taxInfo,
    });
  }
}
