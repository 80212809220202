import {Component, inject, Input} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {InjectorProvider} from "../../../../common/app-common-utils";
import {Location} from "@angular/common";
import {Handler} from "../../../../common/handler";
import {View} from "../../../../common/view";
import {Error, ErrorStatus} from "@flowmaps/flowmaps-typescriptmodels";

@Component({
  selector: 'app-issue-overview-item',
  templateUrl: './issue-overview-item.component.html',
  styleUrls: ['./issue-overview-item.component.scss']
})
@Handler()
export class IssueOverviewItemComponent extends View {
  private location = inject(Location);
  private router: Router = inject(Router);
  private route: ActivatedRoute = inject(ActivatedRoute);

  @Input() issue: Error;
  @Input() selecting: boolean;

  isResolved = (issue: Error): boolean => ErrorStatus.RESOLVED == issue.status;
  isMuted = (issue: Error): boolean => ErrorStatus.MUTED == issue.status;

  getErrorStatusConfig = (issue: Error): IssueStatusConfig => {
    switch (issue.status) {
      case ErrorStatus.RESOLVED:
        return {
          colorClass: "success",
          icon: "bi-check-circle"
        };
      case ErrorStatus.MUTED:
        return {
          colorClass: "muted",
          icon: "bi-volume-mute"
        };
      default:
        return {
          colorClass: this.badgeColorForType(issue),
          icon: "bi-record-circle"
        };
    }
  }

  badgeColorForType = (issue: Error) => {
    if (issue.status === ErrorStatus.RESOLVED) {
      return "light";
    }
    switch (issue.errorDetails.level) {
      case "error":
        return "danger";
      case "warning":
        return "warning";
      default:
        return "info";
    }
  }

  handleIssueClick(event: MouseEvent, issueId: string) {
    event.stopPropagation();
    this.sendCommandAndForget("openIssueDetails", issueId);
    if (issueId === this.route.snapshot.queryParamMap.get("issueId")) {
      this.location.go(InjectorProvider.injector.get(Router).createUrlTree(["../issues"],
        {relativeTo: this.route, queryParams: {issueId: issueId}}).toString())
    } else {
      this.router.navigate(["../issues"], {
        relativeTo: this.route,
        queryParams: {
          issueId: issueId
        }
      });
    }
  }

  toggleSelectedItem = (value: boolean) => this.sendCommandAndForget("selectionChange", {
    entity: this.issue,
    selected: value
  });
}

interface IssueStatusConfig {
  colorClass: string;
  icon: string;
}
