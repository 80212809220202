import {Component, Input, OnInit} from '@angular/core';
import {ErrorEntry} from "@flowmaps/flowmaps-typescriptmodels";
import {RefdataUtils} from "../../refdata/refdata-utils";

@Component({
  selector: 'app-issue-entry',
  templateUrl: './issue-entry.component.html',
  styleUrls: ['./issue-entry.component.scss']
})
export class IssueEntryComponent implements OnInit {
  @Input() entry: ErrorEntry;

  formattedLevel: string;
  formattedEnvironment: string;
  isFullStackTraceDisplayed: boolean = true;

  ngOnInit(): void {
    this.formatEnumValues();
  }

  toggleStackTrace() {
    this.isFullStackTraceDisplayed = !this.isFullStackTraceDisplayed;
  }

  getStackTrace(): string {
    if (this.isFullStackTraceDisplayed) {
      return this.entry.errorDetails.stackTrace;
    } else {
      return this.entry.errorDetails.stackTrace
        .split('\n')
        .filter(line => !line.trim().startsWith('at com.flowmaps.'))
        .join('\n');
    }
  }

  formatEnumValues(): void {
    this.formattedLevel = RefdataUtils.enumFormatter(this.entry.errorDetails.level);
    this.formattedEnvironment = RefdataUtils.enumFormatter(this.entry.errorDetails.environment);
  }

  getKibanaUrl(fieldValue: string): string {
    return `https://kibana.flowmaps.com/_dashboards/app/discover#/?_g=(filters:!(),refreshInterval:(pause:!t,value:0),time:(from:now%2Fd,to:now%2Fd))&_a=(columns:!(type,messageType,payload),filters:!(),index:'91805d20-606b-11ee-a969-e30f3e4e5628',interval:auto,query:(language:kuery,query:\"${encodeURIComponent(fieldValue)}\"),sort:!())`;
  }
}

