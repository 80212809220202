import {Component} from '@angular/core';
import {copyToClipboard} from '../../../common/utils';
import {View} from 'src/app/common/view';
import {Handler} from 'src/app/common/handler';
import {AppCommonUtils} from 'src/app/common/app-common-utils';
import {EncryptValue} from "@flowmaps/flowmaps-typescriptmodels";

@Component({
    selector: 'app-encrypt',
    templateUrl: './encrypt.component.html',
    styleUrls: ['./encrypt.component.css']
})
@Handler()
export class EncryptComponent extends View {
    value: string;
    result: string;

    encrypt() {
        this.sendCommand("com.flowmaps.api.admin.EncryptValue", <EncryptValue>{secret: this.value},
            result => {
                copyToClipboard(result).subscribe({
                    error: () => console.error("Failed to copy value to clipboard: " + result),
                    complete: () => AppCommonUtils.registerSuccess("Encrypted value was copied to your clipboard!")
                });
            });
    }
}
