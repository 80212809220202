import {Component, Input, OnInit} from '@angular/core';
import {ConnectionType, Meter, MeterType} from "@flowmaps/flowmaps-typescriptmodels";
import {Entity} from "../../../../handlers/entity";
import {View} from "../../../../common/view";
import {Handler} from "../../../../common/handler";

@Component({
  selector: 'app-location-meter-type-info',
  templateUrl: './location-meter-type-info.component.html',
  styleUrls: ['./location-meter-type-info.component.scss']
})
@Handler()
export class LocationMeterTypeInfoComponent extends View implements OnInit {
    @Input() location: Entity;
    @Input() connectionType: ConnectionType;
    @Input() primary: boolean;
    meters: Entity[];

    ngOnInit() {
        this.sendQuery("getEntitiesById", this.location.location.connections.flatMap(c => c.meters).map(m => m.meterId))
            .subscribe(e => this.meters = e)
    }

    getMetersForConnectionType = (): Meter[] => {
        return this.meters
            .filter(c => c.connection.info.connectionType === this.connectionType)
            .filter(c => this.primary ? c.meter.info.type === MeterType.PRIMARY : c.meter.info.type !== MeterType.PRIMARY)
            .map(c => c.meter);
    }

    trackBy: any = (index: number, item: any) => {
        return item?.meterId;
    };
}
