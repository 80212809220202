import {Component} from '@angular/core';
import {DeleteUser, UpdateUserInfo} from '@flowmaps/flowmaps-typescriptmodels';
import {AppContext} from '../../../app-context';
import {cloneObject} from '../../../common/utils';
import {AppCommonUtils} from "../../../common/app-common-utils";
import {Handler} from "src/app/common/handler";
import {View} from "src/app/common/view";

@Component({
    selector: 'app-edit-profile-general',
    templateUrl: './edit-profile-general.component.html',
    styleUrls: ['./edit-profile-general.component.css']
})
@Handler()
export class EditProfileGeneralComponent extends View {

    command: UpdateUserInfo = {userId: AppContext.userProfile.userId, info: cloneObject(AppContext.userProfile.info)};

    updateInfo = () => this.sendCommand("com.flowmaps.api.user.UpdateUserInfo", this.command,
        () => {
            AppCommonUtils.registerSuccess("You have successfully updated your profile");
            AppContext.userProfile.info = cloneObject(this.command.info);
        });

    deleteAccount = () => this.sendCommand("com.flowmaps.api.user.DeleteUser", <DeleteUser>{userId: AppContext.userProfile.userId},
        () => {
            AppCommonUtils.registerSuccess("Your account has been deleted successfully");
            AppContext.setUserProfile(undefined);
            AppCommonUtils.navigateToUrl("/login");
        });
}
