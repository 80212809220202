import {Component, Input} from '@angular/core';
import {CreateOrganisation, Organisation} from "@flowmaps/flowmaps-typescriptmodels";
import {uuid} from "../../../../common/utils";
import {AppCommonUtils} from "../../../../common/app-common-utils";
import {View} from "src/app/common/view";
import {Handler} from "src/app/common/handler";

@Component({
  selector: 'app-add-organisation',
  templateUrl: './add-organisation.component.html',
  styleUrls: ['./add-organisation.component.scss']
})
@Handler()
export class AddOrganisationComponent extends View {
  @Input() data: AddOrganisationComponentData;

  updateInfo = () => {
    const organisationId =  uuid();
    this.sendCommand("com.flowmaps.api.organisation.CreateOrganisation", <CreateOrganisation>{
      organisationId: organisationId,
      info: this.data.organisation.info,
      owner: null
    }, () => AppCommonUtils.navigateToUrl(`/organisation/${organisationId}`));
  }
}

export interface AddOrganisationComponentData {
  organisation: Organisation;
}