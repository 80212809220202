import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    NgZone,
    Output,
    ViewChild
} from '@angular/core';
import {AppContext} from "../../../app-context";
import {filterByTermArray, lodash} from "../../../common/utils";
import {SourceInfo, SourcesProvider, TreeViewItem} from "../../../utils/source-providers/sources-provider";
import {OrganisationProvider} from "../../../utils/source-providers/organisation-provider";
import {MultiSelectComponent} from "../multi-select/multi-select.component";
import {EntityType} from "../../../handlers/entity";

@Component({
    selector: 'app-sources-selector',
    templateUrl: './sources-selector.component.html',
    styleUrls: ['./sources-selector.component.scss']
})
export class SourcesSelectorComponent implements AfterViewInit {
    appContext = AppContext;

    @ViewChild('sourceListDropdownMenu') sourceListDropdownMenu: ElementRef;
    @ViewChild('multiSelect') multiSelect: MultiSelectComponent;

    @Input() positionClass;

    dataProvider: SourcesProvider<any> = new OrganisationProvider();
    filterTerm: string = "";
    maxFilteredResults: number = 20;

    @Output() updated: EventEmitter<SourceInfo[]> = new EventEmitter<SourceInfo[]>();

    selectedItems: TreeViewItem[] = [];

    @Input()
    set sourceProvider(sourceProvider: SourcesProvider<any>) {
        this.dataProvider = sourceProvider;
        this.dataProvider.dataCollected.subscribe(d => this.updateSelectedItems());
        this.dataProvider.collectData();
    }

    constructor(private ngZone: NgZone, private changeDetector: ChangeDetectorRef) {
    }

    ngAfterViewInit() {
        $(this.multiSelect.dropdownToggle.nativeElement).on("show.bs.dropdown", () => {
            this.dataProvider.data.forEach(r => r.expanded = false);
            this.changeDetector.detectChanges();
        });

        $(this.multiSelect.dropdownToggle.nativeElement).on("hide.bs.dropdown", () => {
            this.filterTerm = "";
            this.changeDetector.detectChanges();
            setTimeout(() => this.changeDetector.detectChanges(), 100);
        });
    }

    updateDropdown = (term: string) => {
        if (term) {
            $(this.multiSelect.dropdownToggle.nativeElement).dropdown("show");
        }
    }

    selectionChanged() {
        this.ngZone.runOutsideAngular(() => {
            this.updateSelectedItems();
            this.updated.emit(this.getSelection());
        });
    }

    private updateSelectedItems() {
        this.selectedItems = this.dataProvider.getAllSelectedSourceIds()
            .map(s => this.dataProvider.flattenedData?.find(v => v.info.id === s))
            .filter(s => s);
    }

    filterSources = (term: string): (value: TreeViewItem) => boolean =>
        value => term ? filterByTermArray(
                [term], ["locations", "connections", "meters", "contracts", "timeZone", "filteredNameOverride"])(value.info) : true;

    private flatMapOrganisationData(data: TreeViewItem[]) {
        return lodash.flatMap(data, r => {
            const arr = SourcesProvider.flatMapSubItems(r);
            if (r.info.type === EntityType.organisation) {
                return arr.concat(r);
            }
            return arr;
        });
    }

    getSelection(): SourceInfo[] {
        return this.flatMapOrganisationData(this.selectedItems).map(s => s.info);
    }

    deleteSource(item: TreeViewItem | TreeViewItem[]) {
        this.ngZone.runOutsideAngular(() => {
            this.dataProvider.sourceSelectionDeleted(item);
            this.selectionChanged();
        });
    }

    filterTermChanged(term: string) {
        this.filterTerm = term;
        this.dataProvider.filterTermChanged(term);
        this.updateDropdown(term);
    }

    filterTermUpdated(data: TreeViewItem[]) {
        this.ngZone.runOutsideAngular(() => {
            this.dataProvider.updateFilteredData(this.filterTerm ? this.slicedData(data) : data);
        });
    }

    private slicedData(data: TreeViewItem[]) {
        return data.sort((a, b) => this.typeOrder(a.info.type) > this.typeOrder(b.info.type) ? 1 : -1)
            .slice(0, this.maxFilteredResults);
    }

    private typeOrder = (type: EntityType) => {
        switch(type) {
            case EntityType.organisation: return 1;
            case EntityType.location: return 2;
            case EntityType.connection: return 3;
            case EntityType.meter: return 4;
        }
    }
}
