import {SourceInfo, SourcesProvider, TreeViewItem} from "./sources-provider";
import {ConnectionType, Organisation, SourceIds} from "@flowmaps/flowmaps-typescriptmodels";
import {lodash} from "../../common/utils";
import {AppContext} from "../../app-context";
import {SourceType} from "../../views/dashboard/dashboard.context";
import {map, Observable} from 'rxjs';
import {subscribeTo} from "../../common/app-common-utils";
import {Entity, EntityType} from "../../handlers/entity";
import {RefdataUtils} from "../../views/refdata/refdata-utils";

export class OrganisationProvider extends SourcesProvider<Organisation> {

    constructor(private readonly organisationId?: string, private readonly initialSelection?: string[],
                private readonly sourceFilter? : ((item : SourceInfo) => boolean)) {
        super();
    }

    collectData() {
        let observable: Observable<Organisation[]> = subscribeTo("getOrganisations");
        if (this.organisationId) {
            observable = observable.pipe(map(orgs => orgs.filter(o => o.organisationId === this.organisationId)));
        }
        observable.subscribe(o => {
            this.setData(o);
            if (this.initialSelection && this.initialSelection.length > 0) {
                this.selectSources(this.initialSelection);
            }
            this.dataCollected.emit(o);
        });
    }

    setData(data: Organisation[]) {
        let mapped = this.treeViewItemMapper(data);
        if (this.sourceFilter) {
            mapped = mapped.filter(m => this.sourceFilter(m.info))
                .map(m => mapItem(m, this.sourceFilter));
        }
        this.hierarchyData = mapped;
        this.flattenedData = this.flatMapData(this.hierarchyData);
        this.data = this.getData();
        this.selectionUpdated.emit(this.getSelection());

        function mapItem(item : TreeViewItem, sourceFilter : ((item : SourceInfo) => boolean)) : TreeViewItem {
            item.subItems = (item.subItems || []).filter(i => sourceFilter(i.info))
                .map(i => mapItem(i, sourceFilter));
            return item;
        }
    }

    treeViewItemMapper(organisations: Organisation[]): TreeViewItem[] {
        if (this.organisationId) {
            const o = organisations[0];
            return lodash.chain(o.locations)
                .map(l => {
                    const locTreeItem = <TreeViewItem>{
                        icon: AppContext.getIconOfSourceType(SourceType.location),
                        checkbox: true,
                        visible: true,
                        info: OrganisationProvider.entityToSourceInfo(new Entity(o, l))
                    };

                    locTreeItem.subItems = lodash.chain(l.connections)
                        .map(c => (<TreeViewItem>{
                            icon: AppContext.getIconOfSourceType(SourceType.connection, c.info.connectionType as ConnectionType),
                            checkbox: true,
                            visible: true,
                            parent: locTreeItem,
                            info: OrganisationProvider.entityToSourceInfo(new Entity(o, l, c))
                        }))
                        .sort(this.treeViewComparator)
                        .value();

                    return locTreeItem;
                })
                .sort(this.treeViewComparator)
                .value();
        }
        return lodash.chain(organisations)
            .map(o => {
                const orgTreeItem = (<TreeViewItem>{
                    icon: AppContext.getIconOfSourceType(SourceType.organisation),
                    checkbox: true,
                    visible: true,
                    parent: null,
                    expanded: false,
                    info: OrganisationProvider.entityToSourceInfo(new Entity(o))
                });

                orgTreeItem.subItems = lodash.chain(o.locations)
                    .map(l => {
                        const locTreeItem = <TreeViewItem>{
                            icon: AppContext.getIconOfSourceType(SourceType.location),
                            checkbox: true,
                            visible: true,
                            parent: orgTreeItem,
                            info: OrganisationProvider.entityToSourceInfo(new Entity(o, l))
                        };

                        locTreeItem.subItems = lodash.chain(l.connections)
                            .map(c => {
                                const connectionItem = <TreeViewItem>{
                                    icon: AppContext.getIconOfSourceType(SourceType.connection, c.info.connectionType as ConnectionType),
                                    checkbox: true,
                                    visible: true,
                                    parent: locTreeItem,
                                    info: OrganisationProvider.entityToSourceInfo(new Entity(o, l, c))
                                };

                                connectionItem.subItems = lodash.chain(c.meters)
                                    .map(m => (<TreeViewItem>{
                                        icon: AppContext.getIconOfSourceType(SourceType.meter, null, m.info.type),
                                        checkbox: true,
                                        visible: false,
                                        parent: connectionItem,
                                        info: OrganisationProvider.entityToSourceInfo(new Entity(o, l, c, m))
                                    }))
                                    .sort(this.treeViewComparator)
                                    .value();

                                return connectionItem;
                            })
                            .sort(this.treeViewComparator)
                            .value();

                        return locTreeItem;
                    })
                    .sort(this.treeViewComparator)
                    .value();

                return orgTreeItem;
            })
            .sort(this.treeViewComparator)
            .value();
    }

    sourceSelectionAfterCleanup(): SourceIds {
        const sources = super.sourceSelectionAfterCleanup();
        if (this.organisationId && this.getAllSelectedSourceIds(sources).length === 0) {
            sources.organisationIds.push(this.organisationId);
        }
        return sources;
    }

    static entityToSourceInfo = (entity: Entity): SourceInfo => ({
        id: entity.getEntityId(),
        aliases: entity.location?.aliasIds || [],
        name: entity.getFormattedName(),
        source: entity,
        type: entity.getEntityType(),
        filteredNameOverride: entity.getEntityType() === EntityType.connection
            ? `${entity.connection.info.code || entity.connection.connectionId} – ${RefdataUtils.locationInfoFormatter(entity.location.info)}` : undefined,
        connectionType: entity.connection?.info.connectionType as ConnectionType,
        meterType: entity.getEntityType() === EntityType.meter ? entity.meter.details?.type || entity.meter.info.type : undefined
    })
}