import * as XLSX from 'xlsx-js-style';
import {CellAddress, CellObject, NumberFormat, WorkBook, WorkSheet, WritingOptions} from 'xlsx-js-style';
import moment from 'moment';
import lodash from 'lodash';
import {combineLatest, Observable, of, Subscriber} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {scrollToTop} from "../utils";
import {ElementRef} from "@angular/core";
import {AppCommonUtils} from "../app-common-utils";

const defaultOpts: WritingOptions = {
  compression: true
}

export function exportTableToExcel(tableElement: ElementRef, fileName: string = "export.xlsx") {
  const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(tableElement.nativeElement);
  const wb: XLSX.WorkBook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'Data');
  XLSX.writeFile(wb, fileName, defaultOpts);
}

export function exportDataAsExcel(data: any[][], fileName: string = "export.xlsx") {
  const workBook = XLSX.utils.book_new();
  const workSheet = XLSX.utils.aoa_to_sheet(data);
  XLSX.utils.book_append_sheet(workBook, workSheet);
  XLSX.writeFile(workBook, fileName, defaultOpts);
}

export function exportExcel(url : string, template: WorkBookTemplate, data: any, fileName: string = "export.xlsx"): void {
  downloadWorkbook(url).subscribe(workBook => {
    template.sheets.forEach(template => {
      const sheet = workBook.Sheets[template.name];
      setSheetDataRange(sheet, template.template, getRecordRowCountForSheet(template.template, data));
      exportAny(template.template, data, sheet, name => name);
    });
    XLSX.writeFile(workBook, fileName, lodash.merge({cellStyles: true}, defaultOpts));
  });
}

export function exportExcelFromWorkBook($workBook : Observable<WorkBook>, template: WorkBookTemplate, data: any, fileName: string = "export.xlsx"): void {
  $workBook.subscribe(workBook => {
    template.sheets.forEach(sheetTemplate => {
      const sheet = workBook.Sheets[sheetTemplate.name];
      setSheetDataRange(sheet, sheetTemplate.template, getRecordRowCountForSheet(sheetTemplate.template, data));
      exportAny(sheetTemplate.template, data, sheet, name => name);
    });
    XLSX.writeFile(workBook, fileName, defaultOpts);
  });
}

export function writeExcelFromWorkBook($workBook : Observable<WorkBook>, template: WorkBookTemplate, data: any, options: WritingOptions): Observable<any> {
  return $workBook.pipe(map(workBook => {
    template.sheets.forEach(sheetTemplate => {
      const sheet = workBook.Sheets[sheetTemplate.name];
      setSheetDataRange(sheet, sheetTemplate.template, getRecordRowCountForSheet(sheetTemplate.template, data));
      exportAny(sheetTemplate.template, data, sheet, name => name);
    });
    return XLSX.write(workBook, options);
  }));
}


function setSheetDataRange(sheet: WorkSheet, template: any, records: number) {
  const highestColumn = Object.keys(sheet).filter(s => !s.startsWith("!"));
  sheet["!ref"] = sheet["!ref"].replace(/:([^:]+)$/, ':' + highestColumn[highestColumn.length-1])
  sheet["!ref"] = (sheet["!ref"].match(/^(.*[A-Z])/) || [''])[0] + (records + 1);
}

export function getRecordRowCountForSheet(template: any, data: any): number {
  if (template instanceof ColumnTemplate) {
    const columnTemplate = template as ColumnTemplate;
    return getRecordRowCountForSheet(template["template"], columnTemplate.dataKey ? data[columnTemplate.dataKey] : data);
  }
  return 1 + Object.keys(template).map(key => data && data[key]?.length || 0).reduce((a, b) => a > b ? a : b, 0);
}

function exportAny(template: any, data, sheet: WorkSheet, cellNameFunction: (value: string) => string, options?: FieldOptions) {
  if (data === null || data === undefined) {
    if (template instanceof Field) {
      template.export(data, sheet, cellNameFunction);
    }
    return;
  }
  if (Array.isArray(template)) {
    exportArray(template, data, sheet, cellNameFunction);
  } else if (template instanceof Field) {
    template.export(data, sheet, cellNameFunction);
  } else if (typeof template === 'string') {
    exportCell(template, data, sheet, cellNameFunction, options);
  } else {
    exportObject(template, data, sheet, cellNameFunction);
  }

  function exportArray(template: any[], data, sheet: WorkSheet, cellNameFunction: (value: string) => string, options?: FieldOptions) {
    template.forEach(t => exportAny(t, data, sheet, cellNameFunction, options));
  }

  function exportObject(template: any, data, sheet: WorkSheet, cellNameFunction: (value: string) => string, options?: FieldOptions) {
    Object.keys(template).forEach(key => {
      const templateValue = template[key];
      const dataSelection = data && data[key];
      exportAny(templateValue, dataSelection, sheet, cellNameFunction, options);
    })
  }

  function exportCell(cellName: string, data, sheet: WorkSheet, cellNameFunction: (value: string) => string, options?: FieldOptions) {
    cellName = cellNameFunction(cellName);
    let value: CellObject = <any>{v: data};
    if (options?.numberFormat) {
      value.z = options.numberFormat;
    }
    if (options?.typeOverride) {
      value.t = options.typeOverride;
      switch (value.t) {
        case "s": value.z = "@"; break;
        case "d":value.z = 'DD-MM-YYYY hh:mm:ss'; break;
      }
    } else {
      switch (typeof data) {
        case 'string': value.t = 's'; value.z = '@'; break;
        case 'number': value.t = 'n'; break;
        case 'boolean': value.t = 's'; value.v = data ? 'Yes' : 'No'; break;
      }
    }
    sheet[cellName] = value;
  }
}

export function parseExcel(file: File, template: WorkBookTemplate): Observable<any> {

  return new Observable((subscriber: Subscriber<any>) => {
    getWorkbook(file).subscribe(workBook => {
      let result = {};
      const errors = [];
      const observables: Observable<any>[] = [];

      if (!workBook) {
        errors.push(new Error('Could not parse Excel file: ' + file && file.name));
      } else {
        template.sheets.forEach(sheet => {
          if (workBook.SheetNames.indexOf(sheet.name) < 0) {
            errors.push(new Error('Sheet not found: ' + sheet.name));
            return;
          }
          const parser = new Parser(workBook, sheet.name);
          const model = parser.mapAny(sheet.template, cellName => cellName);
          let onComplete: Observable<any> = parser.observables.length > 0 ? combineLatest(parser.observables) : of(null);
          onComplete = onComplete.pipe(tap(() => {
            result = lodash.mergeWith(result, model, (objValue, srcValue) => {
              if (lodash.isArray(objValue)) {
                return objValue.concat(srcValue);
              }
            });
            parser.errors.forEach(e => errors.push(e));
          }));
          observables.push(onComplete);
        });
      }
      const onComplete: Observable<any> = observables.length > 0 ? combineLatest(observables) : of(null);
      onComplete.subscribe({
        next: () => {
          if (errors.length > 0) {
            const filteredErrors = Array.from(new Set(errors));
            filteredErrors.forEach(e => AppCommonUtils.registerError(e));
            subscriber.error(filteredErrors);
            scrollToTop();
          } else {
            subscriber.next(result);
          }
        },
        complete: () => subscriber.complete()
      });
    });
  });
}

export function downloadWorkbook(url: string): Observable<WorkBook> {
  const reader: FileReader = new FileReader();
  return new Observable((s: Subscriber<WorkBook>) => {
    const request = new XMLHttpRequest();
    request.open('GET', url, true);
    request.responseType = 'blob';
    request.onload = e => {
      reader.readAsArrayBuffer(request.response);
    };
    request.send();

    reader.onload = (e: any) => {
      let binary = '';
      const bytes = new Uint8Array(e.target.result);
      const length = bytes.byteLength;
      for (let i = 0; i < length; i++) {
        binary += String.fromCharCode(bytes[i]);
      }
      let workBook: WorkBook;
      try {
        workBook = XLSX.read(binary, {type: 'binary', cellDates: true, cellStyles: true});
      } catch (e) {
        AppCommonUtils.registerError(e);
        return;
      }
      s.next(workBook);
      s.complete();
    };
  });
}

export function getWorkbook(file: File): Observable<WorkBook> {
  const reader: FileReader = new FileReader();
  reader.readAsArrayBuffer(file);
  return new Observable((s: Subscriber<WorkBook>) => {
    reader.onload = (e: any) => {
      let binary = '';
      const bytes = new Uint8Array(e.target.result);
      const length = bytes.byteLength;
      for (let i = 0; i < length; i++) {
        binary += String.fromCharCode(bytes[i]);
      }
      let workBook: WorkBook;
      try {
        workBook = XLSX.read(binary, {type: 'binary', cellDates: true});
      } catch (e) {
        AppCommonUtils.registerError(e);
        return;
      }
      s.next(workBook);
      s.complete();
    };
  });
}

export function toBase64(file: File): Observable<String> {
  const reader: FileReader = new FileReader();
  reader.readAsArrayBuffer(file);
  return new Observable((s: Subscriber<String>) => {
    reader.onload = (e: any) => {
      let binary = '';
      const bytes = new Uint8Array(e.target.result);
      const length = bytes.byteLength;
      for (let i = 0; i < length; i++) {
        binary += String.fromCharCode(bytes[i]);
      }
      s.next(btoa(binary));
      s.complete();
    };
  });
}

export interface WorkBookTemplate {
  sheets: SheetTemplate[];
}

export interface SheetTemplate {
  name: string;
  dataKey?: string;
  template: any;
}

export abstract class Field<T> {
  protected template: any;
  protected options: FieldOptions;

  protected constructor(template: any, options?: FieldOptions) {
    this.template = template;
    this.options = options;
  }

  abstract getValue(parser: Parser, cellNameFunction): T;

  abstract isEmpty(parser: Parser, cellNameFunction);

  export(data: any, sheet: WorkSheet, cellNameFunction: (value: string) => string) {
    if (this.template) {
      exportAny(this.template, data, sheet, cellNameFunction, this.options);
    }
  }

  static cellOrNull(possibleCell: any) {
    return possibleCell instanceof Cell ? possibleCell : null;
  }

  static getCellValue(possibleCell: any) {
    return possibleCell instanceof Cell ? possibleCell.value : possibleCell;
  }
}

export class ArrayTemplate extends Field<any[]> {
  ranges: [number, number][];

  constructor(private pattern: any, ...ranges: [number, number][]) {
    super(pattern);
    this.ranges = ranges;
  }

  getValue(parser: Parser, cellNameFunction): any[] {
    let newArray = [];
    this.ranges.forEach(range => {
      for (let i = range[0]; i <= range[1]; i++) {
        if (parser.isEmpty(this.template, cellName => cellNameFunction(cellName).replace('$', String(i)))) {
          break;
        }
        let field = parser.mapAny(this.template, cellName => cellNameFunction(cellName).replace('$', String(i)));
        if (field instanceof Cell) {
          field = field.value;
        }
        const index = newArray.push(field) - 1;
        if (field instanceof Observable) {
          field = field.pipe(catchError(e => {
            parser.registerError(e);
            return of(null);
          }));
          field = field.pipe(map(r => newArray[index] = r));
          parser.registerObservable(field);
        }
      }
    });
    return newArray;
  }

  isEmpty(parser: Parser, cellNameFunction) {
    for (const range of this.ranges) {
      for (let i = range[0]; i <= range[1]; i++) {
        if (isEmptyRow(i, parser.workSheet)) {
          return true;
        }
        if (!parser.isEmpty(this.template, cellName => cellNameFunction(cellName).replace('$', String(i)))) {
          return false;
        }
      }
    }
    return true;
  }

  export(data: any, sheet: WorkSheet, cellNameFunction: (value: string) => string) {
    for (const range of this.ranges) {
      const dataRows : any[] = data;
      let j = 0;
      for (let i = range[0]; i <= range[1]; i++) {
        if (j >= dataRows.length) {
          break;
        }
        super.export(data[j], sheet, cellName => cellNameFunction(cellName).replace('$', String(i)));
        j++;
      }
    }
  }
}

export class ColumnTemplate extends Field<any[]> {
  dataKey: string;

  constructor(private pattern: any, dataKey?: string) {
    super(pattern);
    this.dataKey = dataKey;
  }

  getValue(parser: Parser, cellNameFunction): any[] {
    return [];
  }

  isEmpty(parser: Parser, cellNameFunction) {
    return true;
  }

  export(data: any, sheet: WorkSheet, cellNameFunction: (value: string) => string) {
    Object.values(this.template).forEach(t => exportAny(t, this.dataKey ? data[this.dataKey] : data, sheet, cellNameFunction, this.options));
  }
}

export class DynamicField extends Field<any> {
  field: any;
  headerName: string;

  constructor(template: any, headerName: string) {
    super(template);
    this.field = template;
    this.headerName = headerName;
  }

  getValue(parser: Parser, cellNameFunction): any {
    return parser.mapAny(this.field, cellNameFunction);
  }

  isEmpty(parser: Parser, cellNameFunction) {
    return parser.isEmpty(this.field, cellNameFunction);
  }

  export(data: any, sheet: WorkSheet, cellNameFunction: (value: string) => string) {
    if (this.template) {
      exportAny(this.template, data, sheet, cellNameFunction, this.options);
    }
  }
}

export class DateField extends Field<Cell> {
  constructor(protected field: any, private format = 'MM-DD-YYYY', private writeFormat = "YYYY-MM-DD") {
    super(field, {
      typeOverride: "d"
    });
  }

  getValue(parser: Parser, cellNameFunction): Cell {
    let cell: Cell = parser.mapAny(this.field, cellNameFunction);
    if (!(cell instanceof Cell)) {
      parser.registerError(new Error('Unexpected error while parsing a date field in an Excel sheet. Please contact support.'));
    }
    if (cell.value != null && String(cell.value).trim().length > 0) {
      let mom = moment(cell.value, this.format);
      if (mom.isValid()) {
        cell.value = mom.format(this.writeFormat);
      } else {
        parser.registerError(new Error('Cell ' + cell.cell + ' in sheet "' + cell.sheetName + '\" contains an invalid date. The format should be DD-MM-YYYY.'));
      }
    }
    return cell;
  }

  export(data: any, sheet: WorkSheet, cellNameFunction: (value: string) => string) {
    super.export(data && moment(data).format(this.format), sheet, cellNameFunction);
  }

  isEmpty(parser: Parser, cellNameFunction) {
    return parser.isEmpty(this.field, cellNameFunction);
  }

}

export class DateTimeField extends DateField {
  constructor(protected field: any) {
    super(field, 'MM-DD-YYYY HH:mm:ss', 'YYYY-MM-DDTHH:mm:ss');
  }
}

export class MappedField extends Field<any> {
  constructor(private field: any, private mapper: (fieldValue, cell?: Cell, parser?: Parser) => any, private exporter?: (mappedValue) => any) {
    super(field);
  }

  getValue(parser: Parser, cellNameFunction): any {
    let value = parser.mapAny(this.field, cellNameFunction);
    let mappedValue;
    const cell: Cell = Field.cellOrNull(value);
    try {
      mappedValue = this.mapper(Field.getCellValue(value), cell, parser);
      if (cell) {
        cell.value = mappedValue;
      } else {
        value = mappedValue;
      }
    } catch (e) {
      parser.registerError(cell ? 'Cell ' + cell.cell + ' in sheet "' + cell.sheetName + '\" could not be mapped: ' + parseError(e) : e);
    }
    return value;
  }

  isEmpty(parser: Parser, cellNameFunction) {
    return parser.isEmpty(this.field, cellNameFunction);
  }

  export(data: any, sheet: WorkSheet, cellNameFunction: (value: string) => string) {
    super.export(this.exporter ? this.exporter(data) : data, sheet, cellNameFunction);
  }
}

export class RequiredField extends Field<any> {
  field: any;

  constructor(field: any) {
    super(field);
    this.field = field;
  }

  getValue(parser: Parser, cellNameFunction): any {
    let value = parser.mapAny(this.field, cellNameFunction);
    if (Field.getCellValue(value) === undefined) {
      const cell = Field.cellOrNull(value);
      parser.registerError(cell ? 'Please fill out cell ' + cell.cell + ' in sheet "' + cell.sheetName + '\".'
          : 'A required object is missing. Please fill out ' + JSON.stringify(this.field));
    }
    return value;
  }

  isEmpty(parser: Parser, cellNameFunction) {
    return parser.isEmpty(this.field, cellNameFunction);
  }
}

export class HardCodedField extends Field<any> {
  value: any;
  protected readonly isFunction : boolean;

  constructor(value: (any | (() => any)), field?: any) {
    super(field);
    this.value = value;
    this.isFunction = typeof value === "function";
  }

  getValue(parser: Parser, cellNameFunction): any {
    return this.isFunction ? this.value() : this.value;
  }

  isEmpty(parser: Parser, cellNameFunction) {
    return true;
  }
}


export class ArrayField extends HardCodedField {
  constructor(private field: any, private startIndex: number = 1, value: (any | ((data) => any))) {
    super(value, field);
  }

  isEmpty(parser: Parser, cellNameFunction) {
    return parser.isEmpty(this.field, cellNameFunction);
  }

  export(data: any, sheet: WorkSheet, cellNameFunction: (value: string) => string) {
    const value = this.isFunction ? this.value(data) : this.value;
    (value || []).forEach((v, i) => {
      super.export(v, sheet, cellName => cellName.replace('$', String(this.startIndex + i)));
    });
  }
}

export class ValidatedField extends Field<any> {

  constructor(private field: any, private validator: (value: any, cell: Cell, parser: Parser, cellNameFunction: any) => void) {
    super(field);
  }

  getValue(parser: Parser, cellNameFunction): any {
    let value = parser.mapAny(this.field, cellNameFunction);
    try {
      this.validator(Field.getCellValue(value), Field.cellOrNull(value), parser, cellNameFunction);
    } catch (e) {
      const cell = Field.cellOrNull(value);
      parser.registerError('Error for cell ' + cell.cell + ' in sheet "' + cell.sheetName + '\". ' + e);
    }
    return value;
  }

  export(data: any, sheet: WorkSheet, cellNameFunction: (value: string) => string) {
    if (this.template) {
      exportAny(this.template, data, sheet, cellNameFunction, this.options);
    }
  }

  isEmpty(parser: Parser, cellNameFunction) {
    return parser.isEmpty(this.field, cellNameFunction);
  }
}

export class RequiredIfField extends Field<any> {
  constructor(private field: any, private dependentFields: string[]) {
    super(field);
  }

  getValue(parser: Parser, cellNameFunction): any {
    let valueOthers = this.dependentFieldsAreFilled(parser, cellNameFunction);
    let value = parser.mapAny(this.field, cellNameFunction);
    if (valueOthers && parser.isEmpty(this.field, cellNameFunction)) {
      const cell = Field.cellOrNull(value);
      parser.registerError(cell ? 'Please fill out cell ' + cell.cell + ' in sheet "' + cell.sheetName + '\".'
          : 'A conditional required object is missing. Please fill out ' + JSON.stringify(this.field));
    }
    return value;
  }

  private dependentFieldsAreFilled(parser: Parser, cellNameFunction) : boolean {
    let valueOthers = false;
    this.dependentFields.forEach(value1 => {
      if (!parser.isEmpty(value1, cellNameFunction)) {
        valueOthers = true;
      }
    });
    return valueOthers;
  }

  isEmpty(parser: Parser, cellNameFunction) {
    return parser.isEmpty(this.field, cellNameFunction);
  }
}

export class QuantityField extends Field<number> {
  constructor(private field: any, private defaultValue: number = null, numberFormat? : NumberFormat) {
    super(field, {
      numberFormat: numberFormat
    });
  }

  export(data: any, sheet: WorkSheet, cellNameFunction: (value: string) => string) {
    if (this.template) {
      if (data === undefined || data === null) {
        data = this.defaultValue;
      }
      exportAny(this.template, data, sheet, cellNameFunction, this.options);
    }
  }

  getValue(parser: Parser, cellNameFunction): any {
    const cell : Cell = parser.mapAny(this.field, cellNameFunction);
    if (cell.value === undefined || cell.value === null || cell.value === "") {
      cell.value = this.defaultValue;
    } else if (!lodash.isNumber(cell.value)) {
      parser.registerError('Please fill out cell ' + cell.cell + ' of sheet "' + cell.sheetName + '\" with a valid number.')
    }
    return cell;
  }

  isEmpty(parser: Parser, cellNameFunction) {
    return parser.isEmpty(this.field, cellNameFunction);
  }
}

export class NonNegativeQuantityField extends Field<number> {
  constructor(private field: any, private defaultValue: number = null, numberFormat? : NumberFormat) {
    super(field, {
      numberFormat: numberFormat
    });
  }

  export(data: any, sheet: WorkSheet, cellNameFunction: (value: string) => string) {
    if (this.template) {
      exportAny(this.template, data, sheet, cellNameFunction, this.options);
    }
  }

  getValue(parser: Parser, cellNameFunction): any {
    const cell : Cell = parser.mapAny(this.field, cellNameFunction);
    if (cell.value == null || cell.value == "") {
      cell.value = this.defaultValue;
    } else if (!lodash.isNumber(cell.value) || cell.value < 0) {
      parser.registerError('Please fill out cell ' + cell.cell + ' of sheet "' + cell.sheetName + '\" with a valid number.')
    }
    return cell;
  }

  isEmpty(parser: Parser, cellNameFunction) {
    return parser.isEmpty(this.field, cellNameFunction);
  }
}

export class Cell {
  value: any;
  cell: any;
  sheetName: any;
  address: CellAddress;

  constructor(value: any, cell: any, sheetName: any, address: CellAddress) {
    this.value = value;
    this.cell = cell;
    this.sheetName = sheetName;
    this.address = address;
  }
}

export class Parser {
  sheetName: string;
  workSheet: WorkSheet;
  errors: Error[] = [];
  observables: Observable<any>[] = [];

  constructor(workBook: WorkBook, sheetName: string) {
    this.sheetName = sheetName;
    this.workSheet = workBook.Sheets[sheetName];
  }

  mapAny = (value: any, cellNameFunction) => {
    switch (typeof value) {
      case 'string' :
        const cellName = cellNameFunction(value);
        const cell = this.workSheet[cellName];
        const address = XLSX.utils.decode_cell(cellName);
        return new Cell(cell && (cell.t === 's' ? cell.v.trim() : cell.v), cellName, this.sheetName, address);
      default :
        return Array.isArray(value) ? this.mapArray(value, cellNameFunction)
            : value instanceof Field ? value.getValue(this, cellNameFunction)
                : this.mapObject(value, cellNameFunction);
    }
  };

  private mapArray(array: any[], cellNameFunction) {
    let newArray = [];
    for (let i = 0; i < array.length; i++) {
      let field = this.mapAny(array[i], cellNameFunction);
      if (notNull(field)) {
        if (field instanceof Cell) {
          field = field.value;
        }
        const index = newArray.push(field) - 1;
        if (field instanceof Observable) {
          field = field.pipe(catchError(e => {
            this.registerError(e);
            return of(null);
          }));
          field = field.pipe(map(r => newArray[index] = r));
          this.registerObservable(field);
        }
      }
    }
    return newArray;
  }

  private mapObject(object: any, cellNameFunction): any {
    const result = {};
    let emptyObject = true;
    Object.keys(object).forEach(key => {
      let field = this.mapAny(object[key], cellNameFunction);
      if (field instanceof Cell) {
        field = field.value;
      }
      if (isNonEmpty(field)) {
        emptyObject = false;
      }
      result[key] = field;
      if (field instanceof Observable) {
        field = field.pipe(catchError(e => {
          this.registerError(e);
          return of(null);
        }));
        field = field.pipe(map(r => result[key] = r));
        this.registerObservable(field);
      }
    });
    return emptyObject ? null : result;

    function isNonEmpty(field) {
      if (field === 0 || Array.isArray(field)) {
        return true;
      }
      return !field ? false : typeof field === 'object' ? !lodash.isEmpty(field) : true;
    }
  }

  isEmpty = (value: any, cellNameFunction) => {
    switch (typeof value) {
      case 'string' :
        const cellName = cellNameFunction(value);
        const cellValue = this.workSheet[cellName];
        return !cellValue || (cellValue.t === 's' && !cellValue.v.trim());
      default :
        return Array.isArray(value) ? this.isEmptyArray(value, cellNameFunction)
            : value instanceof Field ? value.isEmpty(this, cellNameFunction)
                : this.isEmptyObject(value, cellNameFunction);
    }
  };

  private isEmptyArray(array: any[], cellNameFunction) {
    for (let i = 0; i < array.length; i++) {
      if (!this.isEmpty(array[i], cellNameFunction)) {
        return false;
      }
    }
    return true;
  }

  private isEmptyObject(object: any, cellNameFunction) {
    for (const key of Object.keys(object)) {
      if (!this.isEmpty(object[key], cellNameFunction)) {
        return false;
      }
    }
    return true;
  }

  registerError(error: any): void {
    this.errors.push(error);
  }

  registerObservable(observable: Observable<any>): void {
    this.observables.push(observable);
  }
}

export interface FieldOptions {
  numberFormat?: NumberFormat;
  typeOverride?: XLSX.ExcelDataType;
}

function parseError(e: any) {
  return e instanceof Error ? e.message : typeof e === 'string' ? e : JSON.stringify(e);
}

function notNull<TValue>(value: TValue | null | undefined): value is TValue {
  return value !== null && value !== undefined;
}

function isEmptyRow(row: number, workSheet: WorkSheet) {
  const regExp = new RegExp('^[A-Z]+' + row + '$');
  let filledFields = Object.keys(workSheet).filter(name => regExp.test(name));
  return filledFields.length === 0;
}
