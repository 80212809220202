import {Component, inject, OnInit,} from '@angular/core';
import {Observable, of, switchMap} from "rxjs";
import {catchError} from "rxjs/operators";
import {Handler} from "../../../common/handler";
import {View} from "../../../common/view";
import {subscribeTo} from "../../../common/app-common-utils";
import {Error, ErrorEntry, GetError, GetErrorEntries} from "@flowmaps/flowmaps-typescriptmodels";
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-issue-navigator',
  templateUrl: './issue-navigator.component.html',
  styleUrls: ['./issue-navigator.component.scss']
})
@Handler()
export class IssueNavigatorComponent extends View implements OnInit {
  private route: ActivatedRoute = inject(ActivatedRoute);

  data: IssueNavigatorComponentData;
  issue: Error;
  issueId: string;

  entries: ErrorEntry[];
  index: number = 0;

  ngOnInit(): void {
    this.issueId = this.data?.issueId || this.route.snapshot.paramMap.get('issueId');
    this.fetchIssueEntries().subscribe(() => this.fetchError());
  }

  fetchIssueEntries(): Observable<any> {
    if (this.issueId) {
      return subscribeTo('com.flowmaps.api.monitoring.errors.GetErrorEntriesNew', <GetErrorEntries> {
        errorId: this.issueId,
        size: 100
      }, { caching: false }).pipe(
        switchMap((newEntries: ErrorEntry[]) => {
          if (this.entries) {
            const existingIds = new Set(this.entries.map(entry => entry.sequence));
            newEntries.forEach(entry => {
              if (!existingIds.has(entry.sequence)) {
                this.entries.push(entry);
              }
            });
          } else {
            this.entries = newEntries;
          }
          this.entries.sort((a, b) => (a.sequence > b.sequence ? -1 : 1));
          return of([]);
        }),
        catchError(() => of([]))
      );
    } else {
      return of([]);
    }
  }

  nextEntry() {
    if (this.entries?.length) {
      this.index = (this.index - 1 + this.entries.length) % this.entries.length;
    }
  }

  prevEntry() {
    if (this.entries?.length) {
      this.index = (this.index + 1) % this.entries.length;
    }
  }

  hasNextEntry(): boolean {
    return this.index > 0;
  }

  hasPrevEntry(): boolean {
    return this.index < this.entries.length - 1;
  }

  goToBeginning(): void {
    this.fetchIssueEntries().subscribe(() => this.index = this.entries.length - 1);
  }

  goToEnd(): void {
    this.fetchIssueEntries().subscribe(() => this.index = 0);
  }

  fetchError() {
    if (this.issueId) {
      this.subscribeTo("com.flowmaps.api.monitoring.errors.GetError", <GetError>{
        errorId: this.issueId
      }).subscribe((issue: Error) => this.issue = issue);
    }
  }
}

export interface IssueNavigatorComponentData {
  issueId: string;
}
