import {Component, OnInit} from '@angular/core';
import {View} from "src/app/common/view";
import {Handler} from "src/app/common/handler";
import {
    Authorisation,
    AuthorisationType,
    GiveAuthorisation,
    Organisation,
    RevokeAuthorisation,
    TimeRange
} from "@flowmaps/flowmaps-typescriptmodels";
import {uuid} from "../../../../common/utils";
import {Entity} from "../../../../handlers/entity";
import {AppContext} from "../../../../app-context";
import moment from "moment";
import {RefdataUtils} from "../../refdata-utils";
import {openConfirmationModalWithCallback} from "../../../../app-utils";
import {openModal} from "../../../../common/app-common-utils";
import {
    ModalConfirmAutofocus,
    ModalConfirmAutofocusData
} from "../../../../common/modal-confirm/modal-confirm.component";
import {SourceInfo, SourcesProvider} from "../../../../utils/source-providers/sources-provider";
import {OrganisationProvider} from "../../../../utils/source-providers/organisation-provider";

@Component({
  selector: 'app-authorisation-details',
  templateUrl: './authorisation-details.component.html',
  styleUrls: ['./authorisation-details.component.scss']
})
@Handler()
export class AuthorisationDetailsComponent extends View implements OnInit {
  refDataUtils = RefdataUtils;
  data: Authorisation;
  isNewRecord: boolean;
  showNominatorField: boolean;
  nominatorOrganisation: Organisation;
  nomineeOrganisation: Organisation;
  sourceProvider: SourcesProvider<any>;

  ngOnInit() {
    this.isNewRecord = !this.data?.authorisationId;
    if (this.isNewRecord) {
      this.data.authorisationId = uuid();
      this.showNominatorField = !this.data.nominator;
    } else {
      this.showNominatorField = this.data["showNominator"];
    }
    this.data.entityIds = this.data.entityIds || [];
    this.data.timeRange = this.data.timeRange || {};
    if (this.data.nominator) {
      this.subscribeTo("getEntity", this.data.nominator).subscribe((e: Entity) => this.nominatorOrganisation = e.organisation);
    }
    if (this.data.nominee && this.data.type === AuthorisationType.organisation) {
      this.subscribeTo("getEntity", this.data.nominee).subscribe((e: Entity) => this.nomineeOrganisation = e.organisation);
    }
    this.sourceProvider = new OrganisationProvider(this.data.nominator,
        this.data.entityIds.length ? this.data.entityIds : []);
  }

  searchOrganisations = (term: string) => this.sendQuery("searchOrganisations", term);

  getMinStartDate = (): string => this.data.timeRange?.start ? this.data.timeRange?.start : AppContext.today();

  authorisationHeader = (): string => this.data.type === AuthorisationType.organisation ? RefdataUtils.organisationFormatter(this.nomineeOrganisation) : this.data.nominee

  setNominee(organisation: Organisation) {
    this.nomineeOrganisation = organisation;
    this.data.nominee = organisation?.organisationId;
  }

  setNominator(organisation: Organisation) {
    this.nominatorOrganisation = organisation;
    this.data.nominator = organisation?.organisationId;
    this.sourceProvider = new OrganisationProvider(this.data.nominator,
        this.data.entityIds.length ? this.data.entityIds : []);
  }

  updateEntityIds(sources: SourceInfo[]) {
    const sourceIds = this.sourceProvider.sourceSelectionAfterCleanup();
    this.data.entityIds = sourceIds.locationIds.concat(sourceIds.connectionIds).concat(sourceIds.meterIds);
  }

  updateInfo = () => {
    this.data.timeRange = asTimeRange(this.data.timeRange);
    const command: GiveAuthorisation = {
      organisationId: this.data.nominator,
      authorisation: this.data
    }
    this.sendCommand("com.flowmaps.api.organisation.GiveAuthorisation", command);

    function asTimeRange(dateRange: TimeRange): TimeRange {
      if (dateRange?.start || dateRange?.end) {
        return {
          start: dateRange.start && moment(dateRange.start).startOf('day').toISOString(),
          end: dateRange.end && moment(dateRange.end).endOf('day').toISOString(),
        }
      }
      return null;
    }
  }

  deleteAuthorisation = () => {
    openConfirmationModalWithCallback(confirmed => {
      if (confirmed) {
        const command = <RevokeAuthorisation>{
          authorisationId: this.data.authorisationId,
          organisationId: this.data.nominator
        };
        this.sendCommand("com.flowmaps.api.organisation.RevokeAuthorisation", command);
      } else {
        openModal(AuthorisationDetailsComponent, this.data);
      }
    }, ModalConfirmAutofocus, <ModalConfirmAutofocusData> {
      type: "danger",
      title: "Delete authorisation",
      innerHtmlMessage: `<p>Are you sure you want to delete this authorisation?</p>`,
      confirmText: "Delete authorisation",
      cancelText: "Cancel"
    }, 'static');
  };
}
