import {Component, Input, OnInit} from '@angular/core';
import {View} from "src/app/common/view";
import {Handler} from "src/app/common/handler";
import {DashboardContext} from "../../../../dashboard/dashboard.context";
import {AppContext} from "../../../../../app-context";
import {ContractPeriod, DeleteContractPeriod} from "@flowmaps/flowmaps-typescriptmodels";
import {ContractEntity} from "../../../../../handlers/app-entity-handler.service";
import {
    ContractPeriodComponentData,
    ContractPeriodDetailsComponent
} from "../../contract-period-details/contract-period-details.component";
import {cloneDeep} from "lodash";

@Component({
  selector: 'app-contract-period-overview-item',
  templateUrl: './contract-period-overview-item.component.html',
  styleUrls: ['./contract-period-overview-item.component.scss']
})
@Handler()
export class ContractPeriodOverviewItemComponent extends View implements OnInit {
    dashboardContext = DashboardContext;
    appContext = AppContext;
    @Input() period: ContractPeriod;
    contract: ContractEntity;
    costUnit = "€";
    standingChargeUnit = "p.m.";

    ngOnInit() {
        this.subscribeTo("getContract").subscribe(c => this.contract = c);
    }

    deleteContractPeriod = () => {
        this.sendCommand("com.flowmaps.api.organisation.DeleteContractPeriod", <DeleteContractPeriod> {
            periodId: this.period.periodId,
            contractId: this.contract.contract.contractId,
            organisationId: this.contract.organisation.organisationId
        });
    }

    openContractPeriodModal(): void {
        this.openModal(ContractPeriodDetailsComponent, cloneDeep(<ContractPeriodComponentData>{
            period: this.period,
            contract: this.contract,
            source: this.period.data.source,
            supplier: this.period.data.supplier
        }));
    }
}
