import {Component, forwardRef} from '@angular/core';
import {BaseMeasurementChartComponent} from "../base-measurement-chart";
import {ConnectionType, DataType} from "@flowmaps/flowmaps-typescriptmodels";

@Component({
  selector: 'app-water-chart',
  templateUrl: './water-chart.component.html',
  styleUrls: ['./water-chart.component.scss'],
  providers: [{provide: BaseMeasurementChartComponent, useExisting: forwardRef(() => WaterChartComponent)}]
})
export class WaterChartComponent extends BaseMeasurementChartComponent {
  contractedCapacityRequiredForPower = true;

  showDataNormally = (): boolean => true;

  measurementTypes = (): DataType[] => this.options.groupByEntityId ? [DataType.waterConsumption, DataType.waterIntermediateConsumption] : [DataType.waterConsumption];

  productionDataTypes = (): DataType[] => [];

  powerDataType = (): DataType => DataType.waterPower;

  consumptionProductionLink = (): Map<DataType, DataType> => new Map<DataType, DataType>();
  measurementIntermediateLink = (): Map<DataType, DataType[]> => {
    return new Map<DataType, DataType[]>()
        .set(DataType.waterConsumption, [DataType.waterIntermediateConsumption])
  };

  connectionType = (): ConnectionType => ConnectionType.Water;

  openModal = () => this.openModalWithType(WaterChartComponent);

  measurementUnit = () => this.context.getMeasurementUnit(DataType.waterConsumption);
}
