import {Component, ElementRef, inject, NgZone, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {DashboardContext} from "./dashboard.context";
import {mergeMap, Observable, of, takeUntil} from "rxjs";
import {
    ContractMeasurementResult,
    Dashboard,
    DeleteDashboard,
    GetContractMeasurements,
    Location,
    SaveDashboard,
    TimeRange,
    TimeResolution
} from "@flowmaps/flowmaps-typescriptmodels";
import {cloneObject, removeIf, uuid} from "../../common/utils";
import {AppContext} from "../../app-context";
import {ActivatedRoute, Router} from "@angular/router";
import {SourceInfo, TreeViewItem} from "../../utils/source-providers/sources-provider";
import {ChartUtilsService} from "../charts/chart-utils.service";
import {PortfolioMeasurementsDataProvider} from "../../utils/portfolio-measurements-data-provider";
import {DayOfWeekMeasurementsDataProvider} from "../../utils/day-of-week-measurements-data-provider";
import {Handler} from "../../common/handler";
import {View} from "../../common/view";
import {AppCommonUtils, sendQuery} from "../../common/app-common-utils";
import {EntityType} from "../../handlers/entity";
import {downloadBulkMonthData} from "../refdata/connections/connections-overview/bulk-data-download";
import {HandleQuery} from "../../common/handle-query";

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss'],
    host: {
        class: 'w-100'
    }
})
@Handler()
export class DashboardComponent extends View implements OnInit, OnDestroy {
    appContext = AppContext;
    context = DashboardContext;

    private ngZone: NgZone = inject(NgZone);
    private router: Router = inject(Router)
    private route: ActivatedRoute = inject(ActivatedRoute);
    private chartUtils: ChartUtilsService = inject(ChartUtilsService);


    @ViewChild('leftMenuCollapse', {static: true}) filtersModal: ElementRef;
    @ViewChild('sourceListDropdown') sourceListDropdown: ElementRef;

    addresses: Location[] = [];

    dataProvider: PortfolioMeasurementsDataProvider;
    dayOfWeekDataProvider: DayOfWeekMeasurementsDataProvider;
    dashboard: Dashboard = { info: {}};

    constructor() {
        super();
        this.dataProvider = new PortfolioMeasurementsDataProvider(this.chartUtils);
        this.dayOfWeekDataProvider = new DayOfWeekMeasurementsDataProvider(this.chartUtils);
        this.route.params.subscribe(params => {
            this.route.queryParams.subscribe(queryParams => {
                const param = params['dashboard'];
                this.dashboard = param ? DashboardContext.base64ToDashboard(param) : DashboardContext.defaultDashboard();
                this.dataProvider.info = this.dashboard.info;
                this.dataProvider.sourceProvider.selectedSources = this.dataProvider.info.sources;
                this.dataProvider.ranges = DashboardContext.defaultRanges;
                if (this.dayOfWeekDataProvider) {
                    this.dayOfWeekDataProvider.info = this.dataProvider.info;
                    this.dayOfWeekDataProvider.sourceProvider = this.dataProvider.sourceProvider;
                }
            });
        });
    }

    ngOnInit(): void {
        this.dataProvider.timeChange.pipe(takeUntil(this.dataProvider.notifier))
            .subscribe(t => {
                AppCommonUtils.modifyQueryCache("getChartDataQueryFilters", (f: DataQueryFilters) => {
                    f.timeRange = {
                        start: t.start,
                        end: t.end
                    };
                    return f;
                });
                this.updateAndNavigateToDashboard();
                this.dayOfWeekDataProvider?.timeChanged(t);
            });
        this.dataProvider.sourceProvider.selectionUpdated.pipe(takeUntil(this.dataProvider.notifier))
            .subscribe(t => this.sourceSelectionUpdated(t));
    }

    sourceSelectionUpdated(sources: SourceInfo[]) {
        this.ngZone.runOutsideAngular(() => {
            AppCommonUtils.modifyQueryCache("getChartDataQueryFilters", (f: DataQueryFilters) => {
                f.sources = sources;
                return f;
            });
            this.updateAndNavigateToDashboard();
            this.dataProvider.sourceChanged(sources);
            this.dayOfWeekDataProvider?.sourceChanged(sources);
            this.addresses = this.getAddresses();
        });
    }

    ngOnDestroy(): void {
        this.dataProvider.notifier.next(null);
        this.dataProvider.notifier.complete();
        this.dayOfWeekDataProvider?.notifier.next(null);
        this.dayOfWeekDataProvider?.notifier.complete();
    }

    private getAddresses() {
        const sourceSelection = this.dataProvider.sourceProvider.getTreeViewSelection();
        const items = sourceSelection.length > 0 ? sourceSelection : this.dataProvider.sourceProvider.flattenedData;
        return items.flatMap(this.getLocations);
    }

    private getLocations(item: TreeViewItem): Location[] {
        switch (item.info.type) {
            case EntityType.organisation:
                return item.info.source.organisation.locations;
            case EntityType.location:
                return [item.info.source.location];
            case EntityType.connection:
                return [item.parent.info.source.location];
            case EntityType.meter:
                return [item.parent.parent.info.source.location];
        }
    }

    saveDashboard = (): void => {
        const savedDashboard: Dashboard = cloneObject(this.dashboard);
        savedDashboard.dashboardId = uuid();
        const command: SaveDashboard = {
            info: savedDashboard.info,
            dashboardId: savedDashboard.dashboardId,
            userId: AppContext.userProfile.userId
        };
        this.sendCommand("com.flowmaps.api.user.SaveDashboard", command, () =>
            AppCommonUtils.navigateToUrl("/dashboard/" + encodeURIComponent(DashboardContext.dashboardToBase64(savedDashboard))));
    }

    deleteDashboard = (id): void => {
        const command: DeleteDashboard = {
            dashboardId: id,
            userId: AppContext.userProfile.userId
        };
        this.sendCommand("com.flowmaps.api.user.DeleteDashboard", command, () => {
            removeIf(AppContext.userProfile.dashboards, d => d.dashboardId === id);
            if (this.dashboard?.dashboardId === id) {
                AppCommonUtils.navigateToUrl("/");
            }
        });
    }

    updateAndNavigateToDashboard() {
        this.ngZone.run(() => {
            this.dashboard.info.sources = this.dataProvider.sourceProvider.selectedSources;
            return this.router.navigateByUrl("/dashboard/" + DashboardContext.dashboardToBase64(this.dashboard));
        });
    }

    downloadMonthlyData = () => downloadBulkMonthData(this.dataProvider.sourceProvider, this.dataProvider.info.timeRange);

    @HandleQuery("getChartDataQueryFilters", { caching: true })
    getChartDataQueryFilters(): Observable<DataQueryFilters> {
        return of({
            timeRange: this.dataProvider.info.timeRange,
            sources: this.dataProvider.selectedSources
        });
    }

    @HandleQuery("getContractMeasurements", { caching: true })
    getContractMeasurements(): Observable<ContractMeasurementResult> {
        return this.subscribeTo("getChartDataQueryFilters").pipe(mergeMap((filters: DataQueryFilters) => {
            const resolution = AppContext.resolutionForTimeRange(filters.timeRange);
            return filters.timeRange && filters.sources ? sendQuery("com.flowmaps.api.measurements.GetContractMeasurements", <GetContractMeasurements>{
                timeRange: filters.timeRange,
                sourceIds: this.dataProvider.sourceProvider.sourceSelectionAfterCleanup(),
                resolution: resolution,
                unrounded: ![TimeResolution.year, TimeResolution.month].includes(resolution)
            }) : of(null);
        }));
    }
}

export interface DataQueryFilters {
    timeRange: TimeRange;
    sources: SourceInfo[];
}