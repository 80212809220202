import {ChangeDetectorRef, Component, Input} from '@angular/core';

@Component({
  selector: 'app-bar-chart-tooltip',
  templateUrl: './dashboard-chart-tooltip.component.html',
  styleUrls: ['./dashboard-chart-tooltip.component.scss']
})
export class DashboardChartTooltipComponent {

  @Input()
  tooltipConfig: TooltipConfig = {
    axisLabel: "",
    data: []
  };

  constructor(private changeDetectorRef: ChangeDetectorRef) {
  }

  public detectChanges = (): void => this.changeDetectorRef.detectChanges();
}

export interface TooltipConfig {
  axisLabel: string;
  data: TooltipData[];
}

export interface TooltipData {
  label: string;
  value: string;
}