import {AfterViewInit, Component} from '@angular/core';
import {View} from "../../../../common/view";
import {ApiKey, Secret} from "@flowmaps/flowmaps-typescriptmodels";
import {AppContext} from "../../../../app-context";
import {Observable} from "rxjs";
import {ApiKeyDetailsComponent} from "../api-key-details/api-key-details.component";
import {ApiKeyResponseComponent} from "../api-key-response/api-key-response.component";
import {downloadAny} from "../../../../common/download.utils";

@Component({
    selector: 'app-api-keys-overview',
    templateUrl: './api-keys-overview.component.html',
    styleUrls: ['./api-keys-overview.component.css']
})
export class ApiKeysOverviewComponent extends View implements AfterViewInit {
    keys: ApiKey[] = [];
    command: UpsertAPIkeyData = {description: "", keyId: ""};
    query: Observable<ApiKey[]>
    protected readonly appContext = AppContext;

    generateApiKey = () => {
        this.openModal(ApiKeyDetailsComponent, <UpsertAPIkeyData>this.command, {backdrop: 'static'},
            (result: Secret) => {
                this.command = {description: "", keyId: ""};
                this.openModal(ApiKeyResponseComponent, result, {backdrop: 'static'}, () => downloadAny(
                    JSON.stringify(result.secret), `${result.secret.keyId}.json`, "application/json"))
            })
    }

    ngAfterViewInit() {
        this.query = this.subscribeTo("getApiKeys");
    }

    trackByForRecord = (index: number, record: ApiKey) => record.keyId;
}

export interface UpsertAPIkeyData {
    description: string;
    keyId: string;
}