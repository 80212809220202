import {Injectable} from "@angular/core";
import {map, Observable} from "rxjs";
import {
    BuildingType,
    ConnectionType,
    ConnectionValue,
    ConsumptionTax,
    FindBuildingTypes,
    FindConnectionValues,
    GetBuildingType,
    GetMyConsumptionTaxes,
    GetServiceProviders,
    GetSustainabilitySources,
    ServiceProvider,
    ServiceProviderType,
    SustainabilitySource
} from "@flowmaps/flowmaps-typescriptmodels";
import lodash from "lodash";
import {ComparatorChain} from "../common/comparator-chain";
import {subscribeTo} from "../common/app-common-utils";
import {StandaloneHandler} from "../common/standalone-handler";
import {HandleQuery} from "../common/handle-query";

@Injectable({
    providedIn: "root"
})
export class RefDataStandaloneHandler extends StandaloneHandler {
    private serviceProvidersComparator = new ComparatorChain('name', 'code');
    private buildingTypesComparator: ComparatorChain = new ComparatorChain('info.name', 'buildingTypeId');
    private taxesComparator: ComparatorChain = new ComparatorChain('taxInfo.country', 'taxInfo.connectionType', 'taxId');

    @HandleQuery("getBuildingType")
    getBuildingType(buildingTypeId: string): Observable<BuildingType> {
        return subscribeTo("com.flowmaps.api.refdata.GetBuildingType", <GetBuildingType>{
            buildingTypeId: buildingTypeId
        });
    }

    @HandleQuery("findBuildingTypes")
    findBuildingTypes(query?: FindBuildingTypes): Observable<BuildingType[]> {
        return subscribeTo("com.flowmaps.api.refdata.FindBuildingTypes", query)
            .pipe(map((buildingTypes: BuildingType[]) => buildingTypes.sort(this.buildingTypesComparator.compare)));
    }

    @HandleQuery("getServiceProviders")
    getServiceProviders(type: ServiceProviderType): Observable<ServiceProvider[]> {
        return subscribeTo("com.flowmaps.api.organisation.GetServiceProviders", <GetServiceProviders>{
            type: lodash.isEmpty(type) ? null : type
        }).pipe(map((s: ServiceProvider[]) => s.sort(this.serviceProvidersComparator.compare)))
    }

    @HandleQuery("getConnectionValues")
    getConnectionValues(connectionType?: ConnectionType): Observable<ConnectionValue[]> {
        return subscribeTo("com.flowmaps.api.refdata.FindConnectionValues", <FindConnectionValues>{
            connectionType: lodash.isEmpty(connectionType) ? null : connectionType
        });
    }

    @HandleQuery("getConsumptionTaxes")
    getConsumptionTaxes(query: GetMyConsumptionTaxes): Observable<ConsumptionTax[]> {
        query.facetFilters = lodash.cloneDeep(query.facetFilters || []);
        return subscribeTo("com.flowmaps.api.organisation.GetMyConsumptionTaxesNew", query)
            .pipe(map((s: ConsumptionTax[]) => s.sort(this.taxesComparator.compare)))
    }

    @HandleQuery("getSustainabilitySources")
    getSustainabilitySources(connectionType: ConnectionType): Observable<SustainabilitySource[]> {
        return subscribeTo("com.flowmaps.api.organisation.GetSustainabilitySources", <GetSustainabilitySources>{
            connectionType: connectionType
        }).pipe(map(sources => sources.sort(new ComparatorChain('connectionType', 'code').compare)));
    }
}