import {ChangeDetectorRef, Component, inject, Input, OnInit} from '@angular/core';
import {ChartDatasetExtended, MeasurementDataset} from '../base-measurement-chart';
import {
    ConnectionType,
    ContractMeasurementResult,
    CostsMeasurementResult,
    CostType,
    DataType
} from '@flowmaps/flowmaps-typescriptmodels';
import {lodash} from '../../../common/utils';
import {AppContext} from '../../../app-context';
import {ChartUtilsService} from "../chart-utils.service";
import {TooltipModel} from "chart.js/dist/types";
import {DecimalPipe} from "@angular/common";
import {TranslateDirective} from "../../../common/utils/translate.directive";
import {View} from "../../../common/view";
import {Handler} from "../../../common/handler";
import {ChartDataProvider} from "../../../utils/chart-data-provider";

@Component({
    selector: 'app-costs-chart',
    templateUrl: './costs-chart.component.html',
    styleUrls: ['./costs-chart.component.css']
})
@Handler()
export class CostsChartComponent extends View implements OnInit {
    private decimalPipe: DecimalPipe = inject(DecimalPipe);
    protected changeDetectorRef: ChangeDetectorRef = inject(ChangeDetectorRef);
    protected chartUtils: ChartUtilsService = inject(ChartUtilsService);

    totalCosts: number = 0;
    allConnectionTypes: ConnectionType[] = [ConnectionType.Electricity, ConnectionType.Gas, ConnectionType.Heat, ConnectionType.Water];

    @Input() showInReport: boolean;
    dataProvider: ChartDataProvider = new ChartDataProvider();

    consumptionProductionCostsLink: Map<CostType, CostType> = new Map<CostType, CostType>()
        .set(CostType.consumption, CostType.feedIn)
        .set(CostType.consumptionOffPeak, CostType.feedInOffPeak);

    ngOnInit() {
        this.subscribeTo("getContractMeasurements").subscribe((d: ContractMeasurementResult) => this.setData(d));
    }

    setData(result: ContractMeasurementResult) {
        const data: MeasurementDataset[] = this.allConnectionTypes.map((c, i) => {
            const filteredResults = lodash.sortBy(result.costsMeasurements
                .filter(item => item.connectionType === c && item.value != 0)
                .map(item => {
                    item["indexOfCostType"] = this.costsOrdering.indexOf(item.costType);
                    return item;
                }), ["estimated", "indexOfCostType"]);
            const dataset: MeasurementDataset = {
                measurementType: this.getDataTypeForConnectionType(c),
                dataset: this.getDataset(c, filteredResults, i + 1)
            };
            return dataset;
        });
        this.totalCosts = Math.round(lodash.sum(result.costsMeasurements.map(item => item.value)));
        this.changeDetectorRef.detectChanges();
        this.dataProvider.emit({
            datasets: data.flatMap(d => [d, <any>{
                dataset: {
                    weight: 0.7
                }
            }])
        });
    }

    private getDataset = (connectionType: ConnectionType, data: CostsMeasurementResult[], order?: number): ChartDatasetExtended => {
        const measurementType = this.getDataTypeForConnectionType(connectionType);
        const measurementName = AppContext.measurementName(measurementType);
        const connectionConfig = AppContext.getConnectionConfig(connectionType);

        const costValues = this.getNettedCostValues(data);
        const costTypes = data.map(item => {
            if (item.estimated) {
                return `${this.transformCostType(item.costType)} (estimated)`;
            } else {
                return this.transformCostType(item.costType) ?? null;
            }
        });

        const colors = data.map(item => {
            if (item.estimated) {
                return 'white';
            } else {
                if (item.costType) {
                    const costsColor = connectionConfig.costsColors ? connectionConfig.costsColors[item.costType] : connectionConfig.color;
                    if ([CostType.feedIn, CostType.feedInOffPeak].includes(item.costType)) {
                        return ChartUtilsService.createDiagonalPattern(costsColor, 'ltr');
                    }
                    return costsColor;
                }
            }
            return null;
        });

        const costsColors = data.map(item => item.costType && connectionConfig.costsColors
            ? connectionConfig.costsColors[item.costType] : connectionConfig.color);
        return ({
            label: measurementName,
            data: costValues.length ? costValues : [0],
            backgroundColor: colors,
            borderColor: costsColors,
            hoverBackgroundColor: costsColors,
            borderWidth: 1,
            order: order,
            measurementType: measurementType,
            tooltipLabels: costTypes,
            tooltip: {
                formatter: this.getTooltipFormatter(),
                labelOverride: costTypes.map(c => TranslateDirective.getTranslation(`${connectionType} ${c}`, true)),
                borderColors: costsColors,
                data: data.map(item => item.value ?? 0)
            }
        });
    };

    private transformCostType(costType: string): string {
        switch (costType) {
            case 'consumptionOffPeak':
                return 'Consumption Off-Peak';
            case 'feedIn':
                return 'Feed-In';
            case 'feedInOffPeak':
                return 'Feed-In Off-Peak';
            case 'standingCharge':
                return 'Standing Charge';
            default:
                return costType.replace(/([a-z])([A-Z])/g, '$1 $2')
                    .replace(/\b([a-z])/g, (char) => char.toUpperCase());
        }
    }

    private getNettedCostValues(data: CostsMeasurementResult[]) {
        return data.map(item => {
            const mappedFeedIn = this.consumptionProductionCostsLink.get(item.costType);
            const foundFeedInCost = data.find(d =>
                d.estimated === item.estimated && d.costType === mappedFeedIn);
            if (mappedFeedIn && foundFeedInCost) {
                return Math.max(item.value + (foundFeedInCost.value ?? 0), 0);
            }
            return Math.abs(item.value);
        });
    }

    private getDataTypeForConnectionType = (connectionType: ConnectionType): DataType => {
        switch (connectionType) {
            case ConnectionType.Electricity:
                return DataType.electricityConsumptionCosts;
            case ConnectionType.Gas:
                return DataType.gasConsumptionCosts;
            case ConnectionType.Heat:
                return DataType.heatConsumptionCosts;
            case ConnectionType.Water:
                return DataType.waterConsumptionCosts;
        }
    }

    getTooltipFormatter(): (ctx: TooltipModel<any>, value: number) => string {
        return (ctx, value) => `€ ${this.decimalPipe.transform(value, '1.0-0')}`;
    }

    costsOrdering: CostType[] = [
        CostType.consumption,
        CostType.feedIn,
        CostType.consumptionOffPeak,
        CostType.feedInOffPeak,
        CostType.standingCharge,
        CostType.consumptionTax
    ]
}
