import {Component, Input, OnInit} from '@angular/core';
import {View} from "src/app/common/view";
import {Handler} from "src/app/common/handler";
import {ConsumptionTax, TaxPeriod, UpsertTaxPeriod} from "@flowmaps/flowmaps-typescriptmodels";
import {uuid} from "../../../../common/utils";
import {DashboardContext} from "../../../dashboard/dashboard.context";

@Component({
  selector: 'app-tax-period-details',
  templateUrl: './tax-period-details.component.html',
  styleUrls: ['./tax-period-details.component.scss']
})
@Handler()
export class TaxPeriodDetailsComponent extends View implements OnInit {
  dashboardContext = DashboardContext;

  @Input() data: TaxPeriodDetailsComponentData;
  isNewRecord: boolean;

  ngOnInit(): void {
    this.isNewRecord = !this.data || !this.data.period.periodId;
    if (this.isNewRecord) {
      this.data.period = {
        periodId: uuid(),
        data: {
          period: {
            start: "",
            end: ""
          },
          residentialRate: null,
          taxBrackets: []
        },
      };
    }
  }

  updateTaxPeriod = () => {
    this.sendCommand("com.flowmaps.api.organisation.UpsertTaxPeriod", <UpsertTaxPeriod> {
      periodId: this.data.period.periodId,
      data: this.data.period.data,
      taxId: this.data.tax.taxId
    });
  }

  addTaxBracket() {
    this.data.period.data.taxBrackets.push({ minLimit: null, maxLimit: null, rate: null });
  }

  removeTaxBracket(index: number) {
    this.data.period.data.taxBrackets.splice(index, 1);
  }

  costUnit = () => "€"
}

export interface TaxPeriodDetailsComponentData {
  period: TaxPeriod;
  tax: ConsumptionTax;
}
