import {ChartDataPerMeasurement, MeasurementsDataProvider} from "./measurements-data-provider";
import {
    GetLocationMeasurements,
    LocationMeasurementsResult,
    Organisation,
    TimeRange,
    TimeResolution
} from "@flowmaps/flowmaps-typescriptmodels";
import {Observable} from "rxjs";
import {DashboardContext} from "../views/dashboard/dashboard.context";
import {CompletenessInfo} from "../app-context";
import {sendQuery} from "../common/app-common-utils";

export class MeterMeasurementsDataProvider extends MeasurementsDataProvider<LocationMeasurementsResult> {
    groupByEntityIdEnabled: () => boolean = () => true;
    sourceRequired: () => boolean = () => true;

    getDataForRange(timeRange: TimeRange): Observable<LocationMeasurementsResult> {
        return sendQuery("com.flowmaps.api.measurements.GetLocationMeasurements", <GetLocationMeasurements>{
            timeRange: timeRange,
            locationIds: this.sourceProvider.selectedSources.locationIds,
            resolution: this.info.resolution,
            unrounded: ![TimeResolution.year, TimeResolution.month].includes(this.info.resolution)
        });
    }

    createChartData(data: LocationMeasurementsResult, completeness: CompletenessInfo[], organisations: Organisation[], dateRange: TimeRange, stack: string = DashboardContext.stacks.currentPeriod, comparedYear?: number): ChartDataPerMeasurement {
        if (stack === DashboardContext.stacks.currentPeriod) {
            this.dashboardData.labels = MeasurementsDataProvider.getSlots(dateRange, this.info.resolution).map(s => s.label);
        }
        return {
            totals: data.byMeter,
            measurements: this.groupDataByMeasurement(data.byMeter, dateRange, stack, comparedYear, false, (m) => m.measurements),
            estimatedMeasurements: this.groupDataByMeasurement(data.byMeter, dateRange, stack, comparedYear, true, (m) => m.estimatedMeasurements),
            totalsPerYear: data.totalsPerYear,
            completeness: completeness
        }
    }

    copy(): MeterMeasurementsDataProvider {
        return new MeterMeasurementsDataProvider(this.chartUtils, this.info, this.selectedSources)
    }
}