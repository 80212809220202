import {Component, inject, ViewContainerRef} from '@angular/core';
import {AppCommonUtils} from "./common/app-common-utils";
import {HandleCommand} from "./common/handle-command";
import {Handler} from "./common/handler";
import {defaultModalOptions, OpenModal} from "./common/modal/modal";
import {ModalComponent} from "./common/modal/modal.component";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
@Handler()
export class AppComponent {
  appCommonUtils = AppCommonUtils;
  viewContainerRef: ViewContainerRef = inject(ViewContainerRef);

  @HandleCommand("openModal")
  openModal(command: OpenModal) {
    const container = command.container || this.viewContainerRef;
    const modal = container.createComponent(ModalComponent);
    container.element.nativeElement.appendChild(modal.location.nativeElement);
    command.options = command.options || defaultModalOptions;
    const optionsCallback = command.options.closeCallback;
    command.options.closeCallback = (args?) => {
      try {
        if (optionsCallback) {
          optionsCallback(args);
        }
      } finally {
        modal.destroy();
      }
    }
    setTimeout(() => modal.instance.openModal(command), 0);
  }

}
