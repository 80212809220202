import {Component, inject, OnInit} from '@angular/core';
import {View} from "src/app/common/view";
import {Handler} from "src/app/common/handler";
import {ContractEntity} from "../../../../handlers/app-entity-handler.service";
import {Contract, DeleteContract, UpsertContract} from "@flowmaps/flowmaps-typescriptmodels";
import {AppContext} from "../../../../app-context";
import {ActivatedRoute, Router} from "@angular/router";
import {RouteData} from "../../../../app-routing.module";

@Component({
    selector: 'app-contract-details',
    templateUrl: './contract-details.component.html',
    styleUrls: ['./contract-details.component.scss']
})
@Handler()
export class ContractDetailsComponent extends View implements OnInit {
    appContext = AppContext;
    contractEntity: ContractEntity;

    private route: ActivatedRoute = inject(ActivatedRoute);
    private router: Router = inject(Router);
    routeData: RouteData;

    constructor() {
        super();
        this.routeData = this.route.snapshot.data as RouteData;
    }

    ngOnInit() {
        this.subscribeTo("getContract").subscribe(c => this.contractEntity = c);
    }

    get contract(): Contract {
        return this.contractEntity?.contract;
    }

    updateInfo = () => this.sendCommand('com.flowmaps.api.organisation.UpsertContract', <UpsertContract>{
        organisationId: this.contractEntity.organisation.organisationId,
        contractId: this.contract.contractId,
        defaultContract: this.contract.defaultContract,
        contractData: this.contract.contractData
    });

    deleteContract = () => this.sendCommand("com.flowmaps.api.organisation.DeleteContract", <DeleteContract>{
        contractId: this.contract.contractId,
        organisationId: this.contractEntity.organisation.organisationId,
    }, () => this.router.navigateByUrl(`/contracts`))
}
