import {Component, Input, OnInit} from '@angular/core';
import {Connection, ConnectionType, Location, Meter, MeterType} from "@flowmaps/flowmaps-typescriptmodels";
import {RefdataUtils} from "../../refdata/refdata-utils";
import {AppContext} from "../../../app-context";
import {Entity} from "../../../handlers/entity";
import {Handler} from "../../../common/handler";
import {View} from "../../../common/view";
import {MeterComponentData, MeterDetailsComponent} from "../../refdata/meters/meter-details/meter-details.component";

@Component({
    selector: 'app-location-meters',
    templateUrl: './location-meters.component.html',
    styleUrls: ['./location-meters.component.scss']
})
@Handler()
export class LocationMetersComponent extends View implements OnInit {
    appContext = AppContext;
    refDataUtils = RefdataUtils;
    @Input() location: Entity;
    meters: Entity[];

    ngOnInit() {
        this.sendQuery("getEntitiesById", this.location.location.connections.flatMap(c => c.meters).map(m => m.meterId))
            .subscribe(e => this.meters = e)
    }

    getMetersForConnectionType = (connectionType: ConnectionType | string, primaryOnly: boolean): Entity[] => {
        return this.meters
            .filter(c => c.connection.info.connectionType === connectionType)
            .filter(c => {
                const type = c.meter.details?.type || c.meter.info.type;
                return primaryOnly ? type === MeterType.PRIMARY : type !== MeterType.PRIMARY;
            });
    }

    getSubMeters = (connectionType?: ConnectionType | string): Entity[] => {
        return this.meters
            .filter(c => !connectionType || c.connection.info.connectionType === connectionType)
            .filter(c => {
                const type = c.meter.details?.type || c.meter.info.type;
                return type !== MeterType.PRIMARY;
            });
    }

    editMeter = (meter: Entity) => this.openModal(MeterDetailsComponent, <MeterComponentData>{
        entity: meter
    });

    trackBy: any = (index: number, item: MeterWithConnection) => {
        return item?.meter?.meterId;
    };
}

export interface MeterWithConnection {
    meter: Meter;
    connection: Connection;
    location: Location;
}