import {Injectable} from "@angular/core";
import * as jsonPatch from "fast-json-patch";
import {Organisation, UiUpdate, UserProfile} from "@flowmaps/flowmaps-typescriptmodels";
import {StandaloneHandler} from "../common/standalone-handler";
import {HandleEvent} from "../common/handle-event";
import {AppCommonUtils} from "../common/app-common-utils";
import {removeItem} from "../common/utils";

@Injectable({
    providedIn: "root"
})
export class AppQueryCacheHandler extends StandaloneHandler {

    @HandleEvent("Organisation")
    handleOrganisationUpdate(event: UiUpdate) {
        AppCommonUtils.modifyQueryCache("com.flowmaps.api.organisation.GetMyOrganisations", (organisations: Organisation[]) => {
            const before = organisations.find(o => o.organisationId === event.id);
            const patchResult = jsonPatch.applyPatch(before, event.patch);
            const after = patchResult.newDocument;
            if (!before) {
                organisations.push(after);
            }
            if (!after) {
                removeItem(organisations, before);
            }
            return [...organisations];
        });
    }

    @HandleEvent("UserProfile")
    handleUserUpdate(event: UiUpdate) {
        AppCommonUtils.modifyQueryCache("com.flowmaps.api.user.GetUserProfile", (user: UserProfile) =>
            event.id === user.userId ? jsonPatch.applyPatch(user, event.patch).newDocument : user);
        AppCommonUtils.modifyQueryCache("com.flowmaps.api.user.FindUsers", (users: UserProfile[]) => {
            const before = users.find(o => o.userId === event.id);
            const patchResult = jsonPatch.applyPatch(before, event.patch);
            const after = patchResult.newDocument;
            if (!before) {
                users.push(after);
            }
            if (!after) {
                removeItem(users, before);
            }
            return [...users];
        });
    }
}
