import {Component, Input, OnInit} from '@angular/core';
import {View} from "src/app/common/view";
import {Handler} from "src/app/common/handler";
import {ContractEntity} from "../../../../handlers/app-entity-handler.service";
import {AppContext} from "../../../../app-context";
import {RefdataUtils} from "../../refdata-utils";
import {Organisation} from "@flowmaps/flowmaps-typescriptmodels";
import {TranslateDirective} from "../../../../common/utils/translate.directive";

@Component({
    selector: 'app-contract-info',
    templateUrl: './contract-info.component.html',
    styleUrls: ['./contract-info.component.scss']
})
@Handler()
export class ContractInfoComponent extends View implements OnInit {
    appContext = AppContext;
    refDataUtils = RefdataUtils;

    @Input() contract: ContractEntity;
    @Input() readonly: boolean;
    isNewRecord: boolean;

    ngOnInit(): void {
        this.isNewRecord = !this.contract.contract || !this.contract.contract.contractId;
        this.setDefaultOrganisation();
    }

    searchOrganisations = (term: string) => this.sendQuery("searchOrganisations", term);

    setOrganisation = (organisation: Organisation) => this.contract.organisation = organisation;

    onDefaultContractChange() {
        if (this.contract.contract.defaultContract) {
            this.setDefaultOrganisation();
        } else {
            this.contract.organisation = null;
        }
    }

    getToggleLabel = (): string => TranslateDirective.getTranslation(this.contract.contract.defaultContract ? "yes" : "no", true);

    private setDefaultOrganisation() {
        this.sendQuery('getOrganisations').subscribe((orgs: Organisation[]) => {
            if (orgs?.length) {
                const defaultOrganisation = orgs.find(org =>
                    org.receivedAuthorisations?.some(auth => auth.role === 'intermediary'));
                if (defaultOrganisation && this.isNewRecord) {
                    this.contract.organisation = defaultOrganisation;
                }
            }
        });
    }
}
