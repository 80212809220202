import {AfterViewInit, Component} from '@angular/core';
import {View} from "src/app/common/view";
import {Handler} from "src/app/common/handler";
import {ContractEntity} from "../../../../handlers/app-entity-handler.service";
import {RefdataUtils} from "../../refdata-utils";
import {ComparatorChain} from "../../../../common/comparator-chain";
import {AppContext} from "../../../../app-context";
import {DashboardContext} from "../../../dashboard/dashboard.context";
import {ContractPeriod} from "@flowmaps/flowmaps-typescriptmodels";
import {
    ContractPeriodComponentData,
    ContractPeriodDetailsComponent
} from "../contract-period-details/contract-period-details.component";
import {cloneDeep} from "lodash";
import {map, Observable} from "rxjs";
import {sort} from "../../../../common/utils";

@Component({
    selector: 'app-contract-periods-overview',
    templateUrl: './contract-periods-overview.component.html',
    styleUrls: ['./contract-periods-overview.component.scss']
})
@Handler()
export class ContractPeriodsOverviewComponent extends View implements AfterViewInit {
    appContext = AppContext;
    refDataUtils = RefdataUtils;
    dashboardContext = DashboardContext;

    contract: ContractEntity;
    comparator: ComparatorChain = new ComparatorChain('contract.data.dateRange');

    term: string;
    query: Observable<ContractPeriod[]>;
    data: ContractPeriod[] = [];

    ngAfterViewInit() {
        this.executeQuery();
        this.subscribeTo("getContract").pipe(map(c => this.contract = c));
    }

    executeQuery = () => {
        this.query = this.subscribeTo("getContractPeriods")
            .pipe(map((c: ContractPeriod[]) => sort(c, this.comparator)));
    }

    trackByForRecord = (index: number, record: ContractPeriod) => record.periodId;

    addNewContractPeriod(): void {
        this.openContractPeriodModal({
            periodId: '',
            data: {
                dateRange: {
                    start: '',
                    end: ''
                },
                offPeakRate: null,
                rate: null,
                feedInRate: null,
                feedInOffPeakRate: null,
                standingCharge: null,
                supplier: null,
                source: null
            }
        });
    }

    openContractPeriodModal(contractPeriod?: ContractPeriod): void {
        this.openModal(ContractPeriodDetailsComponent, cloneDeep(<ContractPeriodComponentData>{
            period: contractPeriod,
            contract: this.contract,
            source: contractPeriod.data.source,
            supplier: contractPeriod.data.supplier
        }));
    }
}