import {Component, Input, OnInit} from '@angular/core';
import {BuildingType, LocationInfo, UpsertLocation} from "@flowmaps/flowmaps-typescriptmodels";
import {uuid} from "../../../../common/utils";
import {RefdataUtils} from "../../refdata-utils";
import {AppContext} from "../../../../app-context";
import {Handler} from "src/app/common/handler";
import {View} from "src/app/common/view";
import {Observable} from "rxjs";
import {EnergyCompassUtils} from "../../../charts/energy-compass-chart/energy-compass.utils";
import {Entity} from "../../../../handlers/entity";

@Component({
  selector: 'app-location-details',
  templateUrl: './location-details.component.html',
  styleUrls: ['./location-details.component.scss']
})
@Handler()
export class LocationDetailsComponent extends View implements OnInit {
  energyCompassUtils = EnergyCompassUtils;
  appContext = AppContext;
  refDataUtils = RefdataUtils;
  @Input() data: LocationComponentData;
  isNewLocation: boolean;
  showOrganisationField: boolean;
  info: LocationInfo;
  buildingType: BuildingType;
  buildingTypes: Observable<BuildingType[]>;

  ngOnInit(): void {
    this.isNewLocation = !this.data.entity.location?.locationId;
    this.showOrganisationField = this.isNewLocation && !this.data.entity.organisation;
    this.info = this.data.entity.location?.info || LocationDetailsComponent.newLocation();
    if (this.info.buildingType) {
      this.sendQuery("getBuildingType", this.info.buildingType).subscribe(b => this.buildingType = b);
    }
    this.buildingTypes = this.sendQuery("findBuildingTypes");
  }

  searchOrganisations = (term: string) => this.sendQuery("searchOrganisations", term);

  buildingTypeFormatter = (buildingType: BuildingType) => buildingType.info.name;

  updateInfo = () => {
    if (this.buildingType) {
      this.info.buildingType = this.buildingType.buildingTypeId;
    }
    this.sendCommand("com.flowmaps.api.organisation.UpsertLocation", <UpsertLocation> {
      organisationId: this.data.entity.organisation.organisationId,
      locationId: this.isNewLocation ? uuid() : this.data.entity.location.locationId,
      info: this.info,
      refdata: this.data.entity.location?.refdata
    });
  };

  static newLocation = (): LocationInfo => ({
    address: {
      geoLocation: {

      }
    }
  })
}

export interface LocationComponentData {
  entity: Entity;
}