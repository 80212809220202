import {ChartConfiguration} from "chart.js";
import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    Input,
    NgZone,
    OnChanges,
    SimpleChanges,
    ViewChild
} from "@angular/core";
import {BaseChartDirective} from "ng2-charts";
import {takeUntil} from "rxjs";
import {MeasurementsDataProvider} from "../../utils/measurements-data-provider";
import {ChartDataProvider} from "../../utils/chart-data-provider";
import lodash, {cloneDeep} from "lodash";
import {ChartType} from "chart.js/dist/types";
import {ChartUtilsService} from "./chart-utils.service";
import {MeasurementData} from "./base-measurement-chart";

@Component({
  template: ''
})
export abstract class BaseChart<T extends ChartType = ChartType> implements AfterViewInit, OnChanges {
  @ViewChild(BaseChartDirective) chartDirective: BaseChartDirective;
  _data: MeasurementData;
  @Input() chartTitle: string = "";
  @Input() measurementsProvider: MeasurementsDataProvider<any>;
  @Input() dataProvider: ChartDataProvider;
  @Input() scales: {
    [key: string]: { };
  }
  @Input() fullScreen: boolean;

  useDataLabels: boolean = true;
  tooltipCentered: boolean = false;
  private hasData: boolean;
  private loaded: boolean;
  refreshOptionsOnDataChange: boolean = false;

  chartData = {
    datasets: []
  }

  abstract getOptions(): ChartConfiguration<T>['options'];

  private debounceTime: number = 100;
  private debounceUpdateChart = lodash.debounce(this.updateChart, this.debounceTime);

  constructor(protected ngZone: NgZone, protected chartUtils: ChartUtilsService, private changeDetectorRef: ChangeDetectorRef) {
  }

  get showChart() {
    return this.hasData || !this.loaded;
  }

  @Input()
  set data(data: MeasurementData) {
    this._data = data;
    if (this._data && this.chartDirective) {
      this.ngZone.runOutsideAngular(() => {
        this.debounceUpdateChart(data);
      });
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.ngZone.runOutsideAngular(() => this.refreshOptions());
  }

  updateChart(data: MeasurementData) {
    this.ngZone.runOutsideAngular(() => {
      this.chartData.datasets = cloneDeep(data.datasets.filter(r => r) || []);
      this.chartDirective.data.datasets = this.chartData.datasets.map(d => d.dataset);
      if (this.hasData !== this.chartHasData()) {
        this.hasData = this.chartHasData();
        setTimeout(() => this.changeDetectorRef.detectChanges(), 0);
      }
      this.chartDirective.data.labels = this.useDataLabels && !data.labels ? this.measurementsProvider?.dashboardData.labels : data.labels || [];
      this.updateChartWhenInitialised(false);
    });
  }

  updateChartWhenInitialised = (waiting: boolean) => {
    if (!this.chartDirective.chart) {
      this.chartDirective.render();
      setTimeout(() => this.updateChartWhenInitialised(true), 100);
      return;
    }
    if (waiting) {
      setTimeout(() => this.debounceUpdateChart(this._data), 100);
      return;
    }
    this.chartDirective.chart["fullScreen"] = this.fullScreen;
    this.chartDirective.chart["tooltipCentered"] = this.tooltipCentered;
    this.loaded = true;
    this.chartDirective.chart.update();
    if (this.refreshOptionsOnDataChange) {
      this.ngZone.runOutsideAngular(() => this.refreshOptions());
    }
  }

  protected refreshOptions(waiting: boolean = false) {
    if (!this.chartDirective) {
      return;
    }
    if (!this.chartDirective.chart) {
      this.chartDirective.render();
      setTimeout(() => this.refreshOptions(true), 100);
      return;
    }
    if (waiting) {
      setTimeout(() => this.refreshOptions(false), 100);
      return;
    }
    this.ngZone.runOutsideAngular(() => {
      this.chartDirective.chart.options = this.getOptions() as any;
      setTimeout(() => this.chartDirective.chart?.update(), 0);
    });
  }

  ngAfterViewInit() {
    if (this.measurementsProvider) {
      this.dataProvider.aggregatedDataChanged.pipe(takeUntil(this.measurementsProvider.notifier))
          .subscribe(d => this.data = d);
    } else {
      this.dataProvider.aggregatedDataChanged.subscribe(d => this.data = d);
    }
    if (this.dataProvider.lastValue?.datasets?.length > 0) {
      this.data = this.dataProvider.lastValue;
    }
    this.ngZone.runOutsideAngular(() => this.refreshOptions());
  }

  chartHasData = () => this.chartData.datasets.some(d => d.data?.length > 0);
}
