import {Component, inject} from '@angular/core';
import {View} from "src/app/common/view";
import {Handler} from "src/app/common/handler";
import {ActivatedRoute} from "@angular/router";
import {HandleQuery} from "../../../../common/handle-query";
import {map, Observable} from "rxjs";
import {ContractEntity} from "../../../../handlers/app-entity-handler.service";
import {ContractPeriod} from "@flowmaps/flowmaps-typescriptmodels";

@Component({
  selector: 'app-contract-routing',
  templateUrl: './contract-routing.component.html',
  styleUrls: ['./contract-routing.component.scss']
})
@Handler()
export class ContractRoutingComponent extends View {
  private route = inject(ActivatedRoute);
  contractId: string;

  constructor() {
    super();
    this.route.params.subscribe(params => this.contractId = params["contractId"]);
  }

  @HandleQuery("getContract")
  getContract(): Observable<ContractEntity> {
    return this.subscribeTo("getContracts")
        .pipe(map((c: ContractEntity[]) => c.find(c => c.contract.contractId === this.contractId)));
  }

  @HandleQuery("getContractPeriods")
  getContractPeriods(): Observable<ContractPeriod[]> {
    return this.subscribeTo("getContract").pipe(map((c: ContractEntity) => c.contract.periods));
  }
}
