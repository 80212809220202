import {ChartDataPerMeasurement, MeasurementsDataProvider} from "./measurements-data-provider";
import {
    AggregatedDataPoint,
    DataType,
    GetPortfolioMeasurements,
    Location,
    MeasurementsResult,
    Organisation,
    PortfolioMeasurementsResult,
    TimeRange,
    TimeResolution
} from "@flowmaps/flowmaps-typescriptmodels";
import {Observable} from "rxjs";
import {DashboardContext} from "../views/dashboard/dashboard.context";
import {AppContext, CompletenessInfo} from "../app-context";
import lodash from "lodash";
import {sendQuery} from "../common/app-common-utils";

export class PortfolioMeasurementsDataProvider extends MeasurementsDataProvider<PortfolioMeasurementsResult> {
    getDataForRange(timeRange: TimeRange): Observable<PortfolioMeasurementsResult> {
        return sendQuery("com.flowmaps.api.measurements.GetPortfolioMeasurements", <GetPortfolioMeasurements>{
            timeRange: timeRange,
            sources: this.sourceProvider.sourceSelectionAfterCleanup(),
            resolution: this.info.resolution,
            unrounded: ![TimeResolution.year, TimeResolution.month].includes(this.info.resolution)
        });
    }

    createChartData(data: PortfolioMeasurementsResult, completeness: CompletenessInfo[], organisations: Organisation[], dateRange: TimeRange, stack: string = DashboardContext.stacks.currentPeriod, comparedYear?: number): ChartDataPerMeasurement {
        if (stack === DashboardContext.stacks.currentPeriod) {
            this.dashboardData.labels = MeasurementsDataProvider.getSlots(dateRange, this.info.resolution).map(s => s.label);
        }
        return {
            totals: [data.totals],
            measurements: this.groupDataByMeasurement([data.totals], dateRange, stack, comparedYear, false, (m) => m.measurements),
            estimatedMeasurements: this.groupDataByMeasurement([data.totals], dateRange, stack, comparedYear, true, (m) => m.estimatedMeasurements),
            byLocation: this.mergeAliasesWithLocation(lodash.cloneDeep(data.byLocation), organisations),
            completeness: completeness
        }
    }

    copy(): PortfolioMeasurementsDataProvider {
        return new PortfolioMeasurementsDataProvider(this.chartUtils, this.info, this.selectedSources)
    }

    private mergeAliasesWithLocation(data: MeasurementsResult[], organisations: Organisation[]): MeasurementsResult[] {
        return data.map(r => {
            const location = this.getLocation(organisations, r.entityId);
            if (!location || location.locationId !== r.entityId) {
                return null;
            }
            if (!location.aliasIds?.length) {
                return r;
            }
            const aliasResults = data.filter(a => location.aliasIds.includes(a.entityId));
            aliasResults.forEach(v => r = this.mergeMeasurementsResult(r, v))
            return r;
        }).filter(r => r);
    }

    private mergeMeasurementsResult = (destination: MeasurementsResult, other: MeasurementsResult): MeasurementsResult => {
        destination.measurements = this.mergeMeasurements(destination.measurements, other.measurements);
        destination.estimatedMeasurements = this.mergeMeasurements(destination.estimatedMeasurements, other.estimatedMeasurements);
        return destination;
    }

    private mergeMeasurements(destination: { [P in DataType]?: AggregatedDataPoint[] }, other: { [P in DataType]?: AggregatedDataPoint[] }): { [P in DataType]?: AggregatedDataPoint[] } {
        const findByTimeRange = (data: AggregatedDataPoint[], a: AggregatedDataPoint): AggregatedDataPoint => data.find(
            d => d.timeRange.start === a.timeRange.start && d.timeRange.end === a.timeRange.end);
        Object.entries(other).forEach(e => {
            const destinationValue: AggregatedDataPoint[] = destination[e[0]];
            if (!destinationValue) {
                destination[e[0]] = e[1];
                return;
            }
            e[1].forEach(d => {
                const destinationPoint = findByTimeRange(destinationValue, d);
                if (!destinationPoint) {
                    destinationValue.push(d);
                    return;
                }
                const aggregationMethod = AppContext.getAggregationMethod(e[0] as DataType);
                destinationPoint.value = aggregationMethod([destinationPoint.value, d.value]);
            });
            destination[e[0]]= destinationValue;
        });
        return destination;
    }

    private getLocation = (organisations: Organisation[], id: string): Location => organisations.flatMap(
        o => o.locations.filter(l => l.locationId === id || l.aliasIds?.includes(id)))[0];
}