import {Component, inject, Input, OnInit} from '@angular/core';
import {View} from "src/app/common/view";
import {Handler} from "src/app/common/handler";
import {RefdataUtils} from "../../refdata-utils";
import {ContractEntity} from "../../../../handlers/app-entity-handler.service";
import {Router} from "@angular/router";
import {uuid} from "../../../../common/utils";
import {LinkContractToOrganisation, LinkedContract} from "@flowmaps/flowmaps-typescriptmodels";
import {map, Observable} from "rxjs";

@Component({
    selector: 'app-link-contract-organisation',
    templateUrl: './link-contract-organisation.component.html',
    styleUrls: ['./link-contract-organisation.component.scss']
})
@Handler()
export class LinkContractOrganisationComponent extends View implements OnInit {
    refDataUtils = RefdataUtils;

    contract: ContractEntity;
    @Input() data: LinkContractToOrganisationData;
    isNewRecord: boolean;

    private router: Router = inject(Router);

    ngOnInit(): void {
        this.isNewRecord = !this.data || !this.data.linkedContract.linkId;
        if (this.data.linkedContract.contractId) {
            this.subscribeTo("getContract", this.data.linkedContract.contractId).subscribe(c => this.contract = c);
        }
        if (this.isNewRecord) {
            this.data = {
                linkedContract: {
                    linkId: uuid(),
                    contractId: this.data.linkedContract.contractId || '',
                    dateRange: {
                        start: this.data.linkedContract.dateRange.start || '',
                        end: ''
                    },
                },
                organisationId: this.data.organisationId,
                contract: this.data.contract || null
            };
        }
    }

    onContractSelected = (selectedContract: ContractEntity) => {
        this.contract = selectedContract;
        this.data.linkedContract.contractId = selectedContract.contract.contractId;
    }

    updateLinkedContract = () =>
        this.sendCommand("com.flowmaps.api.organisation.LinkContractToOrganisation", <LinkContractToOrganisation>{
            organisationId: this.data.organisationId,
            linkedContract: this.data.linkedContract,
            contractName: this.contract.contract.contractData.name,
            linkId: this.data.linkedContract.linkId
        })

    getNonDefaultContracts = (term?: string): Observable<ContractEntity[]> => {
        return this.sendQuery("searchContracts", term)
            .pipe(map((contracts: ContractEntity[]) => contracts.filter(contract => !contract.contract.defaultContract))
        );
    }
}

export class LinkContractToOrganisationData {
    organisationId: string;
    linkedContract: LinkedContract;
    contract?: ContractEntity;
}
