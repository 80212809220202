import {BaseRouteReuseStrategy} from "@angular/router";
import {ExtendedActivatedRouteSnapshot} from "../app-routing.module";

export class RouteStrategyService extends BaseRouteReuseStrategy {

    shouldReuseRoute(future: ExtendedActivatedRouteSnapshot, current: ExtendedActivatedRouteSnapshot): boolean {
        if (future.routeConfig?.data) {
            return future.routeConfig.data.reUseRoute;
        }
        return super.shouldReuseRoute(future, current);
    }
}