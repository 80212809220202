import {DataType, MeasurementUnit, TimeResolution} from "@flowmaps/flowmaps-typescriptmodels";
import moment, {unitOfTime} from "moment/moment";
import {AppContext} from "../../app-context";
import {localTimeFormat} from "../../common/utils";
import {DateFieldRange, MomentDateFieldRange} from "../../common/date/date-range/date-field-range";
import {TranslateDirective} from "../../common/utils/translate.directive";
import {ChartOptionType, Dashboard, DashboardInfo, DashboardType} from "./dashboard.types";

export enum DateTimeSlot {
    year = "year",
    month = "month",
    day = "day"
}

export class DashboardContext {
    static gjToM3Rate: number = 31.60;
    static maximumYears: number = 10;
    static ranges: {[key: string]: MomentDateFieldRange} = {
        thisMonth: {
            label: 'This month',
            start: moment().startOf('month'),
            end: moment().add(1, "month").startOf('month')
        },
        previousMonth: {
            label: 'Previous month',
            start: moment().startOf('month').subtract(1, 'month'),
            end: moment().startOf('month')
        },
        thisYear: {
            label: 'This year',
            start: moment().startOf('year'),
            end: moment().add(1, "year").startOf('year')
        },
        previousYear: {
            label: 'Previous year',
            start: moment().startOf('year').subtract(1, 'year'),
            end: moment().startOf('year')
        },
        lastTwelveMonths: {
            label: 'Last 12 months',
            start: moment().subtract(1, 'year').add(1, 'month').startOf('month'),
            end: moment().startOf('month').add(1, 'month')
        },
        lastWeek: {
            label: 'Last week',
            start: moment().subtract(1, 'week').startOf('week'),
            end: moment().startOf('week')
        }
    }
    static defaultRanges: MomentDateFieldRange[] = [this.ranges.thisMonth, this.ranges.previousMonth,
        this.ranges.thisYear, this.ranges.previousYear, this.ranges.lastTwelveMonths];
    static weekdaysSorted: string[] = ["MONDAY", "TUESDAY", "WEDNESDAY", "THURSDAY", "FRIDAY", "SATURDAY", "SUNDAY"];

    static defaultRange = (): DateFieldRange => {
        const defaultName = "Last 12 months";
        const time = DashboardContext.defaultRanges.find(a => a.label === "Last 12 months");
        return {
            start: time.start.format(localTimeFormat),
            end: time.end.format(localTimeFormat),
            label: defaultName
        }
    };

    static stacks = {
        currentPeriod: "Current period",
        lastYear: "Last year"
    }

    static getConsumptionUnit(connectionType: string): string {
        switch (connectionType) {
            case 'Electricity':
                return this.getMeasurementUnit(DataType.electricityConsumption);
            case 'Gas':
                return this.getMeasurementUnit(DataType.gasConsumption);
            case 'Water':
                return this.getMeasurementUnit(DataType.waterConsumption);
            case 'Heat':
                return this.getMeasurementUnit(DataType.heatConsumption, false);
            case 'Cooling':
                return this.getMeasurementUnit(DataType.coolingConsumption);
            default:
                throw new Error(`Unknown connection type: ${connectionType}`);
        }
    }

    static getMeasurementUnit(measurementType: DataType, heatInM3: boolean = true) {
        switch (measurementType) {
            case DataType.electricityConsumption:
            case DataType.electricityConsumptionOffPeak:
            case DataType.electricityIntermediateConsumption:
            case DataType.electricityConsumptionReactive:
            case DataType.electricityConsumptionReactiveOffPeak:
            case DataType.electricityIntermediateConsumptionReactive:
            case DataType.electricityFeedIn:
            case DataType.electricityFeedInOffPeak:
            case DataType.electricityGrossProduction:
            case DataType.electricityFeedInReactive:
            case DataType.electricityFeedInReactiveOffPeak:
            case DataType.electricityGrossProductionReactive:
                return MeasurementUnit.kWh;

            case DataType.electricityPower:
            case DataType.electricityFeedInPower:
                return MeasurementUnit.kW;

            case DataType.gasConsumption:
            case DataType.gasConsumptionUncorrected:
            case DataType.gasPower:
            case DataType.waterConsumption:
            case DataType.waterIntermediateConsumption:
            case DataType.waterPower:
                return "m³";
            case DataType.heatConsumption:
            case DataType.heatPower:
                return heatInM3 ? "m³" : "GJ";
            case DataType.coolingConsumption:
                return MeasurementUnit.GJ;
            case DataType.windSpeed:
                return "m/s";
            case DataType.rainfall:
                return MeasurementUnit.mm;
            case DataType.temperature:
                return "°C";
            case DataType.sunHours:
                return TranslateDirective.getTranslation("Sun hours", true);
            case DataType.solarRadiation:
                return "kWh/m²";
            case DataType.co2EmissionFromElectricity:
            case DataType.co2EmissionFromGas:
            case DataType.co2EmissionFromHeat:
            case DataType.co2EmissionFromWater:
                return "tn";
            case DataType.electricityConsumptionCosts:
            case DataType.gasConsumptionCosts:
            case DataType.heatConsumptionCosts:
            case DataType.waterConsumptionCosts:
                return "€";
        }
    }

    static getMeasurementColor(stack: string, measurementType: DataType, tint: number = 0) {
        let color = stack === this.stacks.lastYear
            ? AppContext.getChartColorForMeasurement(measurementType).tint(0.25).greyScale()
            : AppContext.getChartColorForMeasurement(measurementType).tint(0);
        color = tint > 0 ? color.tint(tint) : color.darken(tint);
        return color.toString();
    }

    static getFormat(slot: unitOfTime.Base): string {
        switch (slot) {
            case 'year':
                return "YYYY";
            case 'month':
                return "MMM ’YY";
            case 'day':
                return "DD MMM";
            case 'hour':
                return "DD MMM ’YY HH:mm"
            case 'minute':
                return "DD MMM ’YY HH:mm";
        }
    }

    static getTableHeaderFormat(slot: unitOfTime.Base): string {
        switch (slot) {
            case 'year':
                return "YYYY";
            case 'month':
                return "MMM";
            case 'day':
                return "DD MMM";
            case 'hour':
                return "DD MMM HH:mm"
            case 'minute':
                return "DD MMM HH:mm";
        }
    }

    static dashboardToBase64 = (dashboard: Dashboard) => btoa(encodeURIComponent(escape(JSON.stringify(dashboard))));

    static base64ToDashboard = (base64: string): Dashboard => JSON.parse(unescape(decodeURIComponent(atob(base64))));

    static connectionDashboard = (connectionId: string) : Dashboard => {
        const dashboard = DashboardContext.defaultDashboard();
        dashboard.info.sources.connectionIds = [connectionId];
        return dashboard;
    };

    static organisationDashboard = (organisationId: string) : Dashboard => {
        const dashboard = DashboardContext.defaultDashboard();
        dashboard.info.sources.organisationIds = [organisationId];
        return dashboard;
    };

    static locationDashboard = (locationId: string) : Dashboard => {
        const dashboard = DashboardContext.defaultDashboard();
        dashboard.info.sources.locationIds = [locationId];
        return dashboard;
    };

    static defaultDashboard = (): Dashboard => ({
        info: (<DashboardInfo>{
            type: DashboardType.Portfolio,
            resolution: TimeResolution.month,
            timeRange: this.defaultRange(),
            predefinedTimeRange: this.defaultRange().label,
            sources: {
                organisationIds: [],
                locationIds: [],
                connectionIds: [],
                meterIds: []
            },
            chartOptions: {
                [ChartOptionType.Electricity]: {
                    splitOffPeak: true,
                    showPower: true,
                    showConsumption: true,
                    showFeedIn: true,
                    compare: {
                        relative: true,
                        comparedYear: 1
                    },
                    selectedWeatherTypes: []
                },
                [ChartOptionType.Gas]: {
                    showConsumption: true,
                    showPower: true,
                    compare: {
                        relative: true,
                        comparedYear: 1
                    },
                    selectedWeatherTypes: []
                },
                [ChartOptionType.Heat]: {
                    showConsumption: true,
                    showPower: true,
                    compare: {
                        relative: true,
                        comparedYear: 1
                    },
                    selectedWeatherTypes: []
                },
                [ChartOptionType.Water]: {
                    showConsumption: true,
                    showPower: true,
                    compare: {
                        relative: true,
                        comparedYear: 1
                    },
                    selectedWeatherTypes: []
                },
                [ChartOptionType.Performance]: {
                    compare: {
                        relative: true,
                        comparedYear: 1
                    },
                    selectedWeatherTypes: [],
                    showBySquareMeter: false,
                    selectedDataType: DataType.electricityConsumption
                },
                [ChartOptionType.DaysOfWeek]: {
                    compare: {
                        relative: true,
                        comparedYear: 1
                    },
                    selectedWeatherTypes: [],
                    showBySquareMeter: false,
                    selectedDataType: DataType.electricityConsumption,
                    showAllDays: true,
                    selectedDays: []
                }
            }
        })
    });

    static generateRandom(seed: string, minValue: number, maxValue: number) {
        const s = this.stringToHash(seed);
        const random = Math.sin(s) * 10000;
        const normalizedRandom = random - Math.floor(random);
        return normalizedRandom * (maxValue - minValue) + minValue;
    }

    private static stringToHash(str: string) {
        let hash = 0;
        if (str.length === 0) return hash;
        for (let i = 0; i < str.length; i++) {
            const char = str.charCodeAt(i);
            hash = (hash << 5) - hash + char;
            hash = hash & hash;
        }
        return hash;
    }
}

export enum SourceType {
    organisation = "organisation",
    location = "location",
    connection = "connection",
    meter = "meter"
}