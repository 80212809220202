import {Component, Input, OnInit} from '@angular/core';
import {View} from "src/app/common/view";
import {Handler} from "src/app/common/handler";
import {AppContext} from "../../../../../app-context";
import {Authorisation, AuthorisationType, Organisation, RevokeAuthorisation} from "@flowmaps/flowmaps-typescriptmodels";
import {map, Observable, of} from "rxjs";
import {RefdataUtils} from "../../../refdata-utils";
import {Entity} from "../../../../../handlers/entity";
import {cloneDeep} from "lodash";
import {AuthorisationDetailsComponent} from "../../authorisation-details/authorisation-details.component";

@Component({
  selector: 'app-authorisation-overview-item',
  templateUrl: './authorisation-overview-item.component.html',
  styleUrls: ['./authorisation-overview-item.component.scss']
})
@Handler()
export class AuthorisationOverviewItemComponent extends View implements OnInit {
  appContext = AppContext;
  refDataUtils = RefdataUtils;
  @Input() authorisation: Authorisation;
  organisation: Organisation;

  ngOnInit() {
    this.subscribeTo("getOrganisation").subscribe(o => this.organisation = o);
  }

  getIcon = (authorisation: Authorisation) => authorisation.type === AuthorisationType.user ? "bi-person-fill" : "bi-people-fill";

  revoke = (authorisation: Authorisation) => this.sendCommand("com.flowmaps.api.organisation.RevokeAuthorisation", <RevokeAuthorisation> {
    organisationId: this.organisation.organisationId,
    authorisationId: authorisation.authorisationId
  });

  authorisationHeader = (authorisation: Authorisation): Observable<string> => authorisation.type === AuthorisationType.organisation
      ? this.sendQuery("getEntity", this.authorisation.nominee)
          .pipe(map((e: Entity) => RefdataUtils.organisationFormatter(e.organisation)))
      : of(authorisation.nominee);

  getEntity = (entityId: string) : Observable<Entity> => this.subscribeTo("getEntity", entityId);

  editAuthorisation = (authorisation: Authorisation) => this.openModal(AuthorisationDetailsComponent,
      authorisation ? cloneDeep(authorisation) : { nominator: this.organisation?.organisationId });
}