import {Component} from '@angular/core';
import {AppContext} from "../../app-context";

@Component({
  selector: 'app-edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.css']
})
export class EditProfileComponent {
  appContext = AppContext;
}
