import {Component, Input} from '@angular/core';
import {Address} from "@flowmaps/flowmaps-typescriptmodels";

@Component({
  selector: 'app-address-form',
  templateUrl: './address-form.component.html',
  styleUrls: ['./address-form.component.scss']
})
export class AddressFormComponent {
  @Input() address: Address;

  upperCase = (value: string): string => value ? value.toUpperCase() : value;
}
